import React from "react";
import logo from "../../components/photos/wizsuite_login.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Image_sec_all = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="image">
      {/* <img src={login} alt="" /> */}

      <div className="about_wiz">
        <div className="img_logo">
          <img src={logo} alt="" />
        </div>

        <div className="welcome mt-5" style={{ textAlign: "center" }}>
          <h1>Welcome to the wizSuite HR Management Dashboard!</h1>
          <p style={{ padding: "0px 5px" }}>
            We're excited to have you onboard. Our platform is designed to
            simplify your HR tasks, enhance team management, and boost overall
            productivity. Let's get started on your journey to more efficient
            and effective HR management
          </p>
        </div>

        <button
          onClick={() => {
            navigate("/SignUp");
          }}
          className="secondary-btn"
        >
          {t("Sign_up")}
        </button>
      </div>
    </div>
  );
};

export default Image_sec_all;
