import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/AxiosInstance.jsx";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

//component for HR
const HRcomp = (props) => {
  const ACL_redux = useSelector((state) => state.Reducer);
  const signUpSchema = Yup.object({
    employee_readyesornoHR: Yup.string(),
    employee_createyesornoHR: Yup.string(),
    employee_updateyesornoHR: Yup.string(),
    attendence_readyesornoHR: Yup.string(),
    attendence_createyesornoHR: Yup.string(),
    attendence_updateyesornoHR: Yup.string(),
    onboard_readyesornoHR: Yup.string(),
    onboard_createyesornoHR: Yup.string(),
    onboard_updateyesornoHR: Yup.string(),
    payroll_readyesornoHR: Yup.string(),
    payroll_createyesornoHR: Yup.string(),
    payroll_updateyesornoHR: Yup.string(),
  });
  const [initialValues, setinitialValues] = useState({
    employee_readyesornoHR: "0",
    employee_createyesornoHR: "0",
    employee_updateyesornoHR: "0",
    attendence_readyesornoHR: "0",
    attendence_createyesornoHR: "0",
    attendence_updateyesornoHR: "0",
    onboard_readyesornoHR: "0",
    onboard_createyesornoHR: "0",
    onboard_updateyesornoHR: "0",
    payroll_readyesornoHR: "0",
    payroll_createyesornoHR: "0",
    payroll_updateyesornoHR: "0",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("values for status:::::", status, " ", values.roleView);
      // console.log(values);
      if (apistatus === "post") {
        insertACL(values);
      } else if (apistatus === "put") {
        putACL(values);
      }
    },
  });

  // calling useeffect for get api
  useEffect (() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
    getACL();
    }
  }, [props.tabStyle]);

  //state for api status whether to run put or pots api
  const [apistatus, setApiStatus] = useState("");

  //to store id of ACL
  const [idACL, setidACL] = useState();

  //get API
  async function getACL() {
    try {
      await axiosInstance
        .get(`GetAcl`)
        .then((response) => {
          if (response.status == 200 && response.data[0]) {
            const hrExists = response.data.some((emp) => emp.role === "HR");
            if (hrExists) {
              setApiStatus("put");
              const filterdataHR = response.data.filter(
                (emp) => emp.role === "HR"
              );
              setidACL(filterdataHR[0].id);
              setinitialValues({
                employee_readyesornoHR: filterdataHR[0].employee_read,
                employee_createyesornoHR: filterdataHR[0].employee_create,
                employee_updateyesornoHR: filterdataHR[0].employee_update,
                attendence_readyesornoHR: filterdataHR[0].attendence_read,
                attendence_createyesornoHR: filterdataHR[0].attendence_create,
                attendence_updateyesornoHR: filterdataHR[0].attendence_update,
                onboard_readyesornoHR: filterdataHR[0].onboard_read,
                onboard_createyesornoHR: filterdataHR[0].onboard_create,
                onboard_updateyesornoHR: filterdataHR[0].onboard_update,
                payroll_readyesornoHR: filterdataHR[0].payroll_read,
                payroll_createyesornoHR: filterdataHR[0].payroll_create,
                payroll_updateyesornoHR: filterdataHR[0].payroll_update,
              });
            } else {
              setApiStatus("post");
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  //api call for inserting ACL
  async function insertACL(e) {
    try {
      await axiosInstance
        .post(`Insertacl`, {
          Role: props.roleView,
          employee: props.employeeviewHR,
          attendence: props.attendanceviewHR,
          onboard_offboard: props.onboardviewHR,
          payroll: props.payrollviewHR,
          employee_read: e.employee_readyesornoRM,
          employee_create: e.employee_createyesornoRM,
          employee_update: e.employee_updateyesornoRM,
          attendence_read: e.attendence_readyesornoRM,
          attendence_create: e.attendence_createyesornoRM,
          attendence_update: e.attendence_updateyesornoRM,
          onboard_read: e.onboard_readyesornoRM,
          onboard_create: e.onboard_createyesornoRM,
          onboard_update: e.onboard_updateyesornoRM,
          payroll_read: e.payroll_readyesornoRM,
          payroll_create: e.payroll_createyesornoRM,
          payroll_update: e.payroll_updateyesornoRM,
        })
        .then((response) => {
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            // setShouldRerender(prev => !prev); // Trigger re-render by toggling state
            // setRenderKey(2);
            // window.location.reload();
          } else if (response.status == 400) {
            const errmsg = "ACL for this role already exists";
            swal({
              title: "Failure!",
              text: errmsg,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response);

      // console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  // put API
  async function putACL(e) {
    try {
      // Define a helper function to filter out empty values
      const filterNonEmpty = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== "" && value !== null && value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});
      };

      // Construct the payload with potentially empty values
      const payload = {
        acl_id: idACL,
        role: props.roleView,
        employee: props.employeeviewHR,
        attendence: props.attendanceviewHR,
        onboard_offboard: props.onboardviewHR,
        payroll: props.payrollviewHR,
        employee_read: e.employee_readyesornoHR,
        employee_create: e.employee_createyesornoHR,
        employee_update: e.employee_updateyesornoHR,
        attendence_read: e.attendence_readyesornoHR,
        attendence_create: e.attendence_createyesornoHR,
        attendence_update: e.attendence_updateyesornoHR,
        onboard_read: e.onboard_readyesornoHR,
        onboard_create: e.onboard_createyesornoHR,
        onboard_update: e.onboard_updateyesornoHR,
        payroll_read: e.payroll_readyesornoHR,
        payroll_create: e.payroll_createyesornoHR,
        payroll_update: e.payroll_updateyesornoHR,
      };

      // Filter out empty values
      const filteredPayload = filterNonEmpty(payload);

      // Make the API call with filtered payload
      const response = await axiosInstance.put(
        `Editacl`,
        filteredPayload
      );

      if (response.status === 200) {
        const successMessage = response.data.message || "Something went wrong";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = err.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 2000 milliseconds (2 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  return (
 
      <form onSubmit={handleSubmit}>
        {props.showbelowstyemp == 1 && (
          <div className="checkboxestxt">
            <div className="rpsty">
              <p>Employee</p>
            </div>

            <div className="rpsty">
              <Checkbox
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_readyesornoHR", newValue);
                }}
                value={values.employee_readyesornoHR}
                checked={values.employee_readyesornoHR === "1"}
                style={{ marginLeft: "107%" }}
                {...label}
              />
            </div>

            <div className="rpsty">
              <Checkbox
                value={values.employee_createyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_createyesornoHR", newValue);
                }}
                checked={values.employee_createyesornoHR === "1"}
                style={{ marginLeft: "31%" }}
                {...label}
              />
            </div>

            <div className="rpsty">
              <Checkbox
                value={values.employee_updateyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_updateyesornoHR", newValue);
                }}
                checked={values.employee_updateyesornoHR === "1"}
                {...label}
              />
            </div>
          </div>
        )}

        {props.showbelowstyatt == 1 && (
          <div className="checkboxestxt">
            <div className="rpsty">
              <p>Attendance</p>
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.attendence_readyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_readyesornoHR", newValue);
                }}
                checked={values.attendence_readyesornoHR === "1"}
                style={{ marginLeft: "91%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.attendence_createyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_createyesornoHR", newValue);
                }}
                checked={values.attendence_createyesornoHR === "1"}
                style={{ marginLeft: "22%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.attendence_updateyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_updateyesornoHR", newValue);
                }}
                checked={values.attendence_updateyesornoHR === "1"}
                {...label}
              />
            </div>
          </div>
        )}
        {props.showbelowstyon == 1 && (
          <div className="checkboxestxt" style={{display:"none"}}>
            <div className="rpsty">
              <p>Onboard</p>
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.onboard_readyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_readyesornoHR", newValue);
                }}
                checked={values.onboard_readyesornoHR === "1"}
                style={{ marginLeft: "115%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.onboard_createyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_createyesornoHR", newValue);
                }}
                checked={values.onboard_createyesornoHR === "1"}
                style={{ marginLeft: "35%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.onboard_updateyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_updateyesornoHR", newValue);
                }}
                checked={values.onboard_updateyesornoHR === "1"}
                {...label}
              />
            </div>
          </div>
        )}

        {props.showbelowstypay == 1 && (
          <div className="checkboxestxt" style={{display:"none"}} >
            <div className="rpsty">
              <p>Payroll</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_readyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_readyesornoHR", newValue);
                }}
                checked={values.payroll_readyesornoHR === "1"}
                style={{ marginLeft: "140%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_createyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_createyesornoHR", newValue);
                }}
                checked={values.payroll_createyesornoHR === "1"}
                style={{ marginLeft: "46%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_updateyesornoHR}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_updateyesornoHR", newValue);
                }}
                checked={values.payroll_updateyesornoHR === "1"}
                {...label}
              />
            </div>
          </div>
        )}
        <div className="addbtnbra submitbtnroleandper">
          <button type="submit" className=" cusbtn addbtnbra">
            Submit
          </button>
        </div>
      </form>

  );
};

//component for RM
const RMcomp = (props) => {
  const ACL_redux = useSelector((state) => state.Reducer);
  const signUpSchema = Yup.object({
    employee_readyesornoRM: Yup.string(),
    employee_createyesornoRM: Yup.string(),
    employee_updateyesornoRM: Yup.string(),
    attendence_readyesornoRM: Yup.string(),
    attendence_createyesornoRM: Yup.string(),
    attendence_updateyesornoRM: Yup.string(),
    onboard_readyesornoRM: Yup.string(),
    onboard_createyesornoRM: Yup.string(),
    onboard_updateyesornoRM: Yup.string(),
    payroll_readyesornoRM: Yup.string(),
    payroll_createyesornoRM: Yup.string(),
    payroll_updateyesornoRM: Yup.string(),
  });
  const [initialValues, setinitialValues] = useState({
    employee_readyesornoRM: "0",
    employee_createyesornoRM: "0",
    employee_updateyesornoRM: "0",
    attendence_readyesornoRM: "0",
    attendence_createyesornoRM: "0",
    attendence_updateyesornoRM: "0",
    onboard_readyesornoRM: "0",
    onboard_createyesornoRM: "0",
    onboard_updateyesornoRM: "0",
    payroll_readyesornoRM: "0",
    payroll_createyesornoRM: "0",
    payroll_updateyesornoRM: "0",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      // console.log("Entry");
      if (apistatus === "post") {
        insertACL(values);
      } else if (apistatus === "put") {
        putACL(values);
      }
    },
  });

  // calling useeffect for get api
  useEffect(() => {
    getACL();
  }, []);

  //state for api status whether to run put or post api
  const [apistatus, setApiStatus] = useState("");

  //to store id of ACL
  const [idACL, setidACL] = useState();

  //get API
  async function getACL() {
    try {
      await axiosInstance
        .get(`GetAcl`)
        .then((response) => {
          if (response.status == 200 && response.data[0]) {
            const rmExists = response.data.some((emp) => emp.role === "RM");
            if (rmExists) {
              setApiStatus("put");
              const filterdataRM = response.data.filter(
                (emp) => emp.role === "RM"
              );
              setidACL(filterdataRM[0].id);
              setinitialValues({
                employee_readyesornoRM: filterdataRM[0].employee_read,
                employee_createyesornoRM: filterdataRM[0].employee_create,
                employee_updateyesornoRM: filterdataRM[0].employee_update,
                attendence_readyesornoRM: filterdataRM[0].attendence_read,
                attendence_createyesornoRM: filterdataRM[0].attendence_create,
                attendence_updateyesornoRM: filterdataRM[0].attendence_update,
                onboard_readyesornoRM: filterdataRM[0].onboard_read,
                onboard_createyesornoRM: filterdataRM[0].onboard_create,
                onboard_updateyesornoRM: filterdataRM[0].onboard_update,
                payroll_readyesornoRM: filterdataRM[0].payroll_read,
                payroll_createyesornoRM: filterdataRM[0].payroll_create,
                payroll_updateyesornoRM: filterdataRM[0].payroll_update,
              });
            } else {
              setApiStatus("post");
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  //api call for inserting ACL
  async function insertACL(e) {
    try {
      await axiosInstance
        .post(`Insertacl`, {
          Role: props.roleView,
          employee: props.employeeviewRM,
          attendence: props.attendanceviewRM,
          onboard_offboard: props.onboardviewRM,
          payroll: props.payrollviewRM,
          employee_read: e.employee_readyesornoRM,
          employee_create: e.employee_createyesornoRM,
          employee_update: e.employee_updateyesornoRM,
          attendence_read: e.attendence_readyesornoRM,
          attendence_create: e.attendence_createyesornoRM,
          attendence_update: e.attendence_updateyesornoRM,
          onboard_read: e.onboard_readyesornoRM,
          onboard_create: e.onboard_createyesornoRM,
          onboard_update: e.onboard_updateyesornoRM,
          payroll_read: e.payroll_readyesornoRM,
          payroll_create: e.payroll_createyesornoRM,
          payroll_update: e.payroll_updateyesornoRM,
        })
        .then((response) => {
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            // setShouldRerender(prev => !prev); // Trigger re-render by toggling state
            // setRenderKey(2);
            // window.location.reload();
          } else if (response.status == 400) {
            const errmsg = "ACL for this role already exists";
            swal({
              title: "Failure!",
              text: errmsg,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response);

      // console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  // put API
  async function putACL(e) {
    try {
      // Define a helper function to filter out empty values
      const filterNonEmpty = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== "" && value !== null && value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});
      };

      // Construct the payload with potentially empty values
      const payload = {
        acl_id: idACL,
        employee: props.employeeviewRM,
        attendence: props.attendanceviewRM,
        onboard_offboard: props.onboardviewRM,
        payroll: props.payrollviewRM,
        employee_read: e.employee_readyesornoRM,
        employee_create: e.employee_createyesornoRM,
        employee_update: e.employee_updateyesornoRM,
        attendence_read: e.attendence_readyesornoRM,
        attendence_create: e.attendence_createyesornoRM,
        attendence_update: e.attendence_updateyesornoRM,
        onboard_read: e.onboard_readyesornoRM,
        onboard_create: e.onboard_createyesornoRM,
        onboard_update: e.onboard_updateyesornoRM,
        payroll_read: e.payroll_readyesornoRM,
        payroll_create: e.payroll_createyesornoRM,
        payroll_update: e.payroll_updateyesornoRM,
      };

      // Filter out empty values
      const filteredPayload = filterNonEmpty(payload);

      // Make the API call with the filtered payload
      const response = await axiosInstance.put(
        `Editacl`,
        filteredPayload,
        {
          headers: {
            Authorization: `Bearer ${ACL_redux.TOKEN}`,
          },
          httpsAgent: ACL_redux.HTTP_AGENT,
        }
      );

      if (response.status === 200) {
        const successMessage = response.data.message || "Successfully updated!";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
        // Optionally, trigger a re-render or reload the page
        // setShouldRerender(prev => !prev); // Uncomment if needed
        // window.location.reload(); // Uncomment if needed
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 2000 milliseconds (2 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  return (

      <form onSubmit={handleSubmit}>
        {props.showbelowstyemp == 1 && (
          <div className="checkboxestxt">
            <div className="rpsty">
              <p>Employee</p>
            </div>
            <div className="rpsty">
              <Checkbox
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_readyesornoRM", newValue);
                }}
                value={values.employee_readyesornoRM}
                checked={values.employee_readyesornoRM === "1"}
                style={{ marginLeft: "107%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.employee_createyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_createyesornoRM", newValue);
                }}
                checked={values.employee_createyesornoRM === "1"}
                style={{ marginLeft: "31%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.employee_updateyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_updateyesornoRM", newValue);
                }}
                checked={values.employee_updateyesornoRM === "1"}
                {...label}
              />
            </div>
          </div>
        )}

        {props.showbelowstyatt == 1 && (
          <div className="checkboxestxt">
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Attendance</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.attendence_readyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_readyesornoRM", newValue);
                }}
                checked={values.attendence_readyesornoRM === "1"}
                style={{ marginLeft: "91%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.attendence_createyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_createyesornoRM", newValue);
                }}
                checked={values.attendence_createyesornoRM === "1"}
                style={{ marginLeft: "22%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                values={values.attendence_updateyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_updateyesornoRM", newValue);
                }}
                checked={values.attendence_updateyesornoRM === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}
        {props.showbelowstyon == 1 && (
          <div className="checkboxestxt" style={{display:"none"}}>
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Onboard</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.onboard_readyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_readyesornoRM", newValue);
                }}
                checked={values.onboard_readyesornoRM === "1"}
                style={{ marginLeft: "115%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.onboard_createyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_createyesornoRM", newValue);
                }}
                checked={values.onboard_createyesornoRM === "1"}
                style={{ marginLeft: "35%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.onboard_updateyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_updateyesornoRM", newValue);
                }}
                checked={values.onboard_updateyesornoRM === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}

        {props.showbelowstypay == 1 && (
          <div className="checkboxestxt" style={{display:"none"}}>
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Payroll</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_readyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_readyesornoRM", newValue);
                }}
                checked={values.payroll_readyesornoRM === "1"}
                style={{ marginLeft: "140%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_createyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_createyesornoRM", newValue);
                }}
                checked={values.payroll_createyesornoRM === "1"}
                style={{ marginLeft: "46%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_updateyesornoRM}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_updateyesornoRM", newValue);
                }}
                checked={values.payroll_updateyesornoRM === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}
        <div className="addbtnbra submitbtnroleandper">
          <button type="submit" className=" cusbtn addbtnbra">
            Submit
          </button>
        </div>
      </form>

  );
};

//component for ACC
const ACCcomp = (props) => {
  const ACL_redux = useSelector((state) => state.Reducer);
  const signUpSchema = Yup.object({
    employee_readyesornoACC: Yup.string(),
    employee_createyesornoACC: Yup.string(),
    employee_updateyesornoACC: Yup.string(),
    attendence_readyesornoACC: Yup.string(),
    attendence_createyesornoACC: Yup.string(),
    attendence_updateyesornoACC: Yup.string(),
    onboard_readyesornoACC: Yup.string(),
    onboard_createyesornoACC: Yup.string(),
    onboard_updateyesornoACC: Yup.string(),
    payroll_readyesornoACC: Yup.string(),
    payroll_createyesornoACC: Yup.string(),
    payroll_updateyesornoACC: Yup.string(),
  });

  const [initialValues, setinitialValues] = useState({
    employee_readyesornoACC: "0",
    employee_createyesornoACC: "0",
    employee_updateyesornoACC: "0",
    attendence_readyesornoACC: "0",
    attendence_createyesornoACC: "0",
    attendence_updateyesornoACC: "0",
    onboard_readyesornoACC: "0",
    onboard_createyesornoACC: "0",
    onboard_updateyesornoACC: "0",
    payroll_readyesornoACC: "0",
    payroll_createyesornoACC: "0",
    payroll_updateyesornoACC: "0",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      if (apistatus === "post") {
        insertACL(values);
      } else if (apistatus === "put") {
        putACL(values);
      }
    },
  });

  // calling useeffect for get api
  useEffect(() => {
    getACL();
  }, []);

  //state for api status whether to run put or pots api
  const [apistatus, setApiStatus] = useState("");

  //to store id of ACL
  const [idACL, setidACL] = useState();

  //get API
  async function getACL() {
    try {
      await axiosInstance
        .get(`GetAcl`)
        .then((response) => {
          if (response.status == 200 && response.data[0]) {
            const accExists = response.data.some((emp) => emp.role === "ACC");
            if (accExists) {
              setApiStatus("put");
              const filterdataACC = response.data.filter(
                (emp) => emp.role === "ACC"
              );
              setidACL(filterdataACC[0].id);
              setinitialValues({
                employee_readyesornoACC: filterdataACC[0].employee_read,
                employee_createyesornoACC: filterdataACC[0].employee_create,
                employee_updateyesornoACC: filterdataACC[0].employee_update,
                attendence_readyesornoACC: filterdataACC[0].attendence_read,
                attendence_createyesornoACC: filterdataACC[0].attendence_create,
                attendence_updateyesornoACC: filterdataACC[0].attendence_update,
                onboard_readyesornoACC: filterdataACC[0].onboard_read,
                onboard_createyesornoACC: filterdataACC[0].onboard_create,
                onboard_updateyesornoACC: filterdataACC[0].onboard_update,
                payroll_readyesornoACC: filterdataACC[0].payroll_read,
                payroll_createyesornoACC: filterdataACC[0].payroll_create,
                payroll_updateyesornoACC: filterdataACC[0].payroll_update,
              });
            } else {
              setApiStatus("post");
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  //api call for inserting ACL
  async function insertACL(e) {
    try {
      await axiosInstance
        .post(`Insertacl`, {
          Role: props.roleView,
          employee: props.employeeviewACC,
          attendence: props.attendanceviewACC,
          onboard_offboard: props.onboardviewACC,
          payroll: props.payrollviewACC,
          employee_read: e.employee_readyesornoACC,
          employee_create: e.employee_createyesornoACC,
          employee_update: e.employee_updateyesornoACC,
          attendence_read: e.attendence_readyesornoACC,
          attendence_create: e.attendence_createyesornoACC,
          attendence_update: e.attendence_updateyesornoACC,
          onboard_read: e.onboard_readyesornoACC,
          onboard_create: e.onboard_createyesornoACC,
          onboard_update: e.onboard_updateyesornoACC,
          payroll_read: e.payroll_readyesornoACC,
          payroll_create: e.payroll_createyesornoACC,
          payroll_update: e.payroll_updateyesornoACC,
        })
        .then((response) => {
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            // setShouldRerender(prev => !prev); // Trigger re-render by toggling state
            // setRenderKey(2);
            // window.location.reload();
          } else if (response.status == 400) {
            const errmsg = "ACL for this role already exists";
            swal({
              title: "Failure!",
              text: errmsg,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response);

      // console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  // put API
  async function putACL(e) {
    try {
      // Define a helper function to filter out empty values
      const filterNonEmpty = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== "" && value !== null && value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});
      };

      // Construct the payload with potentially empty values
      const payload = {
        acl_id: idACL,
        role: props.roleView,
        employee: props.employeeviewACC,
        attendence: props.attendanceviewACC,
        onboard_offboard: props.onboardviewACC,
        payroll: props.payrollviewACC,
        employee_read: e.employee_readyesornoACC,
        employee_create: e.employee_createyesornoACC,
        employee_update: e.employee_updateyesornoACC,
        attendence_read: e.attendence_readyesornoACC,
        attendence_create: e.attendence_createyesornoACC,
        attendence_update: e.attendence_updateyesornoACC,
        onboard_read: e.onboard_readyesornoACC,
        onboard_create: e.onboard_createyesornoACC,
        onboard_update: e.onboard_updateyesornoACC,
        payroll_read: e.payroll_readyesornoACC,
        payroll_create: e.payroll_createyesornoACC,
        payroll_update: e.payroll_updateyesornoACC,
      };

      // Filter out empty values
      const filteredPayload = filterNonEmpty(payload);

      // Make the API call with the filtered payload
      const response = await axiosInstance.put(
        `Editacl`,
        filteredPayload,
        {
          headers: {
            Authorization: `Bearer ${ACL_redux.TOKEN}`,
          },
          httpsAgent: ACL_redux.HTTP_AGENT,
        }
      );

      if (response.status === 200) {
        const successMessage = response.data.message || "Successfully updated!";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
        // Optionally, trigger a re-render or reload the page
        // setShouldRerender(prev => !prev); // Uncomment if needed
        // window.location.reload(); // Uncomment if needed
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 2000 milliseconds (2 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  return (
    <>
      {" "}
      <form onSubmit={handleSubmit}>
        {props.showbelowstyemp == 1 && (
          <div className="checkboxestxt">
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Employee</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.employee_readyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_readyesornoACC", newValue);
                }}
                checked={values.employee_readyesornoACC === "1"}
                style={{ marginLeft: "107%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.employee_createyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_createyesornoACC", newValue);
                }}
                checked={values.employee_createyesornoACC === "1"}
                style={{ marginLeft: "31%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.employee_updateyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("employee_updateyesornoACC", newValue);
                }}
                checked={values.employee_updateyesornoACC === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}
        {props.showbelowstyatt == 1 && (
          <div className="checkboxestxt">
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Attendance</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.attendence_readyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_readyesornoACC", newValue);
                }}
                checked={values.attendence_readyesornoACC === "1"}
                style={{ marginLeft: "91%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.attendence_createyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_createyesornoACC", newValue);
                }}
                checked={values.attendence_createyesornoACC === "1"}
                style={{ marginLeft: "22%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.attendence_updateyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("attendence_updateyesornoACC", newValue);
                }}
                checked={values.attendence_updateyesornoACC === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}
        {props.showbelowstyon == 1 && (
          <div className="checkboxestxt" style={{display:"none"}}>
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Onboard</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.onboard_readyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_readyesornoACC", newValue);
                }}
                checked={values.onboard_readyesornoACC === "1"}
                style={{ marginLeft: "115%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.onboard_createyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_createyesornoACC", newValue);
                }}
                checked={values.onboard_createyesornoACC === "1"}
                style={{ marginLeft: "35%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.onboard_updateyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("onboard_updateyesornoACC", newValue);
                }}
                checked={values.onboard_updateyesornoACC === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}
        {props.showbelowstypay == 1 && (
          <div className="checkboxestxt" style={{display:"none"}}>
            {/* <div className="checkboxestxt"> */}
            <div className="rpsty">
              <p>Payroll</p>
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_readyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_readyesornoACC", newValue);
                }}
                checked={values.payroll_readyesornoACC === "1"}
                style={{ marginLeft: "140%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_createyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_createyesornoACC", newValue);
                }}
                checked={values.payroll_createyesornoACC === "1"}
                style={{ marginLeft: "46%" }}
                {...label}
              />
            </div>
            <div className="rpsty">
              <Checkbox
                value={values.payroll_updateyesornoACC}
                onChange={(e) => {
                  const newValue = e.target.checked ? "1" : "0";
                  setFieldValue("payroll_updateyesornoACC", newValue);
                }}
                checked={values.payroll_updateyesornoACC === "1"}
                {...label}
              />
            </div>
            {/* </div> */}
          </div>
        )}
        <div className="addbtnbra submitbtnroleandper">
          <button type="submit" className=" cusbtn addbtnbra">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

const Role_Permission = (props) => {
  //to set the state of tabs to true
  const ACL_redux = useSelector((state) => state.Reducer);
  const [status, setstatus] = useState(1);
  const signUpSchema = Yup.object({
    roleView: Yup.string(),
    employeeviewHR: Yup.string(),
    attendanceviewHR: Yup.string(),
    onboardviewHR: Yup.string(),
    payrollviewHR: Yup.string(),
    employeeviewRM: Yup.string(),
    attendanceviewRM: Yup.string(),
    onboardviewRM: Yup.string(),
    payrollviewRM: Yup.string(),
    employeeviewACC: Yup.string(),
    attendanceviewACC: Yup.string(),
    onboardviewACC: Yup.string(),
    payrollviewACC: Yup.string(),
  });

  const [initialValues, setinitialValues] = useState({
    roleView: "",
    employeeviewHR: "",
    attendanceviewHR: "",
    onboardviewHR: "",
    payrollviewHR: "",
    employeeviewRM: "",
    attendanceviewRM: "",
    onboardviewRM: "",
    payrollviewRM: "",
    employeeviewACC: "",
    attendanceviewACC: "",
    onboardviewACC: "",
    payrollviewACC: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("Entry");
    },
  });

  //settting states for showing the chexboxes bases on toggle buttons
  const [showbelowstyemp, setshowbelowstyemp] = useState(0);
  const [showbelowstyatt, setshowbelowstyatt] = useState(0);
  const [showbelowstyon, setshowbelowstyon] = useState(0);
  const [showbelowstypay, setshowbelowstypay] = useState(0);
  const [tableheaderstate, settableheaderstate] = useState(0);

  //handle tab change based on status
  function handletabChange(val) {
    if (val == 1) {
      setstatus(1);
      values.roleView = "HR";
      // console.log("the roleview is:", values.roleView, " ", status);
      //setting all staes to 0 so thta no checboxes should be visible intially
      setshowbelowstyemp(0);
      setshowbelowstyatt(0);
      setshowbelowstyon(0);
      setshowbelowstypay(0);
      settableheaderstate(0);
    } else if (val == 2) {
      setstatus(2);
      values.roleView = "RM";
      // console.log("the roleview is:", values.roleView, " ", status);
      //setting all staes to 0 so thta no checboxes should be visible intially
      setshowbelowstyemp(0);
      setshowbelowstyatt(0);
      setshowbelowstyon(0);
      setshowbelowstypay(0);
      settableheaderstate(0);
    } else if (val == 3) {
      setstatus(3);
      values.roleView = "ACC";
      // console.log("the roleview is:", values.roleView);
      //setting all staes to 0 so thta no checboxes should be visible intially
      setshowbelowstyemp(0);
      setshowbelowstyatt(0);
      setshowbelowstyon(0);
      setshowbelowstypay(0);
      settableheaderstate(0);
    } else if (val == 4) {
      setstatus(4);
      //setting all staes to 0 so thta no checboxes should be visible intially
      setshowbelowstyemp(0);
      setshowbelowstyatt(0);
      setshowbelowstyon(0);
      setshowbelowstypay(0);
      settableheaderstate(0);
    }
  }

  //function for Employee toggle click
  const handleToggleClickEmp = (e) => {
    if (showbelowstyemp == 0) {
      setshowbelowstyemp(e.target.id);
      settableheaderstate(1);
    } else {
      setshowbelowstyemp(0);
    }
    // console.log("the status is:", showbelowstyemp);
  };

  //function for Attenance toggle click
  const handleToggleClickAtt = (e) => {
    if (showbelowstyatt == 0) {
      setshowbelowstyatt(e.target.id);
      settableheaderstate(1);
    } else {
      setshowbelowstyatt(0);
    }
    // console.log("the status is:", showbelowstyatt);
  };

  //function for Onboard toggle click
  const handleToggleClickOn = (e) => {
    if (showbelowstyon == 0) {
      setshowbelowstyon(e.target.id);
      settableheaderstate(1);
    } else {
      setshowbelowstyon(0);
    }
  };

  //funtion for  payroll toggle click
  const handleToggleClickPay = (e) => {
    if (showbelowstypay == 0) {
      setshowbelowstypay(e.target.id);
      settableheaderstate(1);
    } else {
      setshowbelowstypay(0);
    }
  };

  //initially calling handletabChange(1) to set roleView="HR"
  // useEffect(() => {
  //   handletabChange(1);
  // }, []);

  useEffect (() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
    getACL();
    }
  }, [props.tabStyle]);

  //get API
  // const [idACL, setidACL] = useState();

  async function getACL() {
    try {
      await axiosInstance
        .get(`GetAcl`)
        .then((response) => {
          // console.log("for true or false", typeof response.data);
          // setidACL(response.data[0].id);
          setinitialValues({
            employeeviewHR: response.data[0].employee,
            attendanceviewHR: response.data[0].attendence,
            onboardviewHR: response.data[0].onboard_offboard,
            payrollviewHR: response.data[0].payroll,
            // att
            employeeviewRM: response.data[1].employee,
            attendanceviewRM: response.data[1].attendence,
            onboardviewRM: response.data[1].onboard_offboard,
            payrollviewRM: response.data[1].payroll,
            // acc
            employeeviewACC: response.data[2].employee,
            attendanceviewACC: response.data[2].attendence,
            onboardviewACC: response.data[2].onboard_offboard,
            payrollviewACC: response.data[2].payroll,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  // put API fro hr
  const [changedFields, setChangedFields] = useState({});
  async function putACL(fieldName, fieldValue) {
    try {
      // Create an empty payload
      const fieldMapping = {
        employeeviewHR: "employee",
        attendanceviewHR: "attendence",
        onboardviewHR: "onboard_offboard",
        payrollviewHR: "payroll",
        // Add more mappings as needed
      };
      // Get the corresponding API key from the mapping
      const apiKey = fieldMapping[fieldName];

      // Create the payload with the correct key-value pair
      const payload = {
        acl_id: 1,
        [apiKey]: fieldValue,
      };
      // Make the API call with filtered payload
      const response = await axiosInstance.put(
        `Editacl`,
        payload
      );

      if (response.status === 200) {
        const successMessage = response.data.message || "Something went wrong";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = err.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 2000 milliseconds (2 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put API fro hr

  // put API fro att
  async function putACLRM(fieldName, fieldValue) {
    try {
      // Create an empty payload
      const fieldMapping = {
        employeeviewRM: "employee",
        attendanceviewRM: "attendence",
        onboardviewRM: "onboard_offboard",
        payrollviewRM: "payroll",
        // Add more mappings as needed
      };
      // Get the corresponding API key from the mapping
      const apiKey = fieldMapping[fieldName];

      // Create the payload with the correct key-value pair
      const payload = {
        acl_id: 2,
        [apiKey]: fieldValue,
      };
      // Make the API call with filtered payload
      const response = await axiosInstance.put(
        `Editacl`,
        payload
      );

      if (response.status === 200) {
        const successMessage = response.data.message || "Something went wrong";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = err.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 2000 milliseconds (2 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put API fro att

  // put API fro acc
  async function putACLACC(fieldName, fieldValue) {
    try {
      // Create an empty payload
      const fieldMapping = {
        employeeviewACC: "employee",
        attendanceviewACC: "attendence",
        onboardviewACC: "onboard_offboard",
        payrollviewACC: "payroll",
        // Add more mappings as needed
      };
      // Get the corresponding API key from the mapping
      const apiKey = fieldMapping[fieldName];

      // Create the payload with the correct key-value pair
      const payload = {
        acl_id: 3,
        [apiKey]: fieldValue,
      };
      // Make the API call with filtered payload
      const response = await axiosInstance.put(
        `Editacl`,
        payload
      );

      if (response.status === 200) {
        const successMessage = response.data.message || "Something went wrong";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        // Close the SweetAlert after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = err.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 2000 milliseconds (2 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put API fro acc
  return (
    <div className="tabs_deatils" style={{ height: "80%" }}>
      <div className="bloc-tabs sidetab">
        <div className="headingleftbox thirdsidetabsty">
          <h1>Administrator</h1>
          <p
            className={status == 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => {
              handletabChange(1);
            }}
            style={{ fontSize: "18px" }}
          >
            HR
          </p>
          <p
            className={status == 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => {
              handletabChange(2);
            }}
            style={{ fontSize: "18px" }}
          >
            Reporting Manager
          </p>
          <p
            className={status == 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => {
              handletabChange(3);
            }}
            style={{ fontSize: "18px" }}
          >
            Accounts Manager
          </p>
          {/* <p className={status == 4 ? 'tabs active-tabs' : 'tabs'} onClick={() => { handletabChange(4) }} style={{ fontSize: "18px" }}>Employee</p> */}
        </div>
      </div>
      <div className="content-tabsright rightcontent thirdsidetabsty headingleftbox">
        <h1>Module Access</h1>
        <p>Give permission for Administrator</p>

        {/* toggle button section for HR Manager */}
        {status == 1 && (
          <>
            <div className="leftBox">
              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Employee</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="employeeviewHR"
                      value={values.employeeviewHR}
                      checked={values.employeeviewHR === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACL(name, newValue);
                      }}
                      id={1}
                    />
                    <label htmlFor={1}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Attendance</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="attendanceviewHR"
                      value={values.attendanceviewHR}
                      checked={values.attendanceviewHR === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACL(name, newValue);
                      }}
                      id={2}
                    />
                    <label htmlFor={2}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2" style={{display:"none"}}>
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>OnBoard</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="onboardviewHR"
                      value={values.onboardviewHR}
                      checked={values.onboardviewHR === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACL(name, newValue);
                      }}
                      id={3}
                    />
                    <label htmlFor={3}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2" style={{display:"none"}}>
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Payroll</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="payrollviewHR"
                      value={values.payrollviewHR}
                      checked={values.payrollviewHR === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACL(name, newValue);
                      }}
                      id={4}
                    />
                    <label htmlFor={4}></label>
                  </div>
                </div>
              </div>
              {/* {tableheaderstate == 1 && ( */}
              <div className="belowsty">
                <div className="checkboxestxt">
                  <div className="rpsty">
                    <h1>Module Permission</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Read</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Create</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Update</h1>
                  </div>
                </div>
              </div>
              {/* )} */}
              <HRcomp
                showbelowstyemp={values.employeeviewHR}
                showbelowstyatt={values.attendanceviewHR}
                showbelowstyon={values.payrollviewHR}
                showbelowstypay={values.onboardviewHR}
                roleView={values.roleView}
                employeeviewHR={values.employeeviewHR}
                attendanceviewHR={values.attendanceviewHR}
                payrollviewHR={values.payrollviewHR}
                onboardview={values.onboardviewHR}
                tabStyle={props.tabStyle}
              />
            </div>
          </>
        )}
        {/* toggle button section for RM Manager */}
        {status == 2 && (
          <>
            <div className="leftBox">
              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Employee</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="employeeviewRM"
                      value={values.employeeviewRM}
                      checked={values.employeeviewRM === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLRM(name, newValue);
                      }}
                      id={1}
                    />
                    <label htmlFor={1}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Attendance</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="attendanceviewRM"
                      value={values.attendanceviewRM}
                      checked={values.attendanceviewRM === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLRM(name, newValue);
                      }}
                      id={2}
                    />
                    <label htmlFor={2}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2" style={{display:"none"}}>
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>OnBoard</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="onboardviewRM"
                      value={values.onboardviewRM}
                      checked={values.onboardviewRM === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLRM(name, newValue);
                      }}
                      id={3}
                    />
                    <label htmlFor={3}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2" style={{display:"none"}}>
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Payroll</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="payrollviewRM"
                      value={values.payrollviewRM}
                      checked={values.payrollviewRM === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACL(name, newValue);
                      }}
                      id={4}
                    />
                    <label htmlFor={4}></label>
                  </div>
                </div>
              </div>

              <div className="belowsty">
                <div className="checkboxestxt">
                  <div className="rpsty">
                    <h1>Module Permission</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Read</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Create</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Update</h1>
                  </div>
                </div>
              </div>

              <RMcomp
                showbelowstyemp={values.employeeviewRM}
                showbelowstyatt={values.attendanceviewRM}
                showbelowstyon={values.payrollviewRM}
                showbelowstypay={values.onboardviewRM}
                roleView={values.roleView}
                employeeviewRM={values.employeeviewRM}
                attendanceviewRM={values.attendanceviewRM}
                payrollviewRM={values.payrollviewRM}
                onboardviewRM={values.onboardviewRM}
              />
            </div>
          </>
        )}

        {/* toggle button section for Account Manager */}
        {status == 3 && (
          <>
            <div className="leftBox">
              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Employee</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="employeeviewACC"
                      value={values.employeeviewACC}
                      checked={values.employeeviewACC === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLACC(name, newValue);
                      }}
                      id={1}
                    />
                    <label htmlFor={1}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Attendance</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="attendanceviewACC"
                      value={values.attendanceviewACC}
                      checked={values.attendanceviewACC === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLACC(name, newValue);
                      }}
                      id={2}
                    />
                    <label htmlFor={2}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2" style={{display:"none"}}>
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>OnBoard</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="onboardviewACC"
                      value={values.onboardviewACC}
                      checked={values.onboardviewACC === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLACC(name, newValue);
                      }}
                      id={3}
                    />
                    <label htmlFor={3}></label>
                  </div>
                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2" style={{display:"none"}}>
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Payroll</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      name="payrollviewACC"
                      value={values.payrollviewACC}
                      checked={values.payrollviewACC === "1"}
                      onClick={async (e) => {
                        const { name, checked } = e.target;
                        const newValue = checked ? "1" : "0";

                        setFieldValue(name, newValue);
                        // Update changed fields
                        setChangedFields((prevFields) => ({
                          ...prevFields,
                          [name]: true,
                        }));
                        await putACLACC(name, newValue);
                      }}
                      id={4}
                    />
                    <label htmlFor={4}></label>
                  </div>
                </div>
              </div>
              {/* {tableheaderstate == 1 && ( */}
              <div className="belowsty">
                <div className="checkboxestxt">
                  <div className="rpsty">
                    <h1>Module Permission</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Read</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Create</h1>
                  </div>
                  <div className="rpsty">
                    <h1>Update</h1>
                  </div>
                </div>
              </div>
              {/* )} */}
              <ACCcomp
                showbelowstyemp={values.employeeviewACC}
                showbelowstyatt={values.attendanceviewACC}
                showbelowstyon={values.payrollviewACC}
                showbelowstypay={values.onboardviewACC}
                roleView={values.roleView}
                employeeviewACC={values.employeeviewACC}
                attendanceviewACC={values.attendanceviewACC}
                payrollview={values.payrollviewACC}
                onboardviewACC={values.onboardviewACC}
              />
            </div>
          </>
        )}

        {/* toggle button section for Employee */}
        {/* {status == 4 && (
          <>
            <div className="leftBox">
              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Employee</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      onClick={(e) => handleToggleClickEmp(e)}
                     
                      id={1}
                    />
                    <label htmlFor={1}></label>
                  </div>

                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Attendance</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      onClick={(e) => handleToggleClickAtt(e)}
                      
                      id={2}
                    />
                    <label htmlFor={2}></label>
                  </div>

                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>OnBoard</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox" onClick={(e) => handleToggleClickOn(e)} 
                     
                      id={3}
                    />
                    <label htmlFor={3}></label>
                  </div>

                </div>
              </div>

              <div className="leavetypesty attendancearea mt-2">
                <div className="leavesty">
                  <h1 style={{ color: "black" }}>Payroll</h1>
                </div>
                <div className="secondWrapper">
                  <div className="switch-toggle">
                    <input
                      type="checkbox"
                      onClick={(e) => handleToggleClickPay(e)}
                
                      id={4}
                    />
                    <label htmlFor={4}></label>
                  </div>

                </div>
              </div>
              {tableheaderstate == 1 && (
                <div className="belowsty">
                  <div className="checkboxestxt">
                    <div className="rpsty">
                      <h1>Module Permission</h1>
                    </div>
                    <div className="rpsty">
                      <h1>Read</h1>
                    </div>
                    <div className="rpsty">
                      <h1>Create</h1>
                    </div>
                    <div className="rpsty">
                      <h1>Update</h1>
                    </div>
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                {showbelowstyemp == 1 && (

                  <div className="checkboxestxt">
                    <div className="rpsty">
                      <p>Employee</p>
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "107%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "31%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox {...label} />
                    </div>
                  </div>

                )}

                {showbelowstyatt == 2 && (


                  <div className="checkboxestxt">
                    <div className="rpsty">
                      <p>Attendance</p>
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "91%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "22%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox {...label} />
                    </div>
                  </div>

                )}
                {showbelowstyon == 3 && (


                  <div className="checkboxestxt">
                    <div className="rpsty">
                      <p>Onboard</p>
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "115%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "35%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox {...label} />
                    </div>
                  </div>

                )}

                {showbelowstypay == 4 && (


                  <div className="checkboxestxt">
                    <div className="rpsty">
                      <p>Payroll</p>
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "140%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox style={{ marginLeft: "46%" }} {...label} />
                    </div>
                    <div className="rpsty">
                      <Checkbox {...label} />
                    </div>
                  </div>

                )}
                <div className="addbtnbra submitbtnroleandper">
                  <button type='submit' className=' cusbtn addbtnbra'>Submit</button>
                </div>
              </form>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default Role_Permission;
