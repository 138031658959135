import React, { useEffect, useState, useMemo } from "react";
import Navbar from "../../Navbar";
import { useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { FaThumbsDown, FaThumbsUp, FaUser } from "react-icons/fa";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import swal from "sweetalert";

const Compoff = () => { 
  const [compoffdata, setCompoffdata] = useState([]);
  const employee_page = useSelector((state) => state.Reducer);

  // Fetch compoff data from API
  const compoff_get = async () => {
    try {
      const response = await axiosInstance.get(`Compoffs`);
      if (response.status === 200) {
        setCompoffdata(response.data);
     
        
      } else {
        throw new Error(response.data.message || "Failed to fetch data");
      }
    } catch (error) {
      console.error(error);
      // swal({
      //   title: "Failure!",
      //   text: error.message,
      //   icon: "error",
      // });

      // Close the SweetAlert after 3 seconds
      setTimeout(() => {
        swal.close();
      }, 3000);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    compoff_get();
  }, []); // Empty dependency array to ensure that it only runs once

  // Helper function to format the date
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  // Handle toggle change
  const handleToggle = async (currentStatus, row) => {
 
    const compoffId = row.original.id;

    try {
      const response = await axiosInstance.put(`EditCompoffs`, {
        compoff_id: compoffId,
        status: currentStatus,
      });

      if (response.status === 200) {
        // Update the local state with the new status
        const updatedData = compoffdata.map((item) =>
          item.id === compoffId ? { ...item, status: currentStatus } : item
        );
        setCompoffdata(updatedData);
      
       
          swal({
            title: "Success!",
            text: "Status Updated Successfully",
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                className: "swal-button--custom",
              },
            },
          });
       
        compoff_get();
      
      } else {
        throw new Error(response.data.message || "Failed to update status");
      }

    } catch (error) {
      console.error(error);
      swal({
        title: "Failure!",
        text: error.message,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3 seconds
      setTimeout(() => {
        swal.close();
      }, 3000);
    }
  };

  // Memoize the columns to prevent re-renders
  const columns = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "firstname",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {row.original.photo ? (
              <img
                src={row.original.photo}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
                alt=""
              />
            ) : (
              <FaUser
                style={{
                  color: "var(--Whiteblack-color)",
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
              />
            )}
            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>
                {row.original.first_name} {row.original.last_name}
              </p>
              {/* <p>{row.original.id}</p> */}
            </div>
          </div>
        ),
      },
      {
        Header: "Comp Off Date",
        accessor: "compoff_date",
        Cell: ({ row }) => <p>{formatDate(row.original.date)}</p>,
      },
      {
        Header: "Department",
        accessor: "department_name",
        Cell: ({ row }) => <p>{row.original.department_name}</p>,
      },
      {
        Header: "Approve/Reject",
        accessor: "approve_reject",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "38px" }}>
            <FaThumbsUp
              style={{
                cursor: "pointer",
                color: "green",
              }}
              onClick={() =>handleToggle("1", row)}
            />

            <FaThumbsDown
              style={{
                cursor: "pointer",
                color: "red",
              }}
              onClick={() =>handleToggle("Rejected", row)}
            />
          </div>
        ),
      },
    ],
    [compoffdata]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: compoffdata,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <div className="main-container">
      <div className="allcontent">
        <Navbar />
        <div className="mainbox">
          <div className="department tablebox card mt-2">
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length} style={{ textAlign: "center" }}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compoff;
