import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
const Attendance_Settings = (props) => {
  // for Translation
  const { t } = useTranslation();
  const [loading,setLoading]=useState(false);
  const attendance_redux = useSelector((state) => state.Reducer);
  // add Department popup start
  const signUpSchema = Yup.object({
    // how_att_calculated: Yup.string().required("Select one option"),
    geofencing: Yup.string().required("Select yes or no"),
    geofencing_meter: Yup.string()
    .test(
      "conditionalRequired",
      "Enter Range",
      function (value) {
        const { geofencing } = this.parent; // Access other fields in the form
        if (geofencing === "1") {
          return !!value; // Return true if geofencing_meter is required and has a value
        }
        return true; // Return true for non-required cases
      }
    ),
   
  
    enable_relax: Yup.string().required("Select yes or no"),

    first_check_lateby: Yup.string().test(
      "conditionalRequired",
      "This is required",
      function (value) {
        const { enable_relax } = this.parent;

        if (enable_relax === "1") {
          return !!value;
        }

        return true;
      }
    ),
    last_check_earlyby: Yup.string().test(
      "conditionalRequired",
      "This is required",
      function (value) {
        const { enable_relax } = this.parent;

        if (enable_relax === "1") {
          return !!value;
        }

        return true;
      }
    ),
    // conseq_for_late: Yup.string().required("Select yes or no"),
    deduct_from_leave_bal: Yup.string().test(
      "conditionalRequired",
      "Select one of the feild",
      function (value) {
        const { conseq_for_late } = this.parent;

        if (conseq_for_late === "1") {
          return !!value;
        }

        return true;
      }
    ),
    conseq_late_month: Yup.string().test(
      "conditionalRequired",
      "This is required",
      function (value) {
        const { deduct_from_leave_bal } = this.parent;

        if (
          deduct_from_leave_bal ===
            "If late coming be deducted from leave balance (if available) then count as LOP (if leave balance is not sufficient)" ||
          deduct_from_leave_bal === "deduct from leave bal"
        ) {
          return !!value;
        }

        return true;
      }
    ),
    conseq_days_from_leaves: Yup.string().test(
      "conditionalRequired",
      "This is required",
      function (value) {
        const { deduct_from_leave_bal } = this.parent;

        if (
          deduct_from_leave_bal ===
            "If late coming be deducted from leave balance (if available) then count as LOP (if leave balance is not sufficient)" ||
          deduct_from_leave_bal === "deduct from leave bal"
        ) {
          return !!value;
        }

        return true;
      }
    ),
    max_regularization: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
  });

  const [initialValues, setinitialValues] = useState({
    geofencing_meter: "",
    geofencing: "",
    // geofencing_meter: "",
    enable_relax: "",
    first_check_lateby: "",
    last_check_earlyby: "",
    conseq_for_late: "",
    deduct_from_leave_bal: "",
    conseq_late_month: "",
    conseq_days_from_leaves: "",
    max_regularization: "",
  });

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      // console.log(values);
      // action.resetForm();
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      if (flag_forPutPost === "put") {
        update_details_put(values);
      } else if (flag_forPutPost === "post") {
        update_details_post(values);
      }
    },
  });

  // get attendance setting
  const [flag_forPutPost, setFlag_forPutPost] = useState("");
  const [attendance_id, setAttendance_id] = useState("");
  useEffect (() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
    get_attendance_setting();
    }
  }, [props.tabStyle]);
  async function get_attendance_setting() {
    try {
      await axiosInstance
        .get(`AttendenceSettings`, {
          headers: {
            Authorization: `Bearer ${attendance_redux.TOKEN}`,
          },
          httpsAgent: attendance_redux.HTTP_AGENT,
        })
        .then((response) => {
          const mess = response.data.message;
          if (response.status == "200" && response.data[0]) {
            setFlag_forPutPost("put");
            setAttendance_id(response.data[0].id);
            setinitialValues({
              // how_att_calculated: response.data[0].checkIn_checkOut_Type,
              geofencing: response.data[0].geofencing,
              geofencing_meter: response.data[0].geofencing_meter,
              enable_relax: response.data[0].relaxation,
              first_check_lateby: response.data[0].late_checkin,
              last_check_earlyby: response.data[0].early_checkout,
              conseq_for_late: response.data[0].late_consequence,
              deduct_from_leave_bal: response.data[0].what_consequence,
              conseq_late_month: response.data[0].late_per_month,
              conseq_days_from_leaves: response.data[0].days_lossOfPay,
              max_regularization: response.data[0].max_regularization,
            });
          } else if (response.data.message === mess) {
            // console.log("hiii");
            setFlag_forPutPost("post");
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get attendance setting

  // put attendance deatils
  async function update_details_put(e) {
    setLoading(true)
    try {
      await axiosInstance
        .put(
          `EditAttendenceSetting`,
          {
            geofencing: e.geofencing,
            geofencing_meter: e.geofencing_meter,
            relaxation: e.enable_relax,
            first_check_lateby: e.first_check_lateby,
            early_checkout: e.last_check_earlyby,
            // late_consequence: e.conseq_for_late,
            late_consequence: "0",
            what_consequence: e.deduct_from_leave_bal,
            late_per_month: e.conseq_late_month,
            days_lossOfPay: e.conseq_days_from_leaves,
            attendenceSetttings_id: attendance_id,
            max_regularization: e.max_regularization,
          },
          {
            headers: {
              Authorization: `Bearer ${attendance_redux.TOKEN}`,
            },
            httpsAgent: attendance_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("atten=", response);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
    finally{
      setLoading(false)
    }
  }
  // put attendance deatils

  // put attendance deatils
  async function update_details_post(e) {
    setLoading(true)
    try {
      await axiosInstance
        .post(
          `InsertAttendenceSettings`,
          {
            geofencing: e.geofencing,
            geofencing_meter: e.geofencing_meter,
            relaxation: e.enable_relax,
            first_check_lateby: e.first_check_lateby,
            early_checkout: e.last_check_earlyby,
            // late_consequence: e.conseq_for_late,
            late_consequence: "0",
            what_consequence: e.deduct_from_leave_bal,
            late_per_month: e.conseq_late_month,
            days_lossOfPay: e.conseq_days_from_leaves,
            max_regularization: e.max_regularization,
            // overtime:"0"
          },
          {
            headers: {
              Authorization: `Bearer ${attendance_redux.TOKEN}`,
            },
            httpsAgent: attendance_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("atten=", response);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
    finally{
      setLoading(false);
    }
  }
  // put attendance deatils

  return (
  
      <form onSubmit={handleSubmit}>
        {/* <div className="attendancebox mt-2">
          <div style={{ borderBottom: "1px dashed var(--primary-color)" }}>
            <div className="attendancearea" style={{ border: "none" }}>
              <h2>How will attendance calculated</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn" style={{ marginLeft: "0" }}>
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="how_att_calculated"
                      value="First_Last"
                      checked={values.how_att_calculated === "First_Last"}
                      onChange={(e) => {
                        setFieldValue("how_att_calculated", e.target.value);
                      }}
                    />
                    <span>First Check in & Last Check out</span>
                  </label>
                </div>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="how_att_calculated"
                      value="Every"
                      checked={values.how_att_calculated === "Every"}
                      onChange={(e) => {
                        setFieldValue("how_att_calculated", e.target.value);
                      }}
                    />
                    <span>Every Valid Check in & Check out</span>
                  </label>
                </div>
              </div>
            </div>
            <div style={{ height: "20px", textAlign: "right" }}>
              {errors.how_att_calculated && touched.how_att_calculated ? (
                <p className="form-error">{errors.how_att_calculated}</p>
              ) : null}
            </div>
          </div>
        </div> */}

        <div className="attendancebox mt-2">
          <div style={{ borderBottom: "1px dashed var(--primary-color)" }}>
            <div className="attendancearea" style={{ border: "none" }}>
              <h2>Is geofencing permitted?</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn" style={{ marginLeft: "0" }}>
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="geofencing"
                      value="1"
                      checked={values.geofencing === "1"}
                      onChange={(e) => {
                        setFieldValue("geofencing", e.target.value);
                      }}
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="geofencing"
                      value="0"
                      checked={values.geofencing === "0"}
                      onChange={(e) => {
                        setFieldValue("geofencing", e.target.value);
                      }}
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
          
            <div style={{ height: "20px", textAlign: "right" }}>
              {errors.geofencing && touched.geofencing ? (
                <p className="form-error">{errors.geofencing}</p>
              ) : null}
            </div>
            {values.geofencing === "1" && (
              <div style={{ border: "none" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "15px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <h2>Geofencing Range In Meter</h2>
                    <input
                      type="text"
                      style={{ width: "8%", margin: "0px 6px 0px 6px" }}
                      className="custominputprofile"
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                      autoComplete="off"
                      name="geofencing_meter"
                      value={values.geofencing_meter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <h2>min</h2> */}
                   
                  </div>
                  
                  {touched.geofencing_meter && errors.geofencing_meter ? (
                      <p className="form-error" style={{textAlign:"right"}}>{errors.geofencing_meter}</p>
                    ) : null}
                </div>
                <div style={{ height: "20px", textAlign: "right" }}>
              
              <p style={{fontSize:"12px"}} >Note: We recommend setting the geofencing radius to at least 50 (meter) for improved accuracy.</p>
           
          </div>

                {/* <div className="attendancearea" style={{ border: "none" }}>
                  <h2>Multiple check in and check out allowed</h2>
                  <div className="attendace_Checkarea">
                    <div className="radio_btn" style={{ marginLeft: "0" }}>
                      <label>
                        <input
                          style={{ marginLeft: "10px" }}
                          type="radio"
                          name="geofencing_meter"
                          value="1"
                          checked={values.geofencing_meter === "1"}
                          onChange={(e) => {
                            setFieldValue("geofencing_meter", e.target.value);
                          }}
                        />
                        <span>Yes</span>
                      </label>
                    </div>
                    <div className="radio_btn">
                      <label>
                        <input
                          style={{ marginLeft: "10px" }}
                          type="radio"
                          name="geofencing_meter"
                          value="0"
                          checked={values.geofencing_meter === "0"}
                          onChange={(e) => {
                            setFieldValue("geofencing_meter", e.target.value);
                          }}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div style={{ height: "20px", textAlign: "right" }}>
                  {errors.geofencing_meter && touched.geofencing_meter ? (
                    <p className="form-error">{errors.geofencing_meter}</p>
                  ) : null}
                </div> */}
              </div>
            )}
          </div>
        </div>

        <div className="attendancebox mt-2">
          <div style={{ borderBottom: "1px dashed var(--primary-color)" }}>
            <div className="attendancearea" style={{ border: "none" }}>
              <h2>Enable Relaxation</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn" style={{ marginLeft: "0" }}>
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="enable_relax"
                      value="1"
                      checked={values.enable_relax === "1"}
                      onChange={(e) => {
                        setFieldValue("enable_relax", e.target.value);
                      }}
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="enable_relax"
                      value="0"
                      checked={values.enable_relax === "0"}
                      onChange={(e) => {
                        setFieldValue("enable_relax", e.target.value);
                      }}
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
            <div style={{ height: "20px", textAlign: "right" }}>
              {errors.enable_relax && touched.enable_relax ? (
                <p className="form-error">{errors.enable_relax}</p>
              ) : null}
            </div>
            {values.enable_relax === "1" && (
             
                <div
                  className="mt-1"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingBottom: "15px",
                  }}
                >
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h2>Allowed late check-in time</h2>
                      <input
                        type="text"
                        style={{ width: "20%", margin: "0px 6px 0px 6px" }}
                        className="custominputprofile"
                        onInput={(e) => {
                          e.target.value = (e.target.value || "").replace(
                            /[^\d]/g,
                            ""
                          );
                        }}
                        autoComplete="off"
                        name="first_check_lateby"
                        value={values.first_check_lateby}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <h2>min</h2>
                    </div>
                    {touched.first_check_lateby && errors.first_check_lateby ? (
                      <p className="form-error">{errors.first_check_lateby}</p>
                    ) : null}
                  </div>

                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h2>Allowed early checkout time</h2>
                      <input
                        type="text"
                        style={{ width: "20%", margin: "0px 6px 0px 6px" }}
                        className="custominputprofile"
                        onInput={(e) => {
                          e.target.value = (e.target.value || "").replace(
                            /[^\d]/g,
                            ""
                          );
                        }}
                        autoComplete="off"
                        name="last_check_earlyby"
                        value={values.last_check_earlyby}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <h2>min</h2>
                    </div>
                    {touched.last_check_earlyby && errors.last_check_earlyby ? (
                      <p className="form-error">{errors.last_check_earlyby}</p>
                    ) : null}
                  </div>
                </div>
             
            )}
          </div>
        </div>

        {/* <div className="attendancebox mt-2">
          <div>
            <div className="attendancearea" style={{ border: "none" }}>
              <h2>Would you like to introduce penalties for being late?</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn" style={{ marginLeft: "0" }}>
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="conseq_for_late"
                      value="1"
                      checked={values.conseq_for_late === "1"}
                      onChange={(e) => {
                        setFieldValue("conseq_for_late", e.target.value);
                      }}
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="conseq_for_late"
                      value="0"
                      checked={values.conseq_for_late === "0"}
                      onChange={(e) => {
                        setFieldValue("conseq_for_late", e.target.value);
                      }}
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>
            <div style={{ height: "20px", textAlign: "right" }}>
              {errors.conseq_for_late && touched.conseq_for_late ? (
                <p className="form-error">{errors.conseq_for_late}</p>
              ) : null}
            </div>
          </div>
          {values.conseq_for_late === "1" && (
            <>
              <div className="lateconsqbox">
                <h2>Consequences for late arrivals</h2>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="deduct_from_leave_bal"
                      value="deduct from leave bal"
                      checked={
                        values.deduct_from_leave_bal === "deduct from leave bal"
                      }
                      onChange={(e) => {
                        setFieldValue("deduct_from_leave_bal", e.target.value);
                      }}
                    />
                    If late coming be deducted from the leaves balance?
                  </label>
                </div>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="deduct_from_leave_bal"
                      value="Loss of pay"
                      checked={values.deduct_from_leave_bal === "Loss of pay"}
                      onChange={(e) => {
                        setFieldValue("deduct_from_leave_bal", e.target.value);
                      }}
                    />
                    if late coming count in LOP (Loss of pay)
                  </label>
                </div>
                <div className="radio_btn">
                  <label>
                    <input
                      style={{ marginLeft: "10px" }}
                      type="radio"
                      name="deduct_from_leave_bal"
                      value="deducted from leave balance then count as LOP"
                      checked={
                        values.deduct_from_leave_bal ===
                        "deducted from leave balance then count as LOP"
                      }
                      onChange={(e) => {
                        setFieldValue("deduct_from_leave_bal", e.target.value);
                      }}
                    />
                    If late coming be deducted from leave balance (if available)
                    then count as LOP (if leave balance is not sufficient)
                  </label>
                </div>
              </div>
              <div
                style={{
                  height: "20px",
                  textAlign: "left",
                  marginLeft: "10px",
                }}
              >
                {errors.deduct_from_leave_bal &&
                touched.deduct_from_leave_bal ? (
                  <p className="form-error">{errors.deduct_from_leave_bal}</p>
                ) : null}
              </div>
            </>
          )}

          {(values.deduct_from_leave_bal ===
            "deducted from leave balance then count as LOP" ||
            values.deduct_from_leave_bal === "deduct from leave bal") && (
            <>
              <div className="mt-2" style={{ display: "flex" }}>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      style={{ width: "20%", margin: "0px 6px 0px 6px" }}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                      className="custominputprofile"
                      autoComplete="off"
                      name="conseq_late_month"
                      value={values.conseq_late_month}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <h2>Late/Months = Deduction of</h2>
                  </div>
                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.conseq_late_month && touched.conseq_late_month ? (
                      <p className="form-error">{errors.conseq_late_month}</p>
                    ) : null}
                  </div>
                </div>

                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                      style={{ width: "20%", margin: "0px 6px 0px 6px" }}
                      className="custominputprofile"
                      autoComplete="off"
                      name="conseq_days_from_leaves"
                      value={values.conseq_days_from_leaves}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <h2>days from leaves</h2>
                  </div>
                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.conseq_days_from_leaves &&
                    touched.conseq_days_from_leaves ? (
                      <p className="form-error">
                        {errors.conseq_days_from_leaves}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div> */}
        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Max Regularization</h2>
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  type="text"
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  autoComplete="off"
                  name="max_regularization"
                  id="max_regularization"
                  // placeholder="INV"
                  value={values.max_regularization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {touched.max_regularization && errors.max_regularization && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.max_regularization}
            </p>
          )}
        </div>

        <div class="butnsec">
          <button class="cusbtn" type="submit" disabled={loading}>
            {loading?"Saving...":"Save"}
          </button>
        </div>
      </form>
    
  );
};

export default Attendance_Settings;
