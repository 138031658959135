import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";

const EmailVerify = () => {
  const navigate = useNavigate();
  const emailverify = useSelector((state) => state.Reducer);
  useEffect(() => {
    // Get the entire URL of the page
    const pageUrl = window.location.href;
    const url = new URL(pageUrl);
    const token = url.searchParams.get("token");
    VerifyEmail(token);
  });

  async function VerifyEmail(token) {
    console.log(token);
    try {
      await axios
        .post(
          `companyEmailVerify`,
          {
            token: token,
          },
          {
            httpsAgent: emailverify.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            swal({
              title: "Success!",
              text: "Email Verified Please Log In Now",
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            setTimeout(() => {
              navigate("/Sign_Up");
            }, 3000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data);
      swal({
        title: "Failure!",
        text: "Verification Fail Please Try Again",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      setTimeout(() => {
        navigate("/Sign_Up");
      }, 3000);
    }
  }
  return (
    <>
      {/* <div className="emailv">
        {text ? (
          <div className="rightboxemail">
            <div className="on_leave" style={{ marginBottom: "2%" }}>
              <h1>{text}</h1>
              <div class="form-description">
                <button
                  className="cusbtn"
                  onClick={() => {
                    navigate("/Sign_Up");
                  }}
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </>
  );
};

export default EmailVerify;
