import React, { useEffect, useState } from "react";
import axios from "axios";
import Quill from "quill";
import "quill/dist/quill.core.css";
import axiosInstance from "../../../utils/AxiosInstance";
const Tandc = () => {
  const [terms, setTerms] = useState("");
  async function getTerms() {
    try {
      const res = await axiosInstance.get("terms");
      console.log(res.data[0].content);
      setTerms(res.data[0].content);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getTerms();
    console.log(terms);
  }, []);
  return (
    <div
      style={{ padding: "10px" }}
      dangerouslySetInnerHTML={{ __html: terms }}
    ></div>
  );
};

export default Tandc;
