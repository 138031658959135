import React, { useState, useRef, useEffect, useCallback } from "react";
import Navbar from "../Navbar";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import {
  BsFillCameraVideoFill,
  BsFillFileEarmarkPdfFill,
} from "react-icons/bs";
import { FaVideo } from "react-icons/fa";
import { BiCameraMovie, BiMoviePlay } from "react-icons/bi";
import { MdDelete, MdOutlineDeleteSweep, MdPictureAsPdf } from "react-icons/md";
import { AiTwotoneFilePdf } from "react-icons/ai";
import swal from "sweetalert";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstanceInstance
import { useSelector } from "react-redux";

const Assistance = () => {
  // upload video
  const [selectedFile, setSelectedFile] = useState(null);
  const bulkGuestRef = useRef(null);
  const employee_page = useSelector((state) => state.Reducer);
  const videoPreviewRef = useRef(null);

  // get all policies
  const [policy, setPolicy] = useState();
  const get_all_policy = useCallback(async () => {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}getSettings`, {
          type: "Policy",
        })
        .then((response) => {
          // Extracting names from URLs and updating state
          const policies = response.data.map((item) => {
            const fileNameParts = item.filename.split("_");
            const nameWithoutPrefix = fileNameParts.slice(1).join("_");
            return { ...item, name: nameWithoutPrefix };
          });
          setPolicy(policies);
          console.log("policy", policy);
          // setPolicy(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_all_policy();
  }, [get_all_policy]);
  // get all policies

  // get video
  const [video, setVideo] = useState();
  const get_video = useCallback(async () => {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}getSettings`, {
          type: "Video",
        })
        .then((response) => {
          setVideo(response.data[0]);
          console.log("video", response.data[0]);
          // console.log("video",video.value);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_video();
  }, [get_video]);
  // get video

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.type.startsWith("video/")) {
      setSelectedFile(file);
      updatePreview(file);
    } else {
      alert("Please drop a valid video file.");
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file.type.startsWith("video/")) {
      setSelectedFile(file);
      updatePreview(file);
    } else {
      alert("Please select a valid video file.");
    }
  };

  const updatePreview = (file) => {
    videoPreviewRef.current.src = URL.createObjectURL(file);
  };

  // upload video

  // upload a video api
  const UploadVideo = (e) => {
    e.preventDefault();
    if (!selectedFile) {
      swal({
        title: "Failure!",
        text: "Please Upload a video",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    // Check if the size of the selected file exceeds 100MB (100 * 1024 * 1024 bytes)
    if (selectedFile.size > 100 * 1024 * 1024) {
      swal({
        title: "Failure!",
        text: "File size exceeds 100MB limit",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }
    // Check if the size of the selected file exceeds 100MB (100 * 1024 * 1024 bytes)
    upload_video();
    async function upload_video() {
      // console.log(selectedFile);
      var dataa = new FormData();
      dataa.append("video", selectedFile);

      try {
        await axiosInstance
          .post(`${employee_page.GLOBAL_API}settingsvideo`, dataa)
          .then((response) => {
            console.log("bulk=", response.data);
            if (response.status == 200) {
              const successMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Success!",
                text: successMessage,
                icon: "success",
              });
              setTimeout(() => {
                get_video();
                swal.close();
              }, 2000);
            } else {
              const errorMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Failure!",
                text: errorMessage,
                icon: "error",
              });

              // Close the SweetAlert after 3000 milliseconds (3 seconds)
              setTimeout(() => {
                swal.close();
              }, 2000);
            }
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
        const errorMessage = e.response.data.error || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
        });
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    }
  };
  // upload a video api

  // upload multiple file file
  const [singleFile, setSingleFile] = useState();
  const uploadmultifile = React.createRef();

  const handleDragOverSingle = (e) => {
    e.preventDefault();
  };

  const handleDragEnterSingle = (e) => {
    e.preventDefault();
  };

  const handleDragLeaveSingle = (e) => {
    e.preventDefault();
  };

  const handleDropSingle = (e) => {
    e.preventDefault();
    setSingleFile(e.dataTransfer.files[0]);
  };

  const handleFileInputSingle = (e) => {
    setSingleFile(e.target.files[0]);
  };

  const removeFileSingle = (index) => {
    const updatedFiles = [...singleFile];
    updatedFiles.splice(index, 1);
    setSingleFile(updatedFiles);
  };

  // upload single file
  const upload_pdf = (e) => {
    e.preventDefault();
    // console.log(singleFile);
    if (!singleFile) {
      swal({
        title: "Failure!",
        text: "Please Select a file",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    upload_pdf();
    async function upload_pdf() {
      // console.log(files[0]);
      var dataa = new FormData();
      dataa.append("file", singleFile);

      try {
        await axiosInstance
          .post(`${employee_page.GLOBAL_API}settingsPolicy`, dataa, {
            headers: {
              Authorization: `Bearer ${employee_page.TOKEN}`,
            },
            httpsAgent: employee_page.HTTP_AGENT,
          })
          .then((response) => {
            // console.log("bulk=", response.data.count);
            if (response.status == 200) {
              get_all_policy();
              const successMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Success!",
                text: successMessage,
                icon: "success",
              });
              setTimeout(() => {
                swal.close();
              }, 2000);
            } else {
              const errorMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Failure!",
                text: errorMessage,
                icon: "error",
              });

              // Close the SweetAlert after 3000 milliseconds (3 seconds)
              setTimeout(() => {
                swal.close();
              }, 2000);
            }
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
        const errorMessage = e.response.data.error || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
        });
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    }
  };

// select file
  // get document
  const hard_code_documents = [
    {
      value: "Aadhar",
    },
    {
      value: "Pan",
    },
    {
      value: "10th Certificate",
    },
    {
      value: "12th Certificate",
    },
    {
      value: "Graduation Certificate",
    },
    {
      value: "Post Graduation",
    },
   
    {
      value: "Experience Letter",
    },
    {
      value: "Signed Copy Of Offer Letter",
    }
  ];
  const [documents, setDocument] = useState();
  const [clickedIndices, setClickedIndices] = useState(
    Array(hard_code_documents.length).fill(false)
  );

  const get_doucuments = useCallback(async () => {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}getSettings`, {
          type: "Document",
        })
        .then((response) => {
          setDocument(response.data);
          console.log("documnent", response.data);

          if (response.data) {
            setClickedIndices((prevClickedIndices) =>
              prevClickedIndices.map((clicked, index) =>
              response.data.some(
                  (doc) => doc.value === hard_code_documents[index].value
                )
                  ? true
                  : clicked
              )
            );
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_doucuments();
  }, [get_doucuments]);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  console.log("selectedDocuments", selectedDocuments);

  const handleDivClick = (index) => {
    setClickedIndices((prevClickedIndices) => {
      const newClickedIndices = [...prevClickedIndices];
      newClickedIndices[index] = !prevClickedIndices[index];
      const selected = hard_code_documents
        .filter((doc, i) => newClickedIndices[i])
        .map((doc) => doc.value);
      setSelectedDocuments(selected);
      return newClickedIndices;
    });
  };
  // select file

  // submit documents
  async function submitDocument() {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}settingsDocuments`, {
          documents: selectedDocuments,
        })
        .then((response) => {
          // console.log("bulk=", response.data.count);
          if (response.status == 200) {
            get_all_policy();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // submit documents

  // delete policy
  async function delete_policy(e) {
    try {
      await axiosInstance
        .delete(`${employee_page.GLOBAL_API}DeletePolicy`, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
          data: {
            policy_id: e,
          },
        })
        .then((response) => {
          console.log("update=", response.data);
          if (response.status == 200) {
            get_all_policy();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // delete policy

  // delete video
  async function deleteVideo() {
    try {
      await axiosInstance
        .delete(`${employee_page.GLOBAL_API}Deletevideo`, {
          data: {
            video_id: video.id,
          },
        })
        .then((response) => {
          console.log("update=", response.data);
          get_video();
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // delete video
  return (
    <div className="main-container">
      {/* <MainSidebar /> */}
      <div className="allcontent">
        <Navbar />
        <div className="card mt-2">
          <div className="headingbox">
            <div className="headingleftbox">
              <h1>Orgaization Work Culture Video</h1>
              <p>Update Your Orgaization Work Culture</p>
            </div>
            <div className="headingrightbox">
              <FaVideo size="2em" style={{ color: "var(--secondary-color)" }} />
            </div>
          </div>
          {/* video upload  */}
          <div className="input-block mt-1">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="setuploadfile" style={{ width: "49%" }}>
                <div className="app" style={{ width: "100%" }}>
                  <div
                    className="file-upload"
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    ref={bulkGuestRef}
                  >
                    <div>
                      {selectedFile &&
                      selectedFile.type.startsWith("video/") ? (
                        <BiMoviePlay size="6em" />
                      ) : (
                        <BsFillFileEarmarkPdfFill size="6em" />
                      )}
                      <h3>Drag and Drop Video File</h3>
                      <p>or</p>
                      <button
                        onClick={() => {
                          if (video) {
                            swal({
                              title:
                                "If You Upload a new video old one is deleted",
                              text: "Do You Really want to Upload",
                              icon: "info",
                              buttons: {
                                yes: "Yes",
                                no: "No",
                              },
                            }).then((response) => {
                              if (response === "yes") {
                                deleteVideo();
                                bulkGuestRef.current
                                  .querySelector("input")
                                  .click();
                              } else if (response === "no") {
                              }
                            });
                          } else {
                            bulkGuestRef.current.querySelector("input").click();
                          }
                        }}
                        className="cusbtn"
                      >
                        {selectedFile ? "Upload New Video" : "Upload"}
                      </button>
                    </div>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="file"
                      accept="video/*"
                      onChange={(e) => handleFileInput(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="uploaded-video" style={{ width: "49%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3>Uploaded Video</h3>
                  {video ? (
                    <MdDelete
                      style={{ color: "#be202e", cursor: "pointer" }}
                      size="1.8em"
                      onClick={deleteVideo}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {selectedFile ? (
                  <div className="app" style={{ width: "100%" }}>
                    <video
                      ref={videoPreviewRef}
                      controls
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "5px",
                        maxHeight: "200px",
                      }}
                    >
                      <source
                        src={URL.createObjectURL(selectedFile)}
                        type={selectedFile.type}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : video ? (
                  <div className="app" style={{ width: "100%" }}>
                    <video
                      // ref={videoPreviewRef}
                      controls
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "5px",
                        maxHeight: "200px",
                      }}
                    >
                      <source src={video.value} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* You can put other components here */}
            <div className="drag_submit mt-2">
              <button type="button" className="cusbtn" onClick={UploadVideo}>
                Upload video
              </button>
            </div>
          </div>
          {/* video upload  */}
        </div>

        {/* policy pdf  */}
        <div className="card mt-2">
          <div className="headingbox">
            <div className="headingleftbox">
              <h1>Policy PDF</h1>
              <p>Update Your Policies</p>
            </div>
            <div className="headingrightbox">
              <MdPictureAsPdf
                size="2em"
                style={{ color: "var(--secondary-color)" }}
              />
            </div>
          </div>

          <div className="multi_file_upload">
            {/* file upload  */}
            <div style={{ width: "49%" }} className="app">
              <div className="parent">
                <div
                  className="file-upload"
                  onDragOver={handleDragOverSingle}
                  onDragEnter={handleDragEnterSingle}
                  onDragLeave={handleDragLeaveSingle}
                  onDrop={handleDropSingle}
                  ref={uploadmultifile}
                >
                  {singleFile ? (
                    <BsFillFileEarmarkPdfFill size="6em" />
                  ) : (
                    <BiMoviePlay size="6em" />
                  )}
                  {singleFile ? (
                    <h3>{singleFile.name}</h3>
                  ) : (
                    <h3>Drag and drop PDF</h3>
                  )}
                  {/* <h3>Drag and drop Multiple PDF</h3> */}
                  <p>or</p>
                  <button
                    className="cusbtn"
                    onClick={() =>
                      uploadmultifile.current.querySelector("input").click()
                    }
                  >
                    Upload
                  </button>

                  <input
                    type="file"
                    style={{
                      color: "var(--Whiteblack-color)",
                      display: "none",
                    }}
                    name="file"
                    accept=".pdf"
                    multiple
                    onChange={handleFileInputSingle}
                  />
                </div>
              </div>
            </div>
            {/* file upload  */}

            {/* show uloaded files  */}
            <div className="multi_file_scroll">
              <h1 className="mb-1">Uploaded Files</h1>
              <div className="for_scroll_only">
                {policy
                  ? policy.map((file, index) => (
                      <div
                        className="card multi_file_part mb-1 mt-1"
                        style={{ marginBottom: "5px" }}
                        key={index}
                      >
                        <div className="pdf_names">
                          <BsFillFileEarmarkPdfFill
                            style={{ color: "#be202e", marginRight: "5px" }}
                            size="2em"
                          />
                          <div>
                            <p>{file.name}</p>
                            <p>{file.size} KB</p>
                          </div>
                        </div>
                        <MdDelete
                          style={{ color: "#be202e", cursor: "pointer" }}
                          size="2em"
                          onClick={() => delete_policy(file.id)}
                        />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
            {/* show uloaded files  */}
          </div>
          <div className="drag_submit mt-2">
            <button type="button" onClick={upload_pdf} className="cusbtn">
              Upload File
            </button>
          </div>
        </div>
        {/* policy pdf  */}

        {/* select files  */}
        <div className="card mt-2 mb-2">
          <div className="headingbox">
            <div className="headingleftbox">
              <h1>List of Documents</h1>
              <p>List all the documents you want from your Employee</p>
            </div>
            <div className="headingrightbox">
              <AiTwotoneFilePdf
                size="2em"
                style={{ color: "var(--secondary-color)" }}
              />
            </div>
          </div>

          {/* map files  */}
          <div className="mb-2">
            <div
              className="document_divs"
              style={{ width: "73%", marginTop: "10px" }}
            >
              {hard_code_documents
                ? hard_code_documents.map((ele, index) => {
                    return (
                      <>
                        <div
                          className={`docs ${
                            clickedIndices[index] ? "clicked" : ""
                          }`}
                          key={index}
                          onClick={() => handleDivClick(index)}
                        >
                          <p>{ele.value}</p>
                        </div>
                      </>
                    );
                  })
                : ""}
            </div>
            <div className="drag_submit mt-2">
              <button className="cusbtn" type="button" onClick={submitDocument}>
                Submit Documents
              </button>
            </div>
          </div>
          {/* map files  */}
        </div>
        {/* select files  */}
      </div>
    </div>
  );
};

export default Assistance;
