import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { CgCloseR } from "react-icons/cg";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useSelector } from "react-redux";
import { GlobalFilter } from "../Employees/GlobalFilter";
import swal from "sweetalert";
import FormData from "form-data";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextareaAutosize } from "@mui/material";
const Edit = (props) => {
  //for uploading file
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  //for loading
  const [loading, setLoading] = useState(false);
  const employee_page = useSelector((state) => state.Reducer);
  const signUpSchema = Yup.object({
    edit_description: Yup.string().required("Enter Description"),
    announcementImg: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
      if (file) {
        return file.size <= 2000000;
      } else {
        return true;
      }
    }),
  });

  const [initialValues, setInitialValues] = useState({
    edit_description: "",
    edit_announcementImg: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      edit_annoucement(values);
    },
  });

  //announcemnet by id
  const get_annoucementById = useCallback(async (e) => {
    try {
      setLoading(true);
      await axiosInstance
        .post(
          `AnnoucementById`,
          { annoucement_id: props.editId },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setFieldValue("edit_description", response.data[0].annoucement);
            setFieldValue("edit_announcementImg", response.data[0].image);
            setPreviewImage(response.data[0].image);
          }
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const edit_annoucement = useCallback(async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("annoucement_id", props.editId);
      formData.append("annoucement", e.edit_description);
      formData.append("annoucement_image", e.announcementImg);

      await axiosInstance
        .put(`EditAnnoucement`, formData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200) {
            props.setEdit_Announcment(!props.setEdit_Announcment);
            props.get_all_annoucement();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    get_annoucementById();
  }, [props.editId]);

  return (
    <div className="modal">
      <div
        onClick={() => {
          props.setEdit_Announcment(!props.Edit_Announcment);
        }}
        className="overlay"
      ></div>
      <div className="modal-content">
        <div className="modal-header">
          <h2>Edit Announcement</h2>
          <button
            className="close-modal"
            onClick={() => {
              props.setEdit_Announcment(!props.Edit_Announcment);
            }}
            type="button"
          >
            <CgCloseR />
          </button>
        </div>
        <div className="modal-body">
          {loading ? (
            <div className="loaderparent">
              <span className="loading-fallback"></span>
            </div>
          ) : (
            <form action="" onSubmit={handleSubmit}>
              <div className="input-container mt-2" style={{ flex: "1" }}>
                <label>Description</label>
                <TextareaAutosize
                  name="edit_description"
                  placeholder="Description"
                  value={values.edit_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  variant="outlined"
                  style={{ height: "80px" }}
                />
                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.edit_description && touched.edit_description ? (
                    <p className="form-error">{errors.edit_description}</p>
                  ) : null}
                </div>
              </div>

              <div className="setuploadfile">
                <div
                  className="app_upload"
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <div className="parent" style={{ width: "100%" }}>
                    <div className="file-upload">
                      {selectedFile || previewImage ? (
                        <div>
                          <img
                            style={{ height: "117px" }}
                            src={
                              selectedFile
                                ? URL.createObjectURL(selectedFile)
                                : previewImage
                            }
                            alt="upload"
                            onClick={() => fileInputRef.current.click()}
                          />
                          {selectedFile && <h3>{selectedFile.name}</h3>}
                        </div>
                      ) : (
                        <h3>
                          Drop your image here, or
                          <span style={{ color: "#2343a4" }}> upload</span>
                          <br />
                          <span style={{ fontSize: "12px", color: "#767676" }}>
                            PNG, SVG, and JPG only
                          </span>
                        </h3>
                      )}

                      <input
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setSelectedFile(file);
                            setPreviewImage(URL.createObjectURL(file));
                            setFieldValue("announcementImg", file);
                          }
                        }}
                        ref={fileInputRef}
                        name="announcementImg"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="submitbtnnew" style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  className="cusbtn mt-1"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
const Announcement = () => {
  const signUpSchema = Yup.object({
    add_description: Yup.string().required("Enter Description"),
    add_link: Yup.string(),
    announcementImg: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
      if (file) {
        return file.size <= 2000000;
      } else {
        return true;
      }
    }),
  });

  const [initialValues, setInitialValues] = useState({
    add_description: "",
    add_link: "",
    announcementImg: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      add_annoucement(values);
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
    },
  });

  const employee_page = useSelector((state) => state.Reducer);

  const [table_data, setTable] = useState();
  const [add_announcement, setadd_announcement] = useState(false);
  const [Edit_Announcment, setEdit_Announcment] = useState(false);
  const [editId, setEditId] = useState();
  const [loading, setLoading] = useState(false);

  const get_all_annoucement = useCallback(async () => {
    try {
      await axiosInstance
        .get(`AllAnnoucement`, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("table_data", response.data);
          setTable(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);
  const add_annoucement = useCallback(async (e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("annoucement", e.add_description);
      formData.append("annoucement_image", e.announcementImg);

      await axiosInstance
        .post(`InsertAnnoucement`, formData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200) {
            setadd_announcement(false);
            resetForm();
            setSelectedFile(null);
            get_all_annoucement();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    get_all_annoucement();
  }, [get_all_annoucement]);

  const data = useMemo(() => {
    if (table_data) {
      return table_data;
    } else {
      return [];
    }
  }, [table_data]);

  const COLUMNS = [
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            // marginLeft: "33px",
            cursor: "pointer",
          }}
        >
          <div className="name_email">
            <p style={{ fontWeight: "bold" }}>
              {" "}
              {row.original.created_by.split("(")[0].trim()}
            </p>
          </div>
        </div>
      ),
    },
    {
      Header: "Date/Time",
      accessor: "date_time",
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
          >
            {new Date(row.original.date).toLocaleDateString() },{" "} {row.original.time}
          </p>
        </>
      ),
    },
    {
      Header: "Announcement",
      accessor: "announcement",
      Cell: ({ row }) => (
        <>
          <p>
            {row.original.annoucement.substr(0, 50)}
            {row.original.annoucement.length > 50 ? "..." : null}
          </p>
        </>
      ),
    },
    {
      Header: "Like",
      accessor: "link",
      Cell: ({ row }) => (
        <>
          <p>{row.original.likes}</p>
        </>
      ),
    },
    {
      Header: "Dislike",
      accessor: "dislink",
      Cell: ({ row }) => (
        <>
          <p>{row.original.dislikes}</p>
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <div>
          <BiEdit
            onClick={() => {
              setEdit_Announcment(!Edit_Announcment);
              setEditId(row.original.id);
            }}
            size="1.2em"
            style={{ color: "var(--secondary-color)", cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  //for uploading file
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  // upload file
  return (
    <>
      <div className="main-container">
        {/* <MainSidebar /> */}
        <div className="allcontent">
          <Navbar />
          {/* <Breadcrumbs/> */}
          <div className="mainbox">
            {/* pop up */}
            {add_announcement && (
              <div className="modal">
                <div
                  onClick={() => {
                    setadd_announcement(!add_announcement);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Add Announcement</h2>
                    <button
                      className="close-modal"
                      onClick={() => {
                        setadd_announcement(!add_announcement);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <form onSubmit={handleSubmit} action="">
                    <div className="modal-body">
                      <div
                        className="input-container mt-2"
                        style={{ flex: "1" }}
                      >
                        <label style={{ marginBottom: "20px" }}>
                          Description
                        </label>
                        <TextareaAutosize
                          name="add_description"
                          placeholder="Description"
                          value={values.add_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="text_input"
                          id="outlined-basic"
                          variant="outlined"
                          style={{ height: "80px" }}
                        />

                        <div style={{ height: "20px", textAlign: "left" }}>
                          {errors.add_description && touched.add_description ? (
                            <p className="form-error">
                              {errors.add_description}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="setuploadfile">
                        <div
                          className="app_upload"
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => fileInputRef.current.click()}
                        >
                          <div className="parent" style={{ width: "100%" }}>
                            <div className="file-upload">
                              {selectedFile ? (
                                <div>
                                  {" "}
                                  <img
                                    style={{ height: "117px" }}
                                    src={previewImage} // Display preview
                                    alt="upload"
                                    onClick={() => fileInputRef.current.click()}
                                  />
                                </div>
                              ) : (
                                <h3>
                                  Drop your image here, or
                                  <span style={{ color: "#2343a4" }}>
                                    {" "}
                                    upload
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "#767676",
                                    }}
                                  >
                                    PNG, SVG, and JPG only
                                  </span>
                                </h3>
                              )}

                              <input
                                accept=".png,.jpg,.jpeg"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setSelectedFile(file);
                                    setPreviewImage(URL.createObjectURL(file)); // Set preview image
                                    setFieldValue("announcementImg", file);
                                  }
                                }}
                                ref={fileInputRef}
                                name="announcementImg"
                                type="file"
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div style={{ height: "20px", textAlign: "left" }}>
                          {errors.announcementImg && touched.announcementImg ? (
                            <p className="form-error">
                              {errors.announcementImg}
                            </p>
                          ) : null}
                        </div>

                      <div
                        className="submitbtnnew"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="submit"
                          className="cusbtn mt-1"
                          disabled={loading}
                        >
                          {loading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* pop up of edit announcement*/}
            {Edit_Announcment && (
              <Edit
                editId={editId}
                get_all_annoucement={get_all_annoucement}
                Edit_Announcment={Edit_Announcment}
                setEdit_Announcment={setEdit_Announcment}
              />
            )}

            {/* breadcrumbs and pop up  */}
            <div className="breadcrumbs_pop">
              <div></div>
              <button
                onClick={() => {
                  setadd_announcement(!add_announcement);
                }}
                className="cusbtn"
              >
                <BiPlusMedical size="1.2rem" />
              </button>
            </div>
            {/* breadcrumbs and pop up  */}
            {table_data ? (
              <div className="card mt-3">
                <div
                  className="department tablebox"
                  style={{ backgroundcolor: "white" }}
                >
                  {/* pagination */}
                  <div className="pages">
                    <div className="tablebottomboxleft">
                      <h1>Show</h1>
                      <span>
                        <select
                          className="selectnoofpagescolor"
                          style={{
                            padding: "4px 10px 4px 3px",
                            borderRadius: "10px",
                          }}
                          value={pageSize}
                          // className="showrows"
                          onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                          {[10, 15, 20].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>

                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                  {/* pagination */}

                  <div className="resposive_table">
                    <table className="mt-2" {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroups) => (
                          <tr {...headerGroups.getHeaderGroupProps()}>
                            {headerGroups.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? "🔽"
                                      : "🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {/* {flight_data.EVENT_TABLE_DATA  ? (
                        <> */}

                        {page.length === 0 ? (
                          <tr>
                            <td>No data</td>
                          </tr>
                        ) : (
                          page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td
                                      style={{
                                        textalign: "center",
                                      }}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <button
                      className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiLeftArrow />
                    </button>
                    <button
                      className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiRightArrow />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card mt-3">
                <div
                  className="department tablebox"
                  style={{ backgroundcolor: "white" }}
                >
                  {/* pagination */}
                  <div className="pages">
                    <div className="tablebottomboxleft">
                      <h1>Show</h1>
                      <span>
                        <select
                          className="selectnoofpagescolor"
                          style={{
                            padding: "4px 10px 4px 3px",
                            borderRadius: "10px",
                          }}
                          value={pageSize}
                          // className="showrows"
                          onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                          {[10, 15, 20].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>

                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                  {/* pagination */}

                  <table className="mt-2" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? "🔽"
                                    : "🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {/* {flight_data.EVENT_TABLE_DATA  ? (
                      <> */}

                      <tr>
                        <td>No data</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="pagination">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <button
                      className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiLeftArrow />
                    </button>
                    <button
                      className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiRightArrow />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* <h1 class="text-3xl font-bold underline">
      Hello world!
    </h1> */}
    </>
  );
};

export default Announcement;
