import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Dashboardcardbox from "./Dashboardcardbox";
import { FaUser, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import dp from "../../components/photos/dp.jpg";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../utils/AxiosInstance.jsx";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import anniversary from "../../components/photos/years.png";
import leave from "../../components/photos/leave.png";
import birthday from "../../components/photos/birthdaycake.png";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // get_all_birthday api
  const employee_page = useSelector((state) => state.Reducer);
  const [Birthday, setBirthday] = useState("");
  async function get_all_birthday() {
    try {
      await axiosInstance.get(`Birthday`).then((response) => {
        // console.log("d", response.data);
        setBirthday(response.data);
      });
    } catch (e) {
    
      console.log(e.response.data.error);
    }
  }
  useEffect(() => {
    get_all_birthday();
  }, []);
  // get_all_birthday api

  // get_all_shift_donut api
  const [donut, setDonut] = useState("");
  async function get_all_shift_donut() {
    try {
      await axiosInstance.get(`AllShifts`).then((response) => {
        const extractedData = response.data.map(
          ({ shift_name: label, employee_count: value }) => ({
            label,
            value,
          })
        );
        // console.log("donut",extractedData);

        setDonut(extractedData);
      });
    } catch (e) {
   
      console.log(e.response.data.error);
    }
  }
  useEffect(() => {
    get_all_shift_donut();
  }, []);
  // get_all_shift_donut api

  // get_all_empl_bar_chart api
  const [bar_chart, setBar_chart] = useState("");
  async function get_all_empl_bar_chart() {
    try {
      await axiosInstance.get(`AllDepartment`).then((response) => {
        const extractedData = response.data.map(
          ({ department_name: Department, employee_count: Count }) => ({
            Department,
            Count,
          })
        );
        console.log("bar_chart", extractedData);

        setBar_chart(extractedData);
      });
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    get_all_empl_bar_chart();
  }, []);
  // get_all_empl_bar_chart api

  // get_all_Anniversary api
  const [Anniversary, setAnniversary] = useState("");
  async function get_all_Anniversary() {
    try {
      await axiosInstance.get(`Anniversary`).then((response) => {
        // console.log("d", response.data);
        setAnniversary(response.data);
      });
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    get_all_Anniversary();
  }, []);
  // get_all_Anniversary api

  // get_all_On_leave_today api
  const [On_leave_today, setOn_leave_today] = useState("");
  async function get_all_On_leave_today() {
    try {
      await axiosInstance.get(`OnLeaveToday`).then((response) => {
        // console.log("d", response.data);
        setOn_leave_today(response.data);
      });
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    get_all_On_leave_today();
  }, []);
  // get_all_On_leave_today api

  // get_all_cards_data api
  const [cards_data, setcards_data] = useState("");
  async function get_all_cards_data() {
    try {
      await axiosInstance.get(`Cards`).then((response) => {
        setcards_data(response.data.Data);
      });
    } catch (e) {
      console.log(e.response.data.error);
    }
  }
  useEffect(() => {
    get_all_cards_data();
  }, []);
  // get_all_cards_data api

  // Total_att_chart api
  const [Total_att_chart, setTotal_att_chart] = useState([
    {
      id: "Attendance",
      color: "hsl(320, 70%, 50%)",
      data: [],
    },
  ]);
  const [maxYPlusTwo, setMaxYPlusTwo] = useState(0); // Initialize with 0
  async function Total_att_chart_api() {
    try {
      await axiosInstance.get(`AttendenceChart1`).then((response) => {
        // console.log("d", response.data.Data);
        // Extract y values
        const yValues = response.data.Data.map((item) => item.y);

        // Find maximum y value
        const max = Math.max(...yValues);

        // Add 2 to the maximum value
        const result = (max + 2).toString();

        setMaxYPlusTwo(result);
        const transformedData = response.data.Data.map(({ date, count }) => ({
          x: date, // Map 'date' to 'x'
          y: count, // Map 'count' to 'y'
        }));
        // console.log(transformedData)
        setTotal_att_chart([
          {
            id: "Attendance",
            color: "hsl(320, 70%, 50%)",
            data: transformedData,
          },
        ]);
      });
    } catch (e) {
    console.log(e.response.data.error);
    }
  }
  useEffect(() => {
    Total_att_chart_api();
  }, []);
  // Total_att_chart api

  const chart_bar = [
    { country: "USA", population: 10 },
    { country: "China", population: 20 },
    { country: "India", population: 30 },
    { country: "Brazil", population: 40 },
    { country: "Pakistan", population: 50 },
  ];

  // date format
  function formatDate(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Define month names array
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month name
    const month = monthNames[date.getMonth()];

    // Get the day
    const day = date.getDate();

    // Get the year
    const year = date.getFullYear();

    // Return formatted date string
    return `${day} ${month}, ${year}`;
  }
  // date format

  return (
    <div className="main-container">
      {/* <MainSidebar /> */}
      <div className="allcontent">
        <Navbar />
        <div className="mainbox">
          <div className="dash_event_cards">
            {cards_data ? <Dashboardcardbox data={cards_data} /> : ""}
          </div>

          {/* charts  */}
          <div className="total_chart_attendance card mt-3">
            <div className="on_leave_dashboard">
              <h1>{t("Total_Attendance_Report")}</h1>
            </div>
            <div className="total_attendance_chart">
              {Total_att_chart ? (
                <ResponsiveLine
                  data={Total_att_chart}
                  margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: true,
                    reverse: false,
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45, // Rotate ticks to a slanted position
                    // legend: "Date",
                    legendOffset: 36,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Count",
                    legendOffset: -40,
                    legendPosition: "middle",
                  }}
                  pointSize={10}
                  enableArea={true}
                  areaOpacity={0.1}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  legends={[
                    {
                      anchor: "bottom-right",
                      direction: "column",
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: "left-to-right",
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="charts mt-3">
            <div className="charts_employee card">
              <div className="on_leave_dashboard">
                <h1>
                  Employee By Department
                  {/* {t("Employee by Dept")} */}
                </h1>
              </div>
              <div className="employee_by_dept">
                {bar_chart ? (
                  <ResponsiveBar
                    data={bar_chart}
                    keys={["Count"]}
                    indexBy="Department"
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    axisBottom={{
                      legend: "Department",
                      legendPosition: "middle",
                      legendOffset: 36,
                    }}
                    axisLeft={{
                      legend: "Count",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    enableGridY={true}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="charts_employee card">
              <div className="on_leave_dashboard">
                <h1>Employee By Shift</h1>
              </div>
              <div className="employee_by_dept">
                {donut ? (
                  <Chart
                    options={{
                      chart: {
                        type: "donut",
                      },
                      labels: donut.map((item) => item.label),
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 200,
                            },
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                    }}
                    series={donut.map((item) => item.value)}
                    type="donut"
                    width="480"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* charts  */}

          {/* upcoming  */}
          <div className="upcoming mt-3">
            <div className="upcoming_birthday card">
              <div className="upcoming_heading">
                <h1>{t("Upcoming_Birthday")}</h1>
              </div>
              <div className="upcoming_datils">
                {Birthday ? (
                  Birthday.map((ele, index) => {
                    return (
                      <div key={index} className="user_birthday_details">
                        <div className="name_designation_birthday">
                          {ele.photo ? (
                            <img
                              src={ele.photo}
                              style={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "0px 5px 0px 5px",
                              }}
                              alt=""
                            />
                          ) : (
                            <FaUser
                              style={{
                                color: "var(--Whiteblack-color)",
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "0px 5px 0px 5px",
                              }}
                            />
                          )}
                          <div>
                            <h2>
                              {ele.first_name} {ele.last_name}
                            </h2>
                            <p>{ele.designation}</p>
                          </div>
                        </div>

                        <div className="date_department">
                          <h3 style={{ color: "var(--primary-color)" }}>
                            {formatDate(ele.dob)}
                          </h3>
                          <p>{ele.department_name}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={birthday} alt="" style={{ width: "170px" }} />
                  </div>
                )}
              </div>
            </div>

            <div className="upcoming_birthday card">
              <div className="upcoming_heading">
                <h1>{t("Upcoming_Join_Anniversary")}</h1>
              </div>
              <div className="upcoming_datils">
                {Anniversary ? (
                  Anniversary.map((ele, index) => {
                    return (
                      <div key={index} className="user_birthday_details">
                        <div className="name_designation_birthday">
                          {ele.photo ? (
                            <img
                              src={ele.photo}
                              style={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "0px 5px 0px 5px",
                              }}
                              alt=""
                            />
                          ) : (
                            <FaUser
                              style={{
                                color: "var(--Whiteblack-color)",
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "0px 5px 0px 5px",
                              }}
                            />
                          )}
                          <div>
                            <h1>
                              {ele.first_name} {ele.last_name}
                            </h1>
                            {/* <p>UI & UX Developer</p> */}
                          </div>
                        </div>

                        <div className="date_department">
                          <h3 style={{ color: "var(--primary-color)" }}>
                            {formatDate(ele.join_date)}
                          </h3>
                          <p>From IT Department</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={anniversary} alt="" style={{ width: "170px" }} />
                  </div>
                )}
              </div>
            </div>

            <div className="upcoming_birthday card">
              <div className="upcoming_heading">
                <h1>On Leave Today</h1>
              </div>
              <div className="upcoming_datils">
                {On_leave_today ? (
                  On_leave_today.map((ele, index) => {
                    return (
                      <div key={index} className="user_birthday_details">
                        <div className="name_designation_birthday">
                          {ele.photo ? (
                            <img
                              src={ele.photo}
                              style={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "0px 5px 0px 5px",
                              }}
                              alt=""
                            />
                          ) : (
                            <FaUser
                              style={{
                                color: "var(--Whiteblack-color)",
                                height: "35px",
                                width: "35px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                margin: "0px 5px 0px 5px",
                              }}
                            />
                          )}
                          <div>
                            <h2>
                              {ele.first_name} {ele.last_name}
                            </h2>
                            <p>{ele.designation}</p>
                          </div>
                        </div>

                        <div className="date_department">
                          <p>{ele.department_name}</p>
                          <p>{ele.branch_name}</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={leave} alt="" style={{ width: "170px" }} />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* upcoming  */}
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Dashboard;
