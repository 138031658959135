import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { SETACTiVELINK } from "../../redux/bulkbtns/Actions";
import { SETSUBMENU } from "../../redux/bulkbtns/Actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    x: "-100%",
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
  show: (i) => ({
    x: 0,
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
};

const SidebarMenu = ({ route, showAnimation, isOpen, setIsOpen ,index}) => {
  const dispatch = useDispatch();
  const toggleTab = (index) => {
    dispatch(SETACTiVELINK(index));
  };
  const togglesidebar = useSelector((state) => state.Reducer);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // console.log("toggle=",togglesidebar.VALUE_ACTIVE_LINK);
  const toggleMenu = () => {
    if(togglesidebar.SIDEMENUOPENCLOSE == true){
      setIsMenuOpen(!isMenuOpen)

      dispatch(SETSUBMENU(!togglesidebar.VALUE_SUBMENU));
    }
  };

  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu_item" >
          <div className="icon">{route.icon}</div>
          <AnimatePresence>
          {togglesidebar.SIDEMENUOPENCLOSE && (
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="link_text"
            >
              {route.name}
            </motion.div>
          )}
          </AnimatePresence>
        </div>
        {togglesidebar.SIDEMENUOPENCLOSE && (
          <motion.div>
            {isMenuOpen ? <FaAngleDown /> : <FaAngleRight />}
          </motion.div>
        )}

        {/* hover submenu  */}
        {!togglesidebar.SIDEMENUOPENCLOSE && (
          <div className="check">
            <div>
              {route.subRoutes.map((subRoute, i) => (
                <div variants={menuItemAnimation} key={i} custom={i}>
                  <NavLink to={subRoute.path} className="hover_link">
                    {/* <div className="icon1">{subRoute.icon}</div> */}
                    <div className="hover_link_text">{subRoute.name}</div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* hover submenu  */}
      </div>{" "}
      <AnimatePresence>
      {isMenuOpen && togglesidebar.SIDEMENUOPENCLOSE &&(
        <div
          variants={menuAnimation}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="menu_container"
        >
          {route.subRoutes.map((subRoute, i) => {
          // console.log("index=",i); 
          // console.log("r=",route.subRoutes[i].toggleState); 
            return(
            <div style={{ marginTop:"4px"}} variants={menuItemAnimation} key={i} custom={i}>
              <NavLink
                to={subRoute.path}
                key={index}
                className={
                  togglesidebar.VALUE_ACTIVE_LINK == route.subRoutes[i].toggleState
                    ? "linkactive "
                    : "link"
                }
                onClick={() => toggleTab(route.subRoutes[i].toggleState)}
              >
                {/* <div className="icondrop">{subRoute.icon}</div> */}
                <div style={{ paddingLeft:"20px"}} className="link_text">{subRoute.name}</div>
              </NavLink>
            </div>
          )})}
        </div>
      )}{" "}
      </AnimatePresence>
    </>
  );
};

export default SidebarMenu;
