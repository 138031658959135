import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import dot from "../../components/photos/dotimg.png";
import { useTranslation } from "react-i18next";
import Image_sec_all from "./Image_sec_all";
import logo from "../../components/photos/wizsuite_login.png";
import { useFormik } from "formik";
import * as Yup from "yup";

const Otp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom -100px right -100px",
  };

  // const initialValues = {
  //   email: "",
  // };

  // const signUpSchema = Yup.object({
  //   email: Yup.string().email("Must be a valid email").required("Enter Email"),
  // });

  // const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
  //   useFormik({
  //     initialValues,
  //     validationSchema: signUpSchema,
  //     validateOnChange: true,
  //     validateOnBlur: false,
  //     //// By disabling validation onChange and onBlur formik will validate on submit.
  //     onSubmit: (values, action) => {
  //       console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
  //     },
  //   });

  const [code, setCode] = useState("");

  const handleChange = (code) => setCode(code);

  return (
    <>
      <div className="loginbox"> 

      <Image_sec_all />

        <div style={style} className="form">
          <div className="sign_up">
            <h1>{t('Verify_OTP')}</h1>
          </div>

          <div className="signinfull mt-5">
            <div className="hello flex_row_global">
              <h2 style={{ color: "#605eb6" }}>
              {t('Enter_OTP_Verify')}
              </h2>
            </div>
            {/* <form onSubmit={handleSubmit}> */}
            <div className="check_success_sign_up">
              <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={6}
                separator={<span style={{ width: "8px" }}></span>}
                isInputNum={true}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid var(--heading-color)",
                  borderRadius: "8px",
                  width: "54px",
                  marginRight: "10px",
                  height: "54px",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />

              <div className="login mt-5">
                <button className="cusbtn">{t('Submit_OTP')}</button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
