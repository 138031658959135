import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { CgCloseR } from "react-icons/cg";
import { BiEdit } from "react-icons/bi";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import swal from "sweetalert";

const Add_Department = (props) => {
  // add Department popup start
  const department = useSelector((state) => state.Reducer);
  const signUpSchema = Yup.object({
    departmentname: Yup.string().required("Department Name is Required"),
    departmenthead: Yup.string().required("Enter Branch Head"),
    departmentemail: Yup.string().required("Enter Email").matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email address"
    ),
    // parentdepartment: Yup.string().required("Enter Branch Latitude"),
  });
const [loading,setLoading]=useState(false);
  const [initialValues, setinitialValues] = useState({
    departmentname: "",
    departmenthead: "",
    departmentemail: "",
    parentdepartment: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    // By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      add_department(values);
      action.resetForm();
    },
  });

  // put company deatils
  async function add_department(e) {
    setLoading(true)
    try {
      await axiosInstance
        .post(
          `InsertDepartment`,
          {
            department_name: e.departmentname,
            department_head: e.departmenthead,
            department_email: e.departmentemail,
            parent_department: e.parentdepartment,
            company_id: department.COMPANYAPI,
          }
        )
        .then((response) => {
          // console.log("update=", response.data);
          if (response.status == 200) {
            props.setCreate_depart_modal(false);
            props.get_all_department();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-button',
              }
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setCreate_depart_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      props.setCreate_depart_modal(false);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
    finally{
      setLoading(false);
    }
  }
  
  // put company detail
  return (
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="departmentname"
            value={values.departmentname}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Department Name*"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.departmentname && touched.departmentname ? (
              <p className="form-error">{errors.departmentname}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="departmenthead"
            value={values.departmenthead}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Department Head*"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.departmenthead && touched.departmenthead ? (
              <p className="form-error">{errors.departmenthead}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="departmentemail"
            value={values.departmentemail}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Department Email*"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.departmentemail && touched.departmentemail ? (
              <p className="form-error">{errors.departmentemail}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="parentdepartment"
            value={values.parentdepartment}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Parent Department"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.parentdepartment && touched.parentdepartment ? (
              <p className="form-error">{errors.parentdepartment}</p>
            ) : null}
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="cusbtn" disabled={loading}>
            {loading?"Submitting...":"Submit"}
          </button>
        </div>
      </form>

  );
};

const Edit_Department = (props) => {
  // add Department popup start
  const department = useSelector((state) => state.Reducer);
  const[loading,setLoading]=useState(false);
  const signUpSchema = Yup.object({
    departmentname_edit: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ),
    departmenthead_edit: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ).required("Enter Department Head"),
    departmentemail_edit: Yup.string().matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email address"
    ).required("Enter Department Email"),
    parentdepartment_edit: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ),
  });
 
  const [initialValues, setinitialValues] = useState({
    departmentname_edit: props.prop_depart_name || "",
    departmenthead_edit: props.prop_depart_head || "",
    departmentemail_edit: props.prop_depart_email || "",
    parentdepartment_edit: props.prop_parent_depart || "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      put_single_department(values);
    },
  });
  async function put_single_department(e) {
    const keyMapping = {
      departmentname_edit: 'department_name',
      departmenthead_edit: 'department_head',
      departmentemail_edit: 'department_email',
      parentdepartment_edit: 'parent_department',
      // Add more mappings as needed
    };
     const updatedValues = { company_id: department.COMPANYAPI,department_id: props.single_depart_id,};
    Object.keys(e).forEach(key => {
      const apiKey = keyMapping[key] || key; // Use mapped key if exists, otherwise use the original key
      if (e[key] !== initialValues[key]) {
        updatedValues[apiKey] = e[key];
      }
    });
    // const updatedValues = { company_id: department.COMPANYAPI,department_id: props.single_depart_id,};
    //   Object.keys(e).forEach(key => {
    //     if (e[key] !== initialValues[key]) {
    //       updatedValues[key] = e[key];
    //     }
    //   });
      // console.log("Values to be updated",updatedValues);
      setLoading(true);
    try {
      // console.log("Values to be updated",updatedValues);
      // console.log("values to be updated",e);
      await axiosInstance
        .put(
          `EditDepartment`,
            updatedValues,
         
          {
            headers: {
              Authorization: `Bearer ${department.TOKEN}`,
            },
            httpsAgent: department.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("updated values:",response.data);
          if (response.status == 200) {
            props.setedit_depart_modal(false);
            props.get_all_department();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 2000 milliseconds (2 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setedit_depart_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 2000 milliseconds (2 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      props.setedit_depart_modal(false);
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
    finally{
      setLoading(false)
    }
  }
  // put_single_department api

  return (

      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="departmentname_edit"
            value={values.departmentname_edit}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Department Name*"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.departmentname_edit && touched.departmentname_edit ? (
              <p className="form-error">{errors.departmentname_edit}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="departmenthead_edit"
            value={values.departmenthead_edit}
            onChange={handleChange}
            onBlur={handleBlur}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            className="text_input"
            id="outlined-basic"
            label="Department Head*"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.departmenthead_edit && touched.departmenthead_edit ? (
              <p className="form-error">{errors.departmenthead_edit}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="departmentemail_edit"
            value={values.departmentemail_edit}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Department Email*"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.departmentemail_edit && touched.departmentemail_edit ? (
              <p className="form-error">{errors.departmentemail_edit}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="parentdepartment_edit"
            value={values.parentdepartment_edit}
            onChange={handleChange}
            onBlur={handleBlur}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            className="text_input"
            id="outlined-basic"
            label="Parent Department"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.parentdepartment_edit && touched.parentdepartment_edit ? (
              <p className="form-error">{errors.parentdepartment_edit}</p>
            ) : null}
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            {loading?"Submitting...":"Submit"}
          </button>
        </div>
      </form>

  );
};

const Department = (props) => {
  // for Translation
  const [single_depart_id, setSingle_depart_id] = useState("");
  const department = useSelector((state) => state.Reducer);
  const { t } = useTranslation();

  // Add Create popup
  const [Create_depart_modal, setCreate_depart_modal] = useState(false);

  // Edit Create popup
  const [edit_depart_modal, setedit_depart_modal] = useState(false);
  useEffect(() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
      get_all_department();
    }
  }, [props.tabStyle]);
  // get_all_department api
  const [department_arr, setDepartment_arr] = useState("");
  const [prop_depart_name, set_prop_depart_name] = useState("");
  const [prop_depart_head, set_prop_depart_head] = useState("");
  const [prop_depart_email, set_prop_depart_email] = useState("");
  const [prop_parent_depart, set_prop_parent_depart] = useState("");

  async function get_all_department() {
    try {
      await axiosInstance
        .get(
          `AllDepartment`
        )
        .then((response) => {
          // console.log(response.data);
          setDepartment_arr(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get_all_department api

  return (
    <>
      {Create_depart_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_depart_modal(!Create_depart_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Department</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_depart_modal(!Create_depart_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Add_Department setCreate_depart_modal={setCreate_depart_modal} get_all_department={get_all_department} department_arr={department_arr} setDepartment_arr={setDepartment_arr} />
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}

      {/* Edit pop up */}
      {edit_depart_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setedit_depart_modal(!edit_depart_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Department</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setedit_depart_modal(!edit_depart_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Edit_Department
                setedit_depart_modal={setedit_depart_modal}
                single_depart_id={single_depart_id}
                prop_depart_name={prop_depart_name}
                prop_depart_head={prop_depart_head}
                prop_depart_email={prop_depart_email}
                prop_parent_depart={prop_parent_depart}
                get_all_department={get_all_department} 
                department_arr={department_arr} 
                setDepartment_arr={setDepartment_arr} 
              />
            </div>
          </div>
        </div>
      )}
      {/* Edit pop up*/}

      <div className="addbtnbra">
        <button
          className="cusbtn"
          type="button"
          onClick={() => {
            setCreate_depart_modal(!Create_depart_modal);
          }}
        >
          Add Department
        </button>
      </div>

      <div className="branchbox mt-2 mb-3">
        {department_arr
          ? department_arr.map((ele, index) => {
              return (
                <div className="card brachcard" key={index}>
                  <div className="departbox">
                    <div className="departleft">
                      <h1>{ele.department_head}</h1>
                      <h2>{ele.department_name}</h2>
                      <p>{ele.department_email}</p>
                    </div>
                    <div className="editbtndep">
                      <button
                        className="stylenonebtn"
                        onClick={() => {
                          setSingle_depart_id(ele.id);
                          setedit_depart_modal(!edit_depart_modal);
                          set_prop_depart_name(ele.department_name);
                          set_prop_depart_head(ele.department_head);
                          set_prop_depart_email(ele.department_email);
                          set_prop_parent_depart(ele.parent_department);
                        }}
                      >
                        <BiEdit color="var(--secondary-color)" />
                      </button>
                    </div>
                  </div>
                  <div className="employeebox">
                    <p>
                      <span>Number of employee:</span> {ele.employee_count}
                    </p>
                  </div>
                </div>
              );
            })
          :<p style={{marginTop:"25px", fontSize:"20px",fontWeight:"bold"}}>No Department Found</p> }
      </div>
    </>
  );
};

export default Department;
