import React, { useState } from "react";
import Navbar from "../Navbar";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BsBackspaceFill,
  BsBriefcaseFill,
  BsBuilding,
  BsFillKeyFill,
} from "react-icons/bs";
import { DiOpenshift } from "react-icons/di";
import {
  FaFileInvoice,
  FaRegThumbsUp,
  FaUmbrellaBeach,
  FaUserCheck,
  FaUserPlus,
  FaUserMinus,
} from "react-icons/fa";
import { BiGitBranch, BiTimeFive } from "react-icons/bi";
import { GiExitDoor, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import Company_Settings from "./Details_Pages/Company_Settings";
import Department from "./Details_Pages/Department";
import Shift from "./Details_Pages/Shift";
import Reporting_Manger from "./Details_Pages/Reporting_Manger";
import Branch from "./Details_Pages/Branch";
import Role_Permission from "./Details_Pages/Role_Permission";
import Approval_Settings from "./Details_Pages/Approval_Settings";
import Invoice_Settings from "./Details_Pages/Invoice_Settings";
import Salary_Settings from "./Details_Pages/Salary_Settings";
import Attendance_Settings from "./Details_Pages/Attendance_Settings";
import Leave_Settings from "./Details_Pages/Leave_Settings";
import Payroll_Rule from "./Details_Pages/Payroll_Rule";
import Leave_Type from "./Details_Pages/Leave_Type";
import Smtp from "./Details_Pages/Smtp";
import Localization from "./Details_Pages/Localization";
import Holiday from "./Details_Pages/Holiday";
import Reports from "./Details_Pages/Reports";
import { MdReport } from "react-icons/md";
import Designation from "./Details_Pages/Designation";
import { classNames } from "@react-pdf-viewer/core";

const Setting = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [toggleState, setToggleState] = useState(
    parseInt(localStorage.getItem("activeTab")) || 1
  );

  const toggleTab = (index) => {
    setToggleState(index);
    localStorage.setItem("activeTab", index);
  };

  const navigate = useNavigate();

  return (
    
      <div className="main-container">
        {/* <MainSidebar /> */}
        <div className="allcontent">
          <Navbar />
          <div className="mainbox">
            {/* breadcrumbs and pop up  */}
            {/* <div className="breadcrumbs_pop">
              <div></div>
              <button
                onClick={() => {
                  navigate("/Dashboard/Dashboard.jsx");
                }}
                className="breadcrumbbtn"
              >
                <BsBackspaceFill size="1.2rem" />
              </button>
            </div> */}
            {/* breadcrumbs and pop up  */}

            {/* details */}
            <div className="tabs_deatils">
              <div className="bloc-tabs card mt-3">
                <p style={{ color: "var(--secondary-color) !important" }}>
                  Settings
                </p>
              
                <p
                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  <BsBuilding /> Company Settings
                </p>
                <p
                  className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(6)}
                >
                  <BiGitBranch /> Branch
                </p>
                <p
                  className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(4)}
                >
                  <DiOpenshift /> Shift
                </p>
                <p
                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  <BsBriefcaseFill /> Department
                </p>
                <p
                  className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(3)}
                >
                  <BsBriefcaseFill /> Designation
                </p>
               
                <p
                  className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(5)}
                >
                  <FaUserCheck /> Roles
                </p>
              
                {/* <p
                  className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(6)}
                >
                  <BiTimeFive/> Localization
                </p> */}
                <p
                  className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(7)}
                >
                  <BsFillKeyFill /> Role & Permission
                </p>
                {/* <p
                  className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(8)}
                >
                  <FaRegThumbsUp /> Approval Settings
                </p> */}
                {/* <p
                  className={toggleState === 9 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(9)}
                >
                  <FaFileInvoice /> Invoice Settings
                </p> */}
                {/* <p
                  className={toggleState === 10 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(10)}
                >
                  <GiTakeMyMoney /> Salary Settings
                </p> */}
                <p
                  className={toggleState === 11 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(11)}
                >
                  <FaUserPlus /> Attendance Settings
                </p>
                <p
                  className={toggleState === 12 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(12)}
                >
                  <FaUserMinus /> Leave Settings
                </p>
                {/* <p
                  className={toggleState === 13 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(13)}
                >
                  <GiPayMoney /> Payroll Rule
                </p> */}
                <p
                  className={toggleState === 14 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(14)}
                >
                  <GiExitDoor /> Leave Type
                </p>
                <p
                  className={toggleState === 15 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(15)}
                >
                  <FaUmbrellaBeach /> Holidays
                </p>
                <p
                  className={toggleState === 16 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(16)}
                >
                  <MdReport />
                  Reports
                </p>
                {/* <p
                  className={toggleState === 17 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(17)}
                >
                  <MdReport />
                  SMTP
                </p> */}
              </div>

              <div className="content-tabs mt-3 card">
                <div
                  className={
                    toggleState === 1 ? "content  active-content" : "content"
                  }
                >
                 <Company_Settings tabStyle={toggleState === 1 ? "activeContent" : "content"} />

                </div>

                <div
                  className={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  <Department tabStyle={toggleState === 2 ? "activeContent" : "content"}/>
                </div>

                <div
                  className={
                    toggleState === 3 ? "content  active-content" : "content"
                  }
                >
                  <Designation tabStyle={toggleState === 3 ? "activeContent" : "content"}/>
                </div>

                <div
                  className={
                    toggleState === 4 ? "content  active-content" : "content"
                  }
                >
                  <Shift tabStyle={toggleState === 4 ? "activeContent" : "content"}/>
                </div>

                <div
                  className={
                    toggleState === 5 ? "content  active-content" : "content"
                  }
                >
                  <Reporting_Manger tabStyle={toggleState === 5 ? "activeContent" : "content"}/>
                </div>

                <div
                  className={
                    toggleState === 6 ? "content  active-content" : "content"
                  }
                >
                  <Branch tabStyle={toggleState === 6 ? "activeContent" : "content"}/>
                </div>

                {/* <div
                  className={
                    toggleState === 6 ? "content  active-content" : "content"
                  }
                >
                  <Localization/>
                </div> */}

                <div
                  className={
                    toggleState === 7 ? "content  active-content" : "content"
                  }
                >
                  <Role_Permission tabStyle={toggleState === 7 ? "activeContent" : "content"}/>
                </div>
                <div
                  className={
                    toggleState === 8 ? "content  active-content" : "content"
                  }
                >
                  <Approval_Settings />
                </div>
                <div
                  className={
                    toggleState === 9 ? "content  active-content" : "content"
                  }
                >
                  <Invoice_Settings />
                </div>
                <div
                  className={
                    toggleState === 10 ? "content  active-content" : "content"
                  }
                >
                  <Salary_Settings />
                </div>
                <div
                  className={
                    toggleState === 11 ? "content  active-content" : "content"
                  }
                >
                  <Attendance_Settings tabStyle={toggleState === 11 ? "activeContent" : "content"}/>
                </div>
                <div
                  className={
                    toggleState === 12 ? "content  active-content" : "content"
                  }
                >
                  <Leave_Settings tabStyle={toggleState === 12 ? "activeContent" : "content"}/>
                </div>
                <div
                  className={
                    toggleState === 13 ? "content  active-content" : "content"
                  }
                >
                  <Payroll_Rule />
                </div>
                <div
                  className={
                    toggleState === 14 ? "content  active-content" : "content"
                  }
                >
                  <Leave_Type tabStyle={toggleState === 14 ? "activeContent" : "content"}/>
                </div>
                <div
                  className={
                    toggleState === 15 ? "content  active-content" : "content"
                  }
                >
                  <Holiday />
                </div>
                <div
                  className={
                    toggleState === 16 ? "content  active-content" : "content"
                  }
                >
                  <Reports />
                </div>
                <div
                  className={
                    toggleState === 17 ? "content  active-content" : "content"
                  }
                >
                  <Smtp tabStyle={toggleState === 17 ? "activeContent" : "content"}/>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
   
  );
};

export default Setting;
