import React from "react";

const Salary = () => {
  return (
    <>
      <h1 className="orange_heading_color">Salary Details</h1>
      <div className="salary_details mt-1">
        <div>
          <h1>4,29.000</h1>
          <p>Annual CTC</p>
        </div>
        <div>
          <h1>29,000</h1>
          <p>Monthly Income</p>
        </div>
      </div>

      <div className="breakup_salary mt-2">
        <h1 className="orange_heading_color">Breakup of Salary</h1>
        <div className="breakup_salary_details">
          <div className="mt-3">
            <p>Basic</p>
            <p>DA(40%)</p>
            <p>HRA(50%)</p>
            <p>Conveyance</p>
            <p>Allowance</p>
            <p>Medical Allowance</p>
          </div>
          <div className="monthly_annual mt-1">
            <div>
             <h1> Monthly Amount in Rs.</h1>
             <p>16,000</p>
             <p>400</p>
             <p>200</p>
             <p>15,00</p>
             <p>500</p>
             <p>1,000</p>
            </div>
            <div>
            <h1> Annual Amount in Rs.</h1>
             <p>16,000</p>
             <p>400</p>
             <p>200</p>
             <p>15,00</p>
             <p>500</p>
             <p>1,000</p>
            </div>
          </div>
        </div>
      </div>

      <div className="breakup_salary mt-2">
        <h1 className="orange_heading_color">Reimbursements</h1>
        <div className="breakup_salary_details">
          <div className="mt-3">
            <p>Basic</p>
            <p>DA(40%)</p>
            <p>HRA(50%)</p>
            <p>Conveyance</p>
            <p>Allowance</p>
            <p>Medical Allowance</p>
          </div>
          <div className="monthly_annual mt-1">
            <div>
             <h1> Monthly Amount in Rs.</h1>
             <p>16,000</p>
             <p>400</p>
             <p>200</p>
             <p>15,00</p>
             <p>500</p>
             <p>1,000</p>
            </div>
            <div>
            <h1> Annual Amount in Rs.</h1>
             <p>16,000</p>
             <p>400</p>
             <p>200</p>
             <p>15,00</p>
             <p>500</p>
             <p>1,000</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Salary;
