import React, { useState } from "react";
import Navbar from "../Navbar";
import Attendance_request from "./Attendance_pages/Attendance_request";
import Attendance_approve from "./Attendance_pages/Attendance_approve";
import Attendance_reject from "./Attendance_pages/Attendance_reject";

const Attendance = () => {
  // radio btn
  const [selectedOption, setSelectedOption] = useState("option1"); // To store the selected option

  const handleOptionChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value); // Update the selected option when a radio button is clicked
  };
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  // radio btn

  return (
    <div className="main-container">
      {/* <MainSidebar />*/}

      <div className="allcontent">
        <Navbar />
       
        <div className="mainbox">
          <div className="card mt-3">
           <Attendance_request/>
          </div>

         
        </div>
      </div>
    </div>
  );
};

export default Attendance;
