import { Navigate } from 'react-router-dom'
const PrivateRoutes = ({children}) => {

    const isAuthenticated= !!localStorage.getItem('accessToken');
     
    return isAuthenticated ? children : <Navigate to="/login" />

}

export default PrivateRoutes
