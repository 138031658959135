import React, { useState, useRef, useCallback, useEffect } from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

const Employee_work = (props) => {
  const [editInput, setEditInput] = useState(true);
  const navigate = useNavigate();
  // console.log(props)
  // get employee by id
  const employee_work = useSelector((state) => state.Reducer);
  const get_employee_byid = useCallback(async () => {
    try {
      await axiosInstance
        .post(
          `GetEmployeeById`,
          {
            employee_id: props.id,
          },
          {
            httpsAgent: employee_work.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.data) {
            const date = new Date(response.data[0].date_exit);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`
            props.setActiveData(response.data[0].active_status)
            setinitialValues({
              work_email: response.data[0].work_email,
              Work_phone: response.data[0].work_phone,
              date_of_sep: formattedDate,
              Employee_type: response.data[0].role,
              designation: response.data[0].designation,
              department: response.data[0].department_id,
              Branch_location: response.data[0].branch_id,
              empl_reprt_man: response.data[0].reportingManager_id,
              reason_exit:response.data[0].reason_exit,
              activeStatus:response.data[0].active_status,
              join_date:response.data[0].join_date
            });
            get_all_department();
            get_all_branch();
            get_all_report();
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_employee_byid();
  }, [get_employee_byid]);
  // get employee by id

  // get_all_department api
  const [department_arr, setDepartment_arr] = useState("");
  async function get_all_department() {
    try {
      await axiosInstance
        .get(`AllDepartment`, {
          headers: {
            Authorization: `Bearer ${employee_work.TOKEN}`,
          },
          httpsAgent: employee_work.HTTP_AGENT,
        })
        .then((response) => {
          // console.log(response.data);
          setDepartment_arr(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get_all_department api

  // get all branch
  const [branch_data, setbranch_data] = useState("");
  useEffect(() => {
    get_all_branch();
  }, []);
  async function get_all_branch() {
    try {
      await axiosInstance
        .get(`AllBranches`, {
          headers: {
            Authorization: `Bearer ${employee_work.TOKEN}`,
          },
          httpsAgent: employee_work.HTTP_AGENT,
        })
        .then((response) => {
          // console.log(response.data);
          setbranch_data(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get all branch

  // get all designation
  const [designation_data, setdesignation_data] = useState("");
  useEffect(() => {
    get_all_designation();
  }, []);
  async function get_all_designation() {
    try {
      await axiosInstance.get(`Alldesignation`).then((response) => {
        // console.log("desi",response.data);
        setdesignation_data(response.data);
      });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get all designation

  // get all reporting
  const [reportManager, setReportManager] = useState("");
  useEffect(() => {
    get_all_report();
  }, []);
  async function get_all_report() {
    try {
      await axiosInstance
        .get(`AllreportingManager`, {
          headers: {
            Authorization: `Bearer ${employee_work.TOKEN}`,
          },
          httpsAgent: employee_work.HTTP_AGENT,
        })
        .then((response) => {
          // console.log(response.data);
          setReportManager(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get all reporting

  const [initialValues, setinitialValues] = useState({
    work_email: "",
    Work_phone: "",
    designation: "",
    department: "",
    Branch_location: "",
    empl_reprt_man: "",
    Employee_type: "",
    date_of_sep: "",
    reason_exit: "",
    activeStatus: "1",
    join_date:""
  });

  const signUpSchema = Yup.object({
    Work_phone: Yup.string().required("Enter phone"),
    designation: Yup.string().required("Enter designation"),
    department: Yup.string().required("Enter department"),
    Branch_location: Yup.date().required("Enter Location"),
    Employee_type: Yup.string().required("Enter employee type"),
    empl_reprt_man: Yup.string().required("Please select a manager"),
  
    activeStatus: Yup.boolean().default("1"), // Ensure this is a boolean
  
    date_of_sep: Yup.string().when("activeStatus", {
      is: "0",
      then: (schema) => schema.required("Enter date of exit"),
      otherwise: (schema) => schema.notRequired(),
    }),
    join_date: Yup.string().required("Enter date of join"),
  
    reason_exit: Yup.string().when("activeStatus", {
      is: "0",
      then: (schema) => schema.required("Enter reason for exit"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      add_work(values);
    },
  });

  // put work deatils
  async function add_work(e) {
    var dataa = new FormData();
    dataa.append("employee_id", props.id);
    dataa.append("work_phone", e.Work_phone);
    dataa.append("department_id", e.department);
    dataa.append("branch_id", e.Branch_location);
    dataa.append("reportingManager_id", e.empl_reprt_man);
    dataa.append("date_exit", e.date_of_sep);
    dataa.append("designation", e.designation);
    dataa.append("role", e.Employee_type);
    dataa.append("reason_exit", e.reason_exit);
    dataa.append("active_status", e.activeStatus);
     dataa.append("join_date", e.join_date);
    // dataa.append("work_email", e.work_email);
    try {
      await axiosInstance
        .put(`EditEmployee`, dataa, {
          headers: {
            Authorization: `Bearer ${employee_work.TOKEN}`,
          },
          httpsAgent: employee_work.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200) {
            get_employee_byid();
           
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put work deatils
  const { t } = useTranslation();
  

  return (
    <>
      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Work Information</h1>
          <p>Update Employee Work Information</p>
        </div>
        <div className="headingrightbox">
          <button
            className="active-status-btn"
            onClick={() =>values.activeStatus==="1"?setFieldValue("activeStatus", "0"):setFieldValue("activeStatus", "1")}
          >
            <span
              className={`status-dot ${values.activeStatus==="1" ? "active1" : "inactive1"}`}
            ></span>
            {values.activeStatus==="1" ? " Active" : " Inactive"}
          </button>
          <button
            className="editbutton"
            onClick={() => {
              setEditInput(!editInput);
            }}
          >
            <FaEdit />
          </button>
        </div>
      </div>
      <div className="detailsboxsection">
        <form onSubmit={handleSubmit}>
          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="work_email" className="input-label">
                Work Email <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <input
                  type="email"
                  className="nocustominputprofile"
                  readOnly={true}
                  autoComplete="off"
                  name="work_email"
                  placeholder=" Work Email"
                  value={values.work_email}
                  onChange={handleChange}
                />
                {touched.work_email && errors.work_email ? (
                  <p className="form-error">{errors.work_email}</p>
                ) : null}
              </div>
            </div>

            <div className="detailsbox mt-2">
              <label htmlFor="Work_phone" className="input-label">
                Work Phone <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                    e.target.value = e.target.value.slice(0, 10);
                  }}
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="Work_phone"
                  id="Work_phone"
                  placeholder="Enter Number"
                  value={values.Work_phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.Work_phone && errors.Work_phone ? (
                  <p className="form-error">{errors.Work_phone}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="designation" className="input-label">
                Desigantion <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <select
                  type="branch_location"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  disabled={editInput}
                  name="designation"
                  id="designation"
                  placeholder="Male"
                  value={values.designation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled value="">
                    Select Designation
                  </option>
                  {designation_data
                    ? designation_data.map((ele, index) => {
                        return (
                          <option index={index} value={ele.name}>
                            {ele.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {touched.designation && errors.designation ? (
                  <p className="form-error">{errors.designation}</p>
                ) : null}
              </div>
            </div>

            {/* department  */}
            <div className="detailsbox mt-2">
              <label htmlFor="department" className="input-label">
                Department <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <select
                  type="branch_location"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  disabled={editInput}
                  name="department"
                  id="department"
                  placeholder="Male"
                  value={values.department}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled value="">
                    Select Department
                  </option>
                  {department_arr
                    ? department_arr.map((ele, index) => {
                        return (
                          <option index={index} value={ele.id}>
                            {ele.department_name}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {touched.department && errors.department ? (
                  <p className="form-error">{errors.department}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            {/* branch  */}
            <div className="detailsbox mt-2">
              <label htmlFor="branch_location" className="input-label">
                Branch Location <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <select
                  type="Branch_location"
                  disabled={editInput}
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="Branch_location"
                  id="Branch_location"
                  placeholder="Male"
                  value={values.Branch_location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled value="">
                    Select Branch
                  </option>
                  {branch_data
                    ? branch_data.map((ele, index) => {
                        return (
                          <option index={index} value={ele.id}>
                            {ele.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {touched.Branch_location && errors.Branch_location ? (
                  <p className="form-error">{errors.Branch_location}</p>
                ) : null}
              </div>
            </div>

            <div className="detailsbox mt-2">
              <label htmlFor="Employee_type" className="input-label">
                Employee Type <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^A-Za-z\s]/g,
                      ""
                    );
                  }}
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="Employee_type"
                  id="Employee_type"
                  placeholder="Enter Employee Type"
                  value={values.Employee_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
                {touched.Employee_type && errors.Employee_type ? (
                  <p className="form-error">{errors.Employee_type}</p>
                ) : null}
              </div>
            </div>
            <div className="detailsbox mt-2">
                  <label htmlFor="join_date" className="input-label">
                   Join Date   <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inputbox">
                    <input
                      type="date"
                      className={
                        editInput
                          ? "nocustominputprofile"
                          : "custominputprofile"
                      }
                      autoComplete="off"
                      name="join_date"
                      id="join_date"
                      placeholder="Permanent Address"
                      value={values.join_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.join_date && errors.join_date ? (
                      <p className="form-error">{errors.join_date}</p>
                    ) : null}
                  </div>
                </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            {/* report manager  */}
            <div className="detailsbox mt-2">
              <label htmlFor="empl_reprt_man" className="input-label">
                Employee Reporting Manager
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="inputbox">
                <select
                  type="empl_reprt_man"
                  disabled={editInput}
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="empl_reprt_man"
                  id="empl_reprt_man"
                  placeholder="Male"
                  value={values.empl_reprt_man}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled value="">
                    Select Reporting Manager
                  </option>
                  {reportManager
                    ? reportManager.map((ele, index) => {
                        return (
                          <option index={index} value={ele.id}>
                            {ele.first_name} {ele.last_name}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {touched.empl_reprt_man && errors.empl_reprt_man ? (
                  <p className="form-error">{errors.empl_reprt_man}</p>
                ) : null}
              </div>
            </div>
            {values.activeStatus==="0" && (
              <>
                <div className="detailsbox mt-2">
                  <label htmlFor="date_of_sep" className="input-label">
                    Date of Exit   <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inputbox">
                    <input
                      type="date"
                      className={
                        editInput
                          ? "nocustominputprofile"
                          : "custominputprofile"
                      }
                      autoComplete="off"
                      name="date_of_sep"
                      id="date_of_sep"
                      placeholder="Permanent Address"
                      value={values.date_of_sep}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.date_of_sep && errors.date_of_sep ? (
                      <p className="form-error">{errors.date_of_sep}</p>
                    ) : null}
                  </div>
                </div>

                <div className="detailsbox mt-2">
                  <label htmlFor="reason_exit" className="input-label">
                    Reason of Exit   <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inputbox">
                    <input
                      type="text"
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                      className={
                        editInput
                          ? "nocustominputprofile"
                          : "custominputprofile"
                      }
                      readOnly={editInput}
                      autoComplete="off"
                      name="reason_exit"
                      id="reason_exit"
                      placeholder="Enter reason of exit"
                      value={values.reason_exit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={editInput} // Corrected disabled logic
                    />
                    {touched.reason_exit && errors.reason_exit ? (
                      <p className="form-error">{errors.reason_exit}</p>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <button type="submit" className="cusbtn">
              Update Work details
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Employee_work;
