import React from 'react'

const Approval_Settings = () => {
  return (
    <div>
      Approval Settings
    </div>
  )
}

export default Approval_Settings
