import React, { useEffect, useState } from "react";
import gear from "../components/photos/gear.png"
import { he } from "date-fns/locale";
const formatThousandsNoRounding = (n, dp) => {
  let e = "",
    s = e + n,
    l = s.length,
    b = n < 0 ? 1 : 0,
    i = s.lastIndexOf(","),
    j = i === -1 ? l : i,
    r = e,
    d = s.substr(j + 1, dp);
  while ((j -= 3) > b) {
    r = "." + s.substr(j, 3) + r;
  }
  return (
    s.substr(0, j + 3) +
    r +
    (dp
      ? "," + d + (d.length < dp ? "00000".substr(0, dp - d.length) : e)
      : e)
  );
};

const ComingSoon = () => {
  const [count, setCount] = useState(0);
  
  useEffect(() => {
    let start = 0;
    const end = 404;
    const duration = 2000;
    const stepTime = duration / end;

    const counter = setInterval(() => {
      start += 5;
      if (start >= end) {
        start = end;
        clearInterval(counter);
      }
      setCount(start);
    }, stepTime);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div  style={styles.innerContainer}>
          <div id="countUp" style={styles.countUp}>
            <div ><img style={styles.gearstyle} src={gear}  alt="" /></div>
            <div style={styles.text}>Page not found</div>
            <div style={styles.text}>Site Under maintenance</div>
            {/* <div style={styles.text}>I'm probably working on something that has blown up.</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/257418/andy-holmes-698828-unsplash.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      fontFamily: "Roboto Mono, Liberation Mono, Consolas, monospace",
      color: "rgba(255,255,255,.87)"
    },
    row: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    innerContainer: {
      textAlign: "center"
    },
    countUp: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    },
    number: {
      fontSize: "4rem",
      fontWeight: "500",
      marginBottom: "1rem"
    },
    text: {
      fontWeight: "300",
      textAlign: "center"
    },
    "@keyframes rotate": {
        "0%": { transform: "rotate(0deg)" },
        "50%": { transform: "rotate(180deg)" },
        "100%": { transform: "rotate(360deg)" }
      },
    gearstyle: {
      animation: "rotate 2s infinite linear",
      height:"155px",
      width:"155px"
    },
   
  };
  

export default ComingSoon;