import React, { useState } from "react";
import Navbar from "../../Navbar";
import Regularization_request from "./Regularization_request";
import Regularization_approve from "./Regularization_approve";
import Regularization_reject from "./Regularization_reject";

const Regularization = () => {
  const [loading,setLoading]=useState(false);
  // radio btn
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedValue = localStorage.getItem("regularization_toggle");
    return storedValue ? storedValue : "option1";
  }); // To store the selected option

  const handleOptionChange = (event) => {
    // console.log(event.target.value);
    localStorage.setItem("regularization_toggle", event.target.value);
    setSelectedOption(event.target.value);
  };
  const [toggleState, setToggleState] = useState(() => {
    const storedValue = localStorage.getItem("regularization");
    return storedValue ? parseInt(storedValue) : 1;
  });

  const toggleTab = (index) => {
    localStorage.setItem("regularization", index);
    setToggleState(index);
  };
  // radio btn

  return (
    <div className="main-container">
      {/* <MainSidebar />*/}
      <div className="allcontent">
        <Navbar />

        <div className="mainbox">
          <div className="attendace_radio  mt-1">
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(1)}
                />
                <span>Requests</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(2)}
                />
                <span>Approved</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option3"
                  checked={selectedOption === "option3"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(3)}
                />
                <span>Rejected</span>
              </label>
            </div>
          </div>

          <div className="mt-3">
            <div
              className={
                toggleState === 1 ? "content-regularization  active-content" : "content-regularization"
              }
            >
              <Regularization_request />
            </div>

            <div
              className={
                toggleState === 2 ? "content  active-content card" : "content"
              }
            >
              <Regularization_approve />
            </div>

            <div
              className={
                toggleState === 3 ? "content  active-content card" : "content"
              }
            >
              <Regularization_reject />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regularization;
