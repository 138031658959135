import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CgCloseR } from "react-icons/cg";
import { FaUserTie } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import swal from "sweetalert";
import axiosInstance from "../../../utils/AxiosInstance.jsx";

// popup for add reporting manager
const Add_Reporting_manger = (props) => {
  const [loading,setLoading]=useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //state for storing name of selected reporting manager
  const [employeesName, setEmployeesName] = useState();

  //state fr storing the names of selected Employees WRM
  const [employeesNameWRM, setEmployeesNameWRM] = useState([]);

  //state fr storing the IDs of selected Employees WRM
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);

  //to store the selected Reporting manager ID
  let RMId = "";

  const addreportingmanager = useSelector((state) => state.Reducer);
  // add Reporting Manger schems start
  const signUpSchema = Yup.object({
    managername: Yup.string().required("Select a value"),
    employeeList: Yup.array().of(Yup.string()).required("Enter Employee Name"),
  });
  //setting up iniitial values
  const [initialValues, setinitialValues] = useState({
    managername: "",
    employeeList: [],
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      const e = {
        reportingManager: values.managername,
        employeename: selectedEmployeeIds,
      };
      //function to adding reporting manager
      addreportingmanagerAPI(e);
      // console.log(values);
      action.resetForm();
    },
  });

  // API for getting names of employee this function will be used in RM list and employee list both
  async function getEmployeeName() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`, {
          headers: {
            Authorization: `Bearer ${addreportingmanager.TOKEN}`,
          },
          httpsAgent: addreportingmanager.HTTP_AGENT,
        })
        .then((response) => {
          console.log(response.data);
          const filterdata = response.data.filter(
            (employee) => employee.role != "RM"
          );
          setEmployeesName(filterdata);
          setEmployeesNameWRM(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  // API for adding reporting manager
  async function addreportingmanagerAPI(e) {
    setLoading(true)
    try {
      await axiosInstance
        .post(
          `AddreportingManager`,
          {
            reportingManagerId: e.reportingManager,
            employeeId: e.employeename,
          },
          {
            headers: {
              Authorization: `Bearer ${addreportingmanager.TOKEN}`,
            },
            httpsAgent: addreportingmanager.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            props.getreportingmangerAPI();
            props.getAccountmanagerAPI();
            props.getHRmanagerAPI();
            props.setCreate_reporting_modal(false);
            // props.setCreate_event_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            // setRenderKey(2);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
    finally{
      setLoading(false)
    }
  }

  //calling API for getting Manager name to show in dropdown
  useEffect(() => {
    getEmployeeName();
  }, []);

  //calling API for getting employee name to show in dropdown after when manager name will get selected
  useEffect(() => {
    if (values.managername) {
      getEmployeeName();
    }
  }, [values.managername]);

  // to handle the multi select checkbox and to store ID of the selected employees
  const handleEmployeeChange = (event) => {
    const { value: selectedNames } = event.target;
    const selectedIds = selectedNames.map(
      (selectedName) =>
        employeesNameWRM.find(
          (employee) =>
            `${employee.first_name} ${employee.last_name}` === selectedName
        )?.id
    );
    setSelectedEmployeeIds(selectedIds);
    setFieldValue("employeeList", selectedNames);
    // console.log("Selected ids are:", selectedIds)
  };

  //to store the the ID of the selected reporting mananger
  const [newEmployees, setNewEmployees] = useState();
  const handleSelectedRM = (e) => {
    setFieldValue("managername", e.target.value);
    RMId = e.target.value;
    // console.log("The id of the RM is:", RMId)
    const arr = employeesNameWRM
      ? employeesNameWRM.filter((employee) => employee.id != RMId)
      : "";
    setNewEmployees(arr);
    //  console.log("new employees are:",newEmployees);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          select
          name="managername"
          value={values.managername}
          onChange={handleSelectedRM}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Select Manager"
          variant="outlined"
        >
          {employeesName
            ? employeesName.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.first_name} {employee.last_name}
                </MenuItem>
              ))
            : ""}
        </TextField>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.managername && touched.managername ? (
            <p className="form-error">{errors.managername}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2">
        <FormControl sx={{ width: 425 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Select Employees
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={values.employeeList}
            onChange={(e) => {
              handleEmployeeChange(e);
            }}
            MenuProps={MenuProps}
            input={<OutlinedInput label="Select Employees" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {newEmployees
              ? newEmployees.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    value={`${employee.first_name} ${employee.last_name}`}
                  >
                    <Checkbox
                      checked={selectedEmployeeIds.includes(employee.id)}
                    />
                    <ListItemText
                      primary={`${employee.first_name} ${employee.last_name}`}
                    />
                  </MenuItem>
                ))
              : "Please select Manager First"}
          </Select>
        </FormControl>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.employeeList && touched.employeeList ? (
            <p className="form-error">{errors.employeeList}</p>
          ) : null}
        </div>
      </div>
      <div className="text-center mt-1 mb-3">
        <button type="submit" className="cusbtn" disabled={loading}>
          {loading?"Adding...":"Add"}
        </button>
      </div>
    </form>
  );
};

// popup to add HR manager
const Add_HR_manager = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const addreportingmanager = useSelector((state) => state.Reducer);
  // add Reporting Manger schems start
  const signUpSchema = Yup.object({
    HRmanagername: Yup.string().required("Select a value"),
  });
  //setting up iniitial values
  const [initialValues, setinitialValues] = useState({
    HRmanagername: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      //function to adding reporting manager
      console.log(values);
      addRoleHR();
      action.resetForm();
    },
  });

  //for storing the employee name for showing them into the dropdown
  const [employeenameforDropdown, setemployeenameforDropdown] = useState();

  //API for changing role to HR
  async function addRoleHR() {
    try {
      await axiosInstance
        .post(`ChangeRole`, {
          employee_id: IDforHR,
          Role: "HR",
        })
        .then((response) => {
          if (response.status == 200) {
            props.getHRmanagerAPI();
            props.getAccountmanagerAPI();
            props.getreportingmangerAPI();
            props.setCreate_HR_modal(false);
            console.log("HR added successfully");
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            //getting data to update right after the submit button
            getEmployeeName();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log("hey: ", e);
      swal({
        title: "Failure!",
        text: e.response.data.error || "Internal Server Error",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // console.log(e.response.data.error);
    }
  }

  //API for getting names of employees for dropdown
  async function getEmployeeName() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`)
        .then((response) => {
          if (response.status == 200) {
            const filterdata = response.data.filter(
              (employee) => employee.role != "HR"
            );
            setemployeenameforDropdown(filterdata);
          } else {
            console.log("No data found");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  //calling API for getting names of employees for dropdown
  useEffect(() => {
    getEmployeeName();
  }, []);

  //function for storing ID of Selected HR
  const [IDforHR, setIDforHR] = useState(""); // Using useState to manage state
  function storeID(e) {
    setFieldValue("HRmanagername", e.target.value);
    setIDforHR(e.target.value);
  }
  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          select
          name="HRmanagername"
          value={values.HRmanagername}
          onChange={(e) => {
            storeID(e);
          }}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Select HR Manager"
          variant="outlined"
        >
          {employeenameforDropdown
            ? employeenameforDropdown.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.first_name} {employee.last_name}
                </MenuItem>
              ))
            : ""}
        </TextField>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.HRmanagername && touched.HRmanagername ? (
            <p className="form-error">{errors.HRmanagername}</p>
          ) : null}
        </div>
      </div>
      <div className="text-center mt-1 mb-3">
        <button type="submit" className="cusbtn">
          Add
        </button>
      </div>
    </form>
  );
};

// popup to add Account manager
const Add_Account_manager = (props) => {
  const [state, setState] = useState(0);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const addreportingmanager = useSelector((state) => state.Reducer);
  // add Reporting Manger schems start
  const signUpSchema = Yup.object({
    Accountmanagername: Yup.string().required("Select a value"),
  });
  //setting up iniitial values
  const [initialValues, setinitialValues] = useState({
    Accountmanagername: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      //function to adding reporting manager
      console.log(values);
      addRoleAccount();
      action.resetForm();
    },
  });

  //for storing the employee name for showing them into the dropdown
  const [employeenameforDropdown, setemployeenameforDropdown] = useState();

  //API for changing role to HR
  async function addRoleAccount() {
    try {
      await axiosInstance
        .post(`ChangeRole`, {
          employee_id: IDforHR,
          Role: "ACC",
        })
        .then((response) => {
          if (response.status == 200) {
            props.getAccountmanagerAPI();
            props.getHRmanagerAPI();
            props.getreportingmangerAPI();
            props.setCreate_accounts_modal(false);
            console.log("Account Manager added successfully");
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            console.log(response.data);

            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      swal({
        title: "Failure!",
        text: e.response,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
    }
  }

  //API for getting names of employees for dropdown
  async function getEmployeeName() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`)
        .then((response) => {
          if (response.status == 200) {
            const AccEmployees = response.data.filter(
              (employee) => employee.role != "ACC"
            );
            setemployeenameforDropdown(AccEmployees);
          } else {
            console.log("No data found");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  //calling API for getting names of employees for dropdown
  useEffect(() => {
    getEmployeeName();
  }, []);

  //function for storing ID of Selected HR
  const [IDforHR, setIDforHR] = useState(""); // Using useState to manage state
  function storeID(e) {
    setFieldValue("Accountmanagername", e.target.value);
    setIDforHR(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          select
          name="Accountmanagername"
          value={values.Accountmanagername}
          onChange={(e) => {
            storeID(e);
          }}
          onBlur={handleBlur}
          className="text_input"
          id="outlined-basic"
          label="Select Account Manager"
          variant="outlined"
        >
          {employeenameforDropdown
            ? employeenameforDropdown.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.first_name} {employee.last_name}
                </MenuItem>
              ))
            : ""}
        </TextField>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.Accountmanagername && touched.Accountmanagername ? (
            <p className="form-error">{errors.Accountmanagername}</p>
          ) : null}
        </div>
      </div>
      <div className="text-center mt-1 mb-3">
        <button
          type="submit"
          className="cusbtn"
          onClick={() => setState(state === 1 ? 0 : 1)}
        >
          Add
        </button>
      </div>
    </form>
  );
};

// Edit Reporting Manager Employees popup start
const Edit_Reporting_manger = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // State for storing the names of selected Employees WRM
  const [employeesNameWRM, setEmployeesNameWRM] = useState([]);

  // State for storing the IDs of selected Employees WRM
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);

  // To store the selected Reporting manager ID
  let RMId = "";

  const addreportingmanager = useSelector((state) => state.Reducer);

  const signUpSchema = Yup.object({
    managername_edit: Yup.string().required("Select a value"),
    employeeList_edit: Yup.array()
      .of(Yup.string())
      .required("Enter Employee Name"),
  });

  const [initialValues, setinitialValues] = useState({
    managername_edit: "",
    employeeList_edit: [],
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      editEmployee();
      action.resetForm();
    },
  });

  // API for editing employee information through id
  async function editEmployee() {
    try {
      await axiosInstance
        .post(
          `AddreportingManager`,
          {
            reportingManagerId: props.editEmployeeID,
            employeeId: selectedEmployeeIds,
          },
          {
            headers: {
              Authorization: `Bearer ${addreportingmanager.TOKEN}`,
            },
            httpsAgent: addreportingmanager.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            props.getreportingmangerAPI();
            props.setedit_reporting_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response.error);
    }
  }

  // API for getting employee information through id
  async function getEmployeeById() {
    try {
      await axiosInstance
        .post(`reportingManagerById`, {
          reportingManager_id: props.editEmployeeID,
        })
        .then((response) => {
          if (response.status === 200) {
            const fullname = `${response.data.reportingManager[0].first_name} ${response.data.reportingManager[0].last_name}`;
            getRMdetail(fullname);
          } else {
            console.log("No data found");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  // API for getting employee names for drop down
  async function getAllEmployee() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`)
        .then((response) => {
          if (response.status === 200) {
            setEmployeesNameWRM(response.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  // Calling API for getting Manager name to via ID
  useEffect(() => {
    getEmployeeById();
    getAllEmployee();
  }, []);

  // API for getting the values and depicting them into fields
  async function getRMdetail(fullname) {
    try {
      const response = await axiosInstance.post(
        `reportingManagerById`,
        {
          reportingManager_id: props.editEmployeeID,
        },
        {
          headers: {
            Authorization: `Bearer ${addreportingmanager.TOKEN}`,
          },
          httpsAgent: addreportingmanager.HTTP_AGENT,
        }
      );

      const { employeeDetail } = response.data;
      const employeeIds = employeeDetail.map((employee) => employee.id);
      setinitialValues({
        employeeList_edit: employeeIds,
        managername_edit: fullname,
      });
    } catch (e) {
      console.log(e.response.error);
    }
  }

  // To handle the multi-select checkbox and to store ID of the selected employees
  const handleEmployeeChange = (event) => {
    const { value: selectedIds } = event.target;
    setSelectedEmployeeIds(selectedIds);
    setFieldValue("employeeList_edit", selectedIds);
    console.log("Selected ids are:", selectedIds);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          type="text"
          name="managername_edit"
          className="text_input"
          id="outlined-basic"
          value={values.managername_edit}
          label="Manager Name"
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          disabled
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.managername_edit && touched.managername_edit ? (
            <p className="form-error">{errors.managername_edit}</p>
          ) : null}
        </div>
      </div>
      <div className="input-container mt-2">
        <FormControl sx={{ width: 425 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Select Employees
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={values.employeeList_edit || []} // Assuming values.employeeList_edit is an array
            onChange={handleEmployeeChange}
            input={<OutlinedInput label="Select Employees" />}
            MenuProps={MenuProps}
            renderValue={(selected) =>
              employeesNameWRM
                .filter((employee) => selected.includes(employee.id))
                .map(
                  (employee) => `${employee.first_name} ${employee.last_name}`
                )
                .join(", ")
            }
          >
            {employeesNameWRM
              .filter((employee) => `${employee.id}` !== props.editEmployeeID)
              .map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  <Checkbox
                    checked={values.employeeList_edit.includes(employee.id)}
                  />
                  <ListItemText
                    primary={`${employee.first_name} ${employee.last_name}`}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.employeeList_edit && touched.employeeList_edit ? (
            <p className="form-error">{errors.employeeList_edit}</p>
          ) : null}
        </div>
      </div>
      <div className="text-center mt-1 mb-3">
        <button type="submit" className="cusbtn">
          Edit
        </button>
      </div>
    </form>
  );
};

// Edit Reporting Manager Role popup start
const Edit_Reporting_manger_Role = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //state fr storing the names of selected Employees WRM
  const [allEmployees, setEmployeesName] = useState();
  const addreportingmanager = useSelector((state) => state.Reducer);

  const signUpSchema = Yup.object({
    managerrole_edit: Yup.string().required("Select a value"),
  });

  const [initialValues, setinitialValues] = useState({
    managerrole_edit: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      editMangerRole();
      action.resetForm();
    },
  });

  async function editMangerRole() {
    await axiosInstance
      .post(
        `ChangeRole`,
        {
          employee_id: props.editEmployeeID,
          Role: values.managerrole_edit,
        },
        {
          headers: {
            Authorization: `Bearer ${addreportingmanager.TOKEN}`,
          },
          httpsAgent: addreportingmanager.HTTP_AGENT,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          props.getAccountmanagerAPI();
          props.getHRmanagerAPI();
          props.getreportingmangerAPI();

          props.setEdit_role(false);
          const successMessage =
            response.data.message || "Something went wrong";
          swal({
            title: "Success!",
            text: successMessage,
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                className: "swal-button--custom",
              },
            },
          });
          // Close the SweetAlert after 3000 milliseconds (3 seconds)
          setTimeout(() => {
            swal.close();
          }, 2000);
        } else {
          const errorMessage = response.data.message || "Something went wrong";
          swal({
            title: "Failure!",
            text: errorMessage,
            icon: "error",
            buttons: {
              confirm: {
                text: "OK",
                className: "swal-button--custom",
              },
            },
          });

          // Close the SweetAlert after 3000 milliseconds (3 seconds)
          setTimeout(() => {
            swal.close();
          }, 2000);
        }
      });
  }
  console.log("props.dropdown value", props.dropdownvalue);
  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        {props.dropdownvalue === "RM" && (
          <TextField
            select
            name="managerrole_edit"
            value={values.managerrole_edit}
            onChange={(e) => {
              setFieldValue("managerrole_edit", e.target.value);
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Change Role"
            variant="outlined"
          >
            <MenuItem value="ACC">Account Manager</MenuItem>
            <MenuItem value="Employee">Employee</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
          </TextField>
        )}
        {props.dropdownvalue === "HR" && (
          <TextField
            select
            name="managerrole_edit"
            value={values.managerrole_edit}
            onChange={(e) => {
              setFieldValue("managerrole_edit", e.target.value);
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Change Role"
            variant="outlined"
          >
            <MenuItem value="ACC">Account Manager</MenuItem>
            <MenuItem value="Employee">Employee</MenuItem>
          </TextField>
        )}
        {props.dropdownvalue === "Accounts" && (
          <TextField
            select
            name="managerrole_edit"
            value={values.managerrole_edit}
            onChange={(e) => {
              setFieldValue("managerrole_edit", e.target.value);
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Change Role"
            variant="outlined"
          >
            <MenuItem value="Employee">Employee</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
          </TextField>
        )}
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.managerrole_edit && touched.managerrole_edit ? (
            <p className="form-error">{errors.managerrole_edit}</p>
          ) : null}
        </div>
      </div>
      <div className="text-center mt-1 mb-3">
        <button type="submit" className="cusbtn">
          Edit
        </button>
      </div>
    </form>
  );
};

// Initial page starts
const Reporting_Manger = (props) => {
  const addreportingmanager = useSelector((state) => state.Reducer);

  // for Translation
  const { t } = useTranslation();

  // Add Create popup for RM
  const [Create_reporting_modal, setCreate_reporting_modal] = useState(false);

  // Add Create popup for HR
  const [Create_HR_modal, setCreate_HR_modal] = useState(false);

  // Add Create popup for Accounts
  const [Create_accounts_modal, setCreate_accounts_modal] = useState(false);

  // Edit popup for employees in RM
  const [Edit_reporting_modal, setedit_reporting_modal] = useState(false);

  // Edit popup for editing role
  const [Edit_role, setEdit_role] = useState(false);

  //for storing the data from API we for cards
  const [reportingManager, getreportingmanger] = useState();
  const [HRManager, setHRManager] = useState();
  const [accountManager, setAccountManager] = useState();

  // Reporting managers
  useEffect(() => {
    if (props.tabStyle === "activeContent") {
      // console.log("the api will be called");
      getreportingmangerAPI();
      getHRmanagerAPI();
      getAccountmanagerAPI();
    }
  }, [props.tabStyle]);

  // API for getting names of reporting managers
  async function getreportingmangerAPI() {
    try {
      await axiosInstance
        .get(`AllreportingManager`, {
          headers: {
            Authorization: `Bearer ${addreportingmanager.TOKEN}`,
          },
          httpsAgent: addreportingmanager.HTTP_AGENT,
        })
        .then((response) => {
          console.log("reporting manager data is:", response.data);
          getreportingmanger(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  // API for getting names of HR managers
  async function getHRmanagerAPI() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`, {
          headers: {
            Authorization: `Bearer ${addreportingmanager.TOKEN}`,
          },
          httpsAgent: addreportingmanager.HTTP_AGENT,
        })
        .then((response) => {
          const hrEmployees = response.data.filter(
            (employee) => employee.role === "HR"
          );
          setHRManager(hrEmployees);
          // console.log(getHRmanagerAPI);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  // API for getting names of Accounts Manager
  async function getAccountmanagerAPI() {
    try {
      await axiosInstance
        .get(`GetAllEmployee`, {
          headers: {
            Authorization: `Bearer ${addreportingmanager.TOKEN}`,
          },
          httpsAgent: addreportingmanager.HTTP_AGENT,
        })
        .then((response) => {
          const AccEmployees = response.data.filter(
            (employee) => employee.role === "ACC"
          );
          setAccountManager(AccEmployees);
          console.log(accountManager);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  //for setting value of dropdown into a const
  const [dropdownvalue, setrDopdownvalue] = useState("RM");
  const select_dropdown = (e) => {
    setrDopdownvalue(e.target.value);
  };
  // Reporting managers
  const [editEmployeeID, setEditEmployeeID] = useState();
  return (
    <>
      {/* Add pop up for adding RM*/}
      {Create_reporting_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_reporting_modal(!Create_reporting_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Reporting Manager</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_reporting_modal(!Create_reporting_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Add_Reporting_manger
                setCreate_reporting_modal={setCreate_reporting_modal}
                getreportingmangerAPI={getreportingmangerAPI}
                getAccountmanagerAPI={getAccountmanagerAPI}
                getHRmanagerAPI={getHRmanagerAPI}
                props={props.tabStyle}
              />
            </div>
          </div>
        </div>
      )}

      {/* Add pop up for adding HR*/}
      {Create_HR_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_HR_modal(!Create_HR_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add HR Manager</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_HR_modal(!Create_HR_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Add_HR_manager
                setCreate_HR_modal={setCreate_HR_modal}
                getHRmanagerAPI={getHRmanagerAPI}
                getreportingmangerAPI={getreportingmangerAPI}
                getAccountmanagerAPI={getAccountmanagerAPI}
              />
            </div>
          </div>
        </div>
      )}

      {/* Add pop up for adding Account*/}
      {Create_accounts_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_accounts_modal(!Create_accounts_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add HR Manager</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_accounts_modal(!Create_accounts_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Add_Account_manager
                setCreate_accounts_modal={setCreate_accounts_modal}
                getAccountmanagerAPI={getAccountmanagerAPI}
                getreportingmangerAPI={getreportingmangerAPI}
                getHRmanagerAPI={getHRmanagerAPI}
              />
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}

      {/* Edit pop up for editing employees of RM*/}
      {Edit_reporting_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setedit_reporting_modal(!Edit_reporting_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Reporting Manager Employees</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setedit_reporting_modal(!Edit_reporting_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Edit_Reporting_manger
                editEmployeeID={editEmployeeID}
                setedit_reporting_modal={setedit_reporting_modal}
                getreportingmangerAPI={getreportingmangerAPI}
              />
            </div>
          </div>
        </div>
      )}

      {/* Edit pop up for editing role of RM*/}
      {Edit_role && (
        <div className="modal">
          <div
            onClick={() => {
              setEdit_role(!Edit_role);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Reporting Manager</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEdit_role(!Edit_role);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Edit_Reporting_manger_Role
                editEmployeeID={editEmployeeID}
                setEdit_role={setEdit_role}
                dropdownvalue={dropdownvalue}
                getreportingmangerAPI={getreportingmangerAPI}
                getAccountmanagerAPI={getAccountmanagerAPI}
                getHRmanagerAPI={getHRmanagerAPI}
              />
            </div>
          </div>
        </div>
      )}

      {/* Edit pop up for RM*/}
      <div className="leftupperpartbtn">
        <button
          className="cusbtn cusbtnres"
          type="button"
          onClick={() => {
            {
              //for Add RM popup
              dropdownvalue === "RM" &&
                setCreate_reporting_modal(!Create_reporting_modal);
            }
            {
              //for Add HR popup
              dropdownvalue === "HR" && setCreate_HR_modal(!Create_HR_modal);
            }
            {
              //for add accounts popup
              dropdownvalue === "Accounts" &&
                setCreate_accounts_modal(!Create_accounts_modal);
            }
          }}
        >
          {" "}
          {dropdownvalue === "RM" && "Add Reporting Manager"}
          {dropdownvalue === "HR" && "Add HR Manager"}
          {dropdownvalue === "Accounts" && "Add Accounts Manager"}
        </button>
        {/* Drop down for selecting roles  */}
        <select
          name="holidayView"
          id="holidayView"
          className="dropdownsty dropdownstyres"
          style={{ marginRight: "3%" }}
          onChange={(e) => {
            select_dropdown(e);
          }}
        >
          <option className="bgg" value="RM">
            Reporting Manager
          </option>
          <option className="bgg" value="HR">
            HR Manager
          </option>
          <option className="bgg" value="Accounts">
            Accounts Manager
          </option>
        </select>
      </div>

      {/* Cards for RM */}
      {dropdownvalue === "RM" && (
        <div className="branchbox mt-2 mb-3">
          {reportingManager ? (
            reportingManager.map((ele, index) => {
              return (
                <div className="card brachcard reporting_manager">
                  <div className="mangerbox">
                    <div className="mangerboxicon">
                      <FaUserTie />
                    </div>
                    <div className="mangerboxright">
                      {/* <button
                    className="stylenonebtn"
                    onClick={() => {
                      //sending id of employee
                      setEditEmployeeID(ele.id);
                      setedit_reporting_modal(!Edit_reporting_modal);
                    }}>
                    <BiEdit color="var(--secondary-color)" />
                  </button> */}
                      <h1>
                        {ele.first_name} {ele.last_name}{" "}
                        <button
                          className="stylenonebtn"
                          onClick={() => {
                            //sending id of employee
                            setEditEmployeeID(ele.id);
                            setEdit_role(!Edit_role);
                          }}
                        >
                          <BiEdit color="var(--secondary-color)" />
                        </button>
                      </h1>
                      <p>{ele.work_email}</p>
                      <p>{ele.work_phone}</p>
                    </div>
                  </div>
                  <div className="mangerdepart">
                    <div className="mangerarea">
                      <p style={{ fontWeight: "bold" }}>Department</p>
                      <p>{ele.designation}</p>
                    </div>
                    <div className="mangerarea">
                      <p style={{ fontWeight: "bold" }}>
                        No of Employee: {ele.No_of_Employee}{" "}
                        <button
                          className="stylenonebtn"
                          onClick={() => {
                            //sending id of employee
                            setEditEmployeeID(ele.id);
                            setedit_reporting_modal(!Edit_reporting_modal);
                          }}
                        >
                          <BiEdit color="var(--secondary-color)" />
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p
              style={{
                marginTop: "25px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              No Reporting Manager Found
            </p>
          )}
        </div>
      )}

      {/* Cards for HR */}
      {dropdownvalue === "HR" && (
        <div className="branchbox mt-2 mb-3">
          {HRManager
            ? HRManager.map((ele, index) => {
                return (
                  <div className="card brachcard">
                    <div className="mangerbox">
                      <div className="mangerboxicon">
                        <FaUserTie />
                      </div>
                      <div className="mangerboxright">
                        {/* to edit role  */}
                        <button
                          className="stylenonebtn"
                          onClick={() => {
                            //sending id of employee
                            setEditEmployeeID(ele.id);
                            setEdit_role(!Edit_role);
                          }}
                        >
                          <BiEdit color="var(--secondary-color)" />
                        </button>
                        <h1>
                          {ele.first_name} {ele.last_name}
                        </h1>
                        <p>{ele.work_email}</p>
                        <p>{ele.work_phone}</p>
                      </div>
                    </div>
                    <div className="mangerdepart"></div>
                  </div>
                );
              })
            : ""}
        </div>
      )}
      {/* Cards for Accounts */}
      {dropdownvalue === "Accounts" && (
        <div className="branchbox mt-2 mb-3">
          {accountManager
            ? accountManager.map((ele, index) => {
                return (
                  <div className="card brachcard">
                    <div className="mangerbox">
                      <div className="mangerboxicon">
                        <FaUserTie />
                      </div>
                      <div className="mangerboxright">
                        <button
                          className="stylenonebtn"
                          onClick={() => {
                            //sending id of employee
                            setEditEmployeeID(ele.id);
                            setEdit_role(!Edit_role);
                            dropdownvalue = { dropdownvalue };
                          }}
                        >
                          <BiEdit color="var(--secondary-color)" />
                        </button>
                        <h1>
                          {ele.first_name} {ele.last_name}
                        </h1>
                        <p>{ele.work_email}</p>
                        <p>{ele.work_phone}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      )}

      {/* New Design */}

      {/* List view */}

      {/* <div className="listviewbox">
        <div className="listitem_content_box">
          <div className="listmagerbox">
            <div className="listmangerbox_icon">
              <FaUserTie />
            </div>
            <div className="listmanger_content">
              <h3>Prateek Punj</h3>
              <p>prateek@vmstech.com</p>
            </div>
          </div>
          <p>9898989898</p>
          <span className="listitemmagerdept">Department</span>
          <div className="listitememployee">          
            <p>17 Employee</p>
            <button
              className="stylenonebtn"
              style={{marginTop: "10px"}}
            // onClick={() => {
            //   //sending id of employee
            //   setEditEmployeeID(ele.id);
            //   setEdit_role(!Edit_role);
            //   dropdownvalue = { dropdownvalue }
            // }}
            >
              <BiEdit color="var(--secondary-color)" />
            </button>
          </div>
          <div className="listitemactions">
            <button
              className="stylenonebtn"
              style={{marginTop: "10px"}}
            // onClick={() => {
            //   //sending id of employee
            //   setEditEmployeeID(ele.id);
            //   setEdit_role(!Edit_role);
            //   dropdownvalue = { dropdownvalue }
            // }}
            >
              <BiEdit color="var(--secondary-color)" />
            </button>
          </div>
        </div>
        <div className="listitem_content_box">
          <div className="listmagerbox">
            <div className="listmangerbox_icon">
              <FaUserTie />
            </div>
            <div className="listmanger_content">
              <h3>Prateek Punj</h3>
              <p>prateek@vmstech.com</p>
            </div>
          </div>
          <p>9898989898</p>
          <span className="listitemmagerdept">Department</span>
          <div className="listitememployee">          
            <p>17 Employee</p>
            <button
              className="stylenonebtn"
              style={{marginTop: "10px"}}
            // onClick={() => {
            //   //sending id of employee
            //   setEditEmployeeID(ele.id);
            //   setEdit_role(!Edit_role);
            //   dropdownvalue = { dropdownvalue }
            // }}
            >
              <BiEdit color="var(--secondary-color)" />
            </button>
          </div>
          <div className="listitemactions">
            <button
              className="stylenonebtn"
              style={{marginTop: "10px"}}
            // onClick={() => {
            //   //sending id of employee
            //   setEditEmployeeID(ele.id);
            //   setEdit_role(!Edit_role);
            //   dropdownvalue = { dropdownvalue }
            // }}
            >
              <BiEdit color="var(--secondary-color)" />
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Reporting_Manger;
