import React, { useContext, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import demo from "../../../components/photos/demo.png";
import MultiStepFormContext from "./MultiStepFormContext";
import { BiUpload } from "react-icons/bi";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import swal from "sweetalert";
import axios from "axios"

const Organisation_details = () => {
  // alerts
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMess, setSuccessAlertMess] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [failAlertMess, setFailAlertMess] = useState("");
  // alerts
  const { t } = useTranslation();
  const register_detail = useSelector((state) => state.Reducer);
  const { next, prev, organisation, setOrganisation, contact_initial } =
    useContext(MultiStepFormContext);

  // const initialValues = {
  //   company_logo: "",
  //   company: "",
  //   registration: "",
  //   website: "",
  //   addressline1: "",
  //   addressline2: "",
  //   country: "",
  //   state: "",
  //   city: "",
  //   pincode: "",
  // };

  const contact_values = contact_initial;
  const initialValues = organisation;
  const signUpSchema = Yup.object({
    // company_logo: Yup.mixed().required("Select Company Logo").test("fileSize", "Max Size 2MB", (file) => {
    //   if (file) {
    //     return file.size <= 2000000;
    //   } else {
    //     return true;
    //   }
    // }),

    company: Yup.string()
      .required("Enter Your company Name")
      .min(2, "This is a required field"),

    // registration: Yup.string()
    //   .required("Enter Your registration")
    //   .min(2, "This is a required field"),

    website: Yup.string()
    .required("Enter Your registration")
    .matches(
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)$/,
      "Invalid website domain format"
    ),
    

    addressline1: Yup.string()
      .required("Enter Your address line1")
      .min(2, "This is a required field"),

    addressline2: Yup.string()
      .required("Enter Your address line2")
      .min(2, "This is a required field"),

    country: Yup.string()
      .required("Enter Your country")
      .min(2, "This is a required field"),

    state: Yup.string().required("Enter Your State"),

    city: Yup.string().required("Enter Your city"),

    pincode: Yup.string()
      .required("Enter Your pincode")
      .min(2, "This is a required field"),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      // next();
      // setOrganisation(values);
      register(values);
      // action.resetForm();
    },
  });

  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);

  // register api
  async function register(e) {
    console.log(contact_values);
    try {
      await axiosInstance
        .post(
          `${register_detail.GLOBAL_API}WizRegister`,
          {
            first_name: contact_values.first_name,
            last_name: contact_values.last_name,
            email: contact_values.email,
            mobile: contact_values.contact_number,
            password: contact_values.password,
            role: contact_values.role,
            company_name: e.company,
            company_registration: e.registration,
            company_website: e.website,
            address1: e.addressline1,
            address2: e.addressline2,
            country: e.country,
            state: e.state,
            city: e.city,
            pincode: e.pincode,
          },
          {
            httpsAgent: register_detail.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("res=", response);
          const userid = response.data.userId;
          if (response.status == 200) {
            // alert success
            const successMessage = response.data.message || "Success";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });
            setTimeout(() => {
              swal.close();
            }, 2000);
            // alert success
            // thank you
            async function thank(e) {
              // console.log("thanku_user=",e)
              console.log(contact_values);
              try {
                await axiosInstance
                  .post(
                    `${register_detail.GLOBAL_API}ThankyouMail`,
                    {
                      userid: e,
                    },
                    {
                      httpsAgent: register_detail.HTTP_AGENT,
                    }
                  )
                  .then((response) => {
                    console.log(response.status);
                  });
              } catch (e) {
                console.log(e);
                console.log(e.response.data.error);
              }
            }
            thank(userid);
            // thank you

            // email for verify
            async function verify_email(e) {
              // console.log("thanku_user=",e)
              console.log(contact_values);
              try {
                await axiosInstance
                  .post(
                    `${register_detail.GLOBAL_API}emailVerify`,
                    {
                      userid: e,
                    },
                    {
                      httpsAgent: register_detail.HTTP_AGENT,
                    }
                  )
                  .then((response) => {
                    console.log(response.status);
                  });
              } catch (e) {
                console.log(e);
                console.log(e.response.data.error);
              }
            }
            verify_email(userid);
            // email for verify

            next();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });
            // alert fail
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      // alert fail
    }
  }
  // register api
   //state to store country name
   const [countryName, setCountryName] = useState([]);

   //state to store states name
   const [statesName, setStatesName] = useState([]);
    //state to store cities name
    const [citiesName, setCitiesName] = useState([]);
 
   const [countryISO,setCountryISO]=useState();


     //api call to get country name
  async function getCountriesname() {
    try {
      await axios
        .get("https://api.countrystatecity.in/v1/countries", {
          headers: {
            "X-CSCAPI-KEY":
              "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
          },
        })
        .then((response) => {
          setCountryName(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  }

    //calling state API after the state is selected
    async function getStateName(countrycode) {
      setCountryISO(countrycode);
      try {
        await axios
          .get(
            `https://api.countrystatecity.in/v1/countries/${countrycode}/states`,
            {
              headers: {
                "X-CSCAPI-KEY":
                  "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
              },
            }
          )
          .then((response) => {
            setStatesName(response.data);
          });
      } catch (e) {
        console.log(e);
      }
    }

     //calling state API after the country is selected
   async function getCityName(statecode) {
    try {
      await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${countryISO}/states/${statecode}/cities`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
            },
          }
        )
        .then((response) => {
          setCitiesName(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  }
     //calling api to get country name on page load
  useEffect(() => {
    getCountriesname();
  }, []);


  

  return (
    <>
      {/* Alert to show on fail API response */}
      {showFailAlert && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {failAlertMess ? `${failAlertMess}` : "Something Went Wrong"}
          </Alert>
        </Stack>
      )}
      {/* Alert to show on fail API response */}
      <div className="contact_person mt-2">
        <div className="contact_heading">
          <h1>{t("Organisation_Details")}</h1>
        </div>
      </div>
      <div className="formright mt-2">
        <form onSubmit={handleSubmit}>
          {/* <div className="profile_upload">
            {selectedImage ? (
              <img className="set_profile_img" src={selectedImage} alt="" />
            ) : (
              <img className="set_profile_img" src={demo} alt="" />
            )}
            <div className="upload_btn mt-2">
              <button
                className="profilebtn"
                type="button"
                onClick={() => fileInputRef.current.click()}
              >
                <BiUpload /> {t("upload_comapany_logo")}
              </button>
              <input
                accept=".png,.jpg,.jpeg"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setSelectedImage(URL.createObjectURL(file));
                  }
                  setFieldValue("company_logo", e.currentTarget.files[0]);
                }}
                name="company_logo"
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
              />
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.company_logo && touched.company_logo ? (
                  <p className="form-error">{errors.company_logo}</p>
                ) : null}
              </div>
            </div>
          </div> */}

          <div className="input_boxes mt-2">
            <div className="left_boxes">
              <div className="input-container mt-2">
                <TextField
                  name="company"
                  value={values.company}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^A-Za-z0-9\s]/g,
                      ""
                    );
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Company Name*"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.company && touched.company ? (
                    <p className="form-error">{errors.company}</p>
                  ) : null}
                </div>
              </div>

              <div className="input-container mt-2">
                <TextField
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Website Domain*"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.website && touched.website ? (
                    <p className="form-error">{errors.website}</p>
                  ) : "Eg:- www.xyz.com"}
                </div>
              </div>
              <div className="input-container mt-2">
                <TextField
                  name="addressline2"
                  value={values.addressline2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Address Line2*"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.addressline2 && touched.addressline2 ? (
                    <p className="form-error">{errors.addressline2}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="right_boxes">
              <div className="input-container mt-2">
                <TextField
                  name="registration"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^A-Za-z0-9\s]/g,
                      ""
                    );
                  }}
                  value={values.registration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="GST Number"
                  variant="outlined"
                />
                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.registration && touched.registration ? (
                    <p className="form-error">{errors.registration}</p>
                  ) : null}
                </div>
              </div>
              <div className="input-container mt-2">
                <TextField
                  name="addressline1"
                  value={values.addressline1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Address Line1*"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.addressline1 && touched.addressline1 ? (
                    <p className="form-error">{errors.addressline1}</p>
                  ) : null}
                </div>
              </div>

              <div className="input-container mt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country*</InputLabel>
                <Select
                  name="country"
                  
                  onChange={(e) => {
                    const [selectedCountryName, selectedCountryIso2] =
                      e.target.value.split(",");
                    console.log("selectedCountryIso2", selectedCountryIso2);
                    setFieldValue("country", selectedCountryName);
                    const state = selectedCountryIso2
                      ? getStateName(selectedCountryIso2)
                      : null;
                  }}
                  onBlur={handleBlur}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Country"
                >
                  {/* <MenuItem value="">Ten</MenuItem> */}
                  {countryName
                    ? countryName.map((country, index) => (
                      <MenuItem key={index}  value={`${country.name},${country.iso2}`}> {country.name}</MenuItem>
                      ))
                    : ""}
               
               
                </Select>
              </FormControl>

              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.city && touched.city ? (
                  <p className="form-error">{errors.city}</p>
                ) : null}
              </div>
            </div>
            </div>
          </div>

          <div className="state_pincode_div">
            <div className="state_city_pincode mt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">State*</InputLabel>
                <Select
                  name="state"
                   onChange={(e) => {
                    const [selectedStateName, selectedStateIso2] =
                      e.target.value.split(",");
                    console.log("selectedStateIso2", selectedStateIso2);
                    setFieldValue("state", selectedStateName);
                    const city = selectedStateIso2
                      ? getCityName(selectedStateIso2)
                      : null;
                  }}
                  onBlur={handleBlur}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="State"
                >
                  {/* <MenuItem value="">Ten</MenuItem> */}
                  {statesName
                    ? statesName.map((state, index) => (
                      <MenuItem key={index}  value={`${state.name},${state.iso2}`}>{state.name}</MenuItem>
                      ))
                    : "No states available"}
                
                
                </Select>
              </FormControl>

              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.state && touched.state ? (
                  <p className="form-error">{errors.state}</p>
                ) : null}
              </div>
            </div>

            <div className="state_city_pincode mt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City*</InputLabel>
                <Select
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="City"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                   {citiesName
                    ? citiesName.map((city, index) => (
                      <MenuItem  key={index}  value={city.name}>{city.name}</MenuItem>
                      
                      ))
                    : "No cities available"}
                  {/* <MenuItem value="">Ten</MenuItem> */}
               
              
                </Select>
              </FormControl>

              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.city && touched.city ? (
                  <p className="form-error">{errors.city}</p>
                ) : null}
              </div>
            </div>

            <div className="state_city_pincode mt-2">
              <TextField
                name="pincode"
                value={values.pincode}
                onInput={(e) => {
                  e.target.value = (e.target.value || "")
                    .replace(/[^\d]/g, "")
                    .slice(0, 6);
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                className="text_input"
                id="outlined-basic"
                label="Pincode*"
                variant="outlined"
              />

              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.pincode && touched.pincode ? (
                  <p className="form-error">{errors.pincode}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div>
            <div className="next_prev_btn mt-3">
              <button onClick={prev} className="cusbtn_next">
                {t("btn_back")}
              </button>
              <button type="submit" className="cusbtn_next">
                {t("btn_next")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Organisation_details;
