import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";

const Invoice_Settings = () => {
  // Employee details edit start
  const signUpSchema = Yup.object({
    invioceperfix: Yup.string().required("Enter Invioce Perfix"),
    invioceperlogo: Yup.mixed()
      .required("Select Profile")
      .test("fileSize", "Recommended image size is 200px x 40px", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
  });

  const [initialValues, setinitialValues] = useState({
    invioceperfix: "",
    invioceperlogo: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);

      action.resetForm();
    },
  });

  // upload image
  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation();



  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="detailsbox mt-2">
          <label htmlFor="invioceperfix" className="input-label">
            Invioce Perfix
          </label>
          <div className="inputbox">
            <input
              type="name"
              className="custominputprofile"
              autoComplete="off"
              name="invioceperfix"
              id="invioceperfix"
              placeholder="INV"
              value={values.invioceperfix}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.invioceperfix && errors.invioceperfix ? (
              <p className="form-error">{errors.invioceperfix}</p>
            ) : null}
          </div>
        </div>
        <div className="detailsbox mt-2">
          <label htmlFor="invioceperlogo" className="input-label">
            Invioce Logo
          </label>
          <div className="inputbox">
            <div className="inputinvioceprof">
              <div className="inputinvpro" style={{flex: '1'}}>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                className="custominputprofile"
                autoComplete="off"
                name="invioceperlogo"
                id="invioceperlogo"
                placeholder="INV"
                value={values.invioceperlogo}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setSelectedImage(URL.createObjectURL(file));
                  }
                  setFieldValue("profilephoto", e.currentTarget.files[0]);
                }}
                ref={fileInputRef}
                onBlur={handleBlur}
              />
              {touched.invioceperlogo && errors.invioceperlogo ? (
                <p className="form-error">{errors.invioceperlogo}</p>
              ) : null}
              </div>
              {selectedImage ? (
                <>
                  <div className="inviocesettingprof">
                    <img
                      className=""
                      src={selectedImage}
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="inviocesettingprof">
                    <div className="empty_profile_img">
                      <FaUser size="2em" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div class="butnsec"><button class="cusbtn">Save</button></div>
      </form>
    </>
  )
}

export default Invoice_Settings
