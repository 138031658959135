import React, { useState, useRef, useCallback, useEffect } from "react";
import csv from "../../../components/photos/pdf.png";
import swal from "sweetalert";
import uploadImg from "../../../components/photos/uploadimg.png";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { CgCloseR } from "react-icons/cg";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

const Document_deatils = (props) => {
  // upload file
  // console.log(props);
  const employee_page = useSelector((state) => state.Reducer);
  const [selectedFiles, setSelectedFiles] = useState({});

  const handleFileInputChange = (e, documentType) => {
    const file = e.target.files[0];
    setSelectedFiles((prevState) => ({
      ...prevState,
      [documentType]: file,
    }));
  };
  // const fileInputRef = useRef(null);
  // get all document
  const [documentsFromAPI, setDocumentsFromAPI] = useState();
  const [for_pop_up, setFor_pop_up] = useState("");
  const get_all_doc = useCallback(async () => {
    try {
      await axiosInstance
        .post(
          `getSettings`,
          {
            type: "Document",
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            map_all_doc(response.data);
          }
          // setDocumentsFromAPI(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_all_doc();
  }, [get_all_doc]);

  async function map_all_doc(e) {
    let requestData = {};

    if (props.onboard) {
      requestData.onboard_id = props.id;
      requestData.type = "Onboard";
    } else {
      requestData.employee_id = props.id;
      requestData.type = "Employee";
    }
    try {
      await axiosInstance
        .post(`GetDocument`, requestData, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("doc pdf=", response.data);
          if (response.status == 200) {
            const updatedApiResponse = e.map((item) => {
              // Find matching item in the second API response based on "id" and "document_id"
              const matchingItem = response.data.find(
                (secondItem) => secondItem.document_id === item.id
              );

              // If a matching item is found, update the item
              if (matchingItem) {
                return {
                  ...item, // Keep existing fields
                  pdf: matchingItem.value, // Add corresponding URL as "pdf"
                  id_sec: matchingItem.id,
                };
              }

              // If no matching item is found, return the original item
              // console.log("get=",item);
              return item;
            });

            setDocumentsFromAPI(updatedApiResponse);
          }
        });
    } catch (error) {
      setDocumentsFromAPI(e);
      console.log(error);
      console.log(error.response.data.error);
    }
  }
  console.log("documentsFromAPI=", documentsFromAPI);
  // get all document

  // upload file post
  const submitfile_post = (e, name, id) => {
    if (!e) {
      swal({
        title: "Failure!",
        text: "Please Select a file",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    if (e.size >= 2 * 1024 * 1024) {
      swal({
        title: "Failure!",
        text: "Maximum Size of file is 2MB",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    upload_pdf();
    async function upload_pdf() {
      var dataa = new FormData();
      if (props.onboard) {
        dataa.append("type", "Onboard");
        dataa.append("onboard_id", props.id);
      } else {
        dataa.append("type", "Employee");
        dataa.append("employee_id", props.id);
      }

      dataa.append("document_id", id);
      dataa.append("file", e);
      dataa.append("document_name", name);

      try {
        await axiosInstance
          .post(`AddDocument`, dataa, {
            httpsAgent: employee_page.HTTP_AGENT,
          })
          .then((response) => {
            // console.log("bulk=", response.data.count);
            if (response.status == 200) {
              get_all_doc();
              const successMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Success!",
                text: successMessage,
                icon: "success",
                buttons: {
                  confirm: {
                    text: "OK",
                    className: "swal-button--custom",
                  },
                },
              });
              setTimeout(() => {
                swal.close();
              }, 2000);
            } else {
              const errorMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Failure!",
                text: errorMessage,
                icon: "error",
                buttons: {
                  confirm: {
                    text: "OK",
                    className: "swal-button--custom",
                  },
                },
              });

              // Close the SweetAlert after 3000 milliseconds (3 seconds)
              setTimeout(() => {
                swal.close();
              }, 2000);
            }
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
        const errorMessage = e.response.data.error || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    }
  };

  // upload file post

  // upload file put
  const submitfile_put = (e, name, id) => {
    if (!e) {
      swal({
        title: "Failure!",
        text: "Please Select a file",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    if (e.size >= 2 * 1024 * 1024) {
      swal({
        title: "Failure!",
        text: "Maximum Size of file is 2MB",
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    upload_pdf();
    async function upload_pdf() {
      var dataa = new FormData();

      dataa.append("id", id);
      dataa.append("file", e);
      // dataa.append("document_name", name);

      try {
        await axiosInstance
          .put(`EditDocument`, dataa, {
            httpsAgent: employee_page.HTTP_AGENT,
          })
          .then((response) => {
            // console.log("bulk=", response.data.count);
            if (response.status == 200) {
              get_all_doc();
              const successMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Success!",
                text: successMessage,
                icon: "success",
                buttons: {
                  confirm: {
                    text: "OK",
                    className: "swal-button--custom",
                  },
                },
              });
              setTimeout(() => {
                swal.close();
              }, 2000);
            } else {
              const errorMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Failure!",
                text: errorMessage,
                icon: "error",
                buttons: {
                  confirm: {
                    text: "OK",
                    className: "swal-button--custom",
                  },
                },
              });

              // Close the SweetAlert after 3000 milliseconds (3 seconds)
              setTimeout(() => {
                swal.close();
              }, 2000);
            }
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
        const errorMessage = e.response.data.error || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              className: "swal-button--custom",
            },
          },
        });
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    }
  };

  // upload file put

  const [showPdf, setShowPdf] = useState(false);
  // console.log(URL.createObjectURL(selectedFile))
  return (
    <>
      {/* pop up */}
      {showPdf && (
        <div className="modal">
          <div
            onClick={() => {
              setShowPdf(!showPdf);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>PDF View</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setShowPdf(!showPdf);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <embed
                src={for_pop_up}
                type="application/pdf"
                style={{ width: "100%" }}
                height="480"
              />
            </div>
          </div>
        </div>
      )}
      {/* pop up pdf  */}

      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Document</h1>
          <p>Updload your documents</p>
        </div>
      </div>

      <div>
        {documentsFromAPI
          ? documentsFromAPI.map((document) => (
              <div className="bulk_document_upload mt-2">
                <div key={document.id} className="upload_document">
                  <h1>Upload {document.value}</h1>
                  <div className="app_upload">
                    <div className="parent">
                      <div className="file-upload">
                        {selectedFiles[document.value] ? (
                          <img
                            style={{ height: "45px" }}
                            src={csv}
                            alt="upload"
                          />
                        ) : (
                          <img
                            className="after_file_upload"
                            src={uploadImg}
                            alt="upload"
                          />
                        )}
                        {selectedFiles[document.value] ? (
                          <h3 style={{ fontSize: "14px" }}>
                            {selectedFiles[document.value].name.length > 18
                              ? selectedFiles[document.value].name.slice(
                                  0,
                                  18
                                ) + "...pdf"
                              : selectedFiles[document.value].name}
                          </h3>
                        ) : (
                          <h3>Select PDF file for {document.value}</h3>
                        )}
                        <input
                          type="file"
                          style={{ color: "var(--Whiteblack-color)" }}
                          name="file"
                          onChange={(e) =>
                            handleFileInputChange(e, document.value)
                          }
                          accept=".pdf"
                        />
                      </div>
                    </div>
                  </div>
                  {selectedFiles[document.value] && (
                    <button
                      className="cusbtn"
                      style={{ marginTop: "5px" }}
                      onClick={() => {
                        console.log(document);
                        if (document.pdf) {
                          submitfile_put(
                            selectedFiles[document.value],
                            document.name,
                            document.id_sec
                          );
                        } else {
                          submitfile_post(
                            selectedFiles[document.value],
                            document.name,
                            document.id
                          );
                        }
                      }}
                    >
                      Upload Document
                    </button>
                  )}
                </div>

                {document.pdf && !selectedFiles[document.value] && (
                  // selectedFiles[document.value] && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <iframe
                      src={document.pdf}
                      type="application/pdf"
                      style={{ cursor: "pointer" }}
                      width="200"
                      height="220"
                    ></iframe>
                    <button
                      className="cusbtn"
                      onClick={() => {
                        setFor_pop_up(document.pdf);
                        setShowPdf(!showPdf);
                      }}
                    >
                      Preview
                    </button>
                  </div>
                )}

                {selectedFiles[document.value] && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <iframe
                      src={URL.createObjectURL(selectedFiles[document.value])}
                      type="application/pdf"
                      style={{ cursor: "pointer" }}
                      width="200"
                      height="220"
                    ></iframe>
                    <button
                      className="cusbtn"
                      onClick={() => {
                        setFor_pop_up(
                          URL.createObjectURL(selectedFiles[document.value])
                        );
                        setShowPdf(!showPdf);
                      }}
                    >
                      Preview
                    </button>
                  </div>
                )}
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

export default Document_deatils;
