import React, { useState, useRef, useCallback, useEffect } from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiPlusMedical} from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import swal from "sweetalert";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

// to edit dependent values
const DependantPOpUP = (props) => {
  // Create Event
  // get employee by id
  const [reloadData, setReloadData] = useState(false);

  const reloadDataHandler = () => {
    setReloadData((prevState) => !prevState);
  };
  const employee_page = useSelector((state) => state.Reducer);
  const get_dependant_byid = useCallback(async () => {
    try {
      let requestData = { dependent_id: props.dependent_id };
      if (props.onboard) {
        requestData.onboard_id = props.id;
      } else {
        requestData.employee_id = props.id;
      }
      await axiosInstance
        .post(`DependentDetailById`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update depandant pop=", response.data);
          const date = new Date(response.data[0].dependent_dob);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          setinitialValues({
            depandentname: response.data[0].dependent_name,
            depandentrelation: response.data[0].dependent_relation,
            depandentdateofbirth: formattedDate,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_dependant_byid();
  }, [get_dependant_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    depandentname: Yup.string().required("Enter Dependent Name"),
    depandentrelation: Yup.string().required("Enter Dependent Relation"),
    depandentdateofbirth: Yup.string().required(
      "Enter Depandent Date of Birth"
    ),
  });

  const [initialValues, setinitialValues] = useState({
    depandentname: "",
    depandentrelation: "",
    depandentdateofbirth: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      add_dependent_put(values);
    },
  });

  // put identity deatils
  async function add_dependent_put(e) {    
    try {
      await axiosInstance
        .put(
          `EditDependentDetail`,
          {
            dependent_id: props.dependent_id,
            dependent_name: e.depandentname,
            dependent_dob: e.depandentdateofbirth,
            dependent_relation: e.depandentrelation,
          },
          {
            headers: {
              Authorization: `Bearer ${employee_page.TOKEN}`,
            },
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
         console.log("hello");
         
          if (response.status === 200) {
            props.get_dependant_byid();
            props.setDependent_pop_up(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
             
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              props.DependantPOpUP(false);
              reloadDataHandler();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
        props.setDependent_pop_up(false);
      }, 2000);
    }
  }
  // put identity deatils
  return (
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="depandentname"
            value={values.depandentname}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Depandent Name"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.depandentname && touched.depandentname ? (
              <p className="form-error">{errors.depandentname}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="depandentrelation"
            value={values.depandentrelation}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Depandent Relation"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.depandentrelation && touched.depandentrelation ? (
              <p className="form-error">{errors.depandentrelation}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="depandentdateofbirth"
            value={values.depandentdateofbirth}
            type="date"
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            label="Date of birth"
            id="outlined-basic"
            // label="Depandent Date of Birth"
            variant="outlined"
            disableFuture={true}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                placeholder: "", 
              },
            }}
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.depandentdateofbirth && touched.depandentdateofbirth ? (
              <p className="form-error">{errors.depandentdateofbirth}</p>
            ) : null}
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
  );
};

// for the UI of dependent
const Dependant = (props) => {
  // Create Event
  const [Create_depandent_modal, setCreate_depandent_modal] = useState(false);
  const [dependent_pop_up, setDependent_pop_up] = useState(false);
  const [timeline_value, setTimeline_value] = useState("");
  const [dependentIdforPopUp, setDependentIdforPopUp] = useState("");
  const navigate = useNavigate();

  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const [id_for_put, setId_for_put] = useState("");
  const get_dependant_byid = useCallback(async () => {
    try {
      let requestData = {};
      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`DependentDetail`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update depandant=", response.data);
          setTimeline_value(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_dependant_byid();
  }, [get_dependant_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    depandentname: Yup.string().required("Enter Dependent Name"),
    depandentrelation: Yup.string().required("Enter Dependent Relation"),
    depandentdateofbirth: Yup.string().required(
      "Enter Depandent Date of Birth"
    ),
  });

  const [initialValues, setinitialValues] = useState({
    depandentname: "",
    depandentrelation: "",
    depandentdateofbirth: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);

      add_dependent_post(values);
    },
  });


  async function add_dependent_post(e) {
    let requestData = {
      dependent_name: e.depandentname,
      dependent_dob: e.depandentdateofbirth,
      dependent_relation: e.depandentrelation,
    };

    if (props.onboard) {
      requestData.onboard_id = props.id;
      requestData.type = "Onboard";
    } else {
      requestData.employee_id = props.id;
      requestData.type = "Employee";
    }
    try {
      await axiosInstance
        .post(`AddDependent`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            setCreate_depandent_modal(false);
            get_dependant_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
             
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              }});

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              setCreate_depandent_modal(false);
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
        setCreate_depandent_modal(false);
      }, 2000);
    }
  }
  // for adding dependenat details
  return (
    <>
      {/* pop up */}
      {Create_depandent_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_depandent_modal(!Create_depandent_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Dependent</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_depandent_modal(!Create_depandent_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="depandentname"
                    value={values.depandentname}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Depandent Name"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.depandentname && touched.depandentname ? (
                      <p className="form-error">{errors.depandentname}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="depandentrelation"
                    value={values.depandentrelation}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Depandent Relation"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.depandentrelation && touched.depandentrelation ? (
                      <p className="form-error">{errors.depandentrelation}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="depandentdateofbirth"
                    value={values.depandentdateofbirth}
                    type="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Depandent Date of Birth"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        placeholder: "", 
                      },
                    }}
                    disableFuture
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.depandentdateofbirth &&
                    touched.depandentdateofbirth ? (
                      <p className="form-error">
                        {errors.depandentdateofbirth}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}
      {dependent_pop_up && (
        <div className="modal">
          <div
            onClick={() => {
              setDependent_pop_up(!dependent_pop_up);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Dependent</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setDependent_pop_up(!dependent_pop_up);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <DependantPOpUP
                get_dependant_byid={get_dependant_byid}
                setDependent_pop_up={setDependent_pop_up}
                dependent_id={dependentIdforPopUp}
                id={props.id}
                onboard={props.onboard}
              />
            </div>
          </div>
        </div>
      )}

      {/* <form onSubmit={handleSubmit}> */}
      <div className="secondheading mt-1">
        <div className="secondheadingbox">
          <div className="addbtn">
            <h2>Dependent</h2>
            <button
              className="cusbtnnew"
              type="button"
              onClick={() => {
                setCreate_depandent_modal(!Create_depandent_modal);
              }}
            >
              <BiPlusMedical size="0.8rem" />
            </button>
          </div>
          <p>Update employee Depandent information</p>
        </div>
      </div>
      <div className="timelinebox mt-2 mb-3">
        {timeline_value
          ? timeline_value.map((ele, index) => {
              return (
                <div className="dependent" index={index} key={index}>
                  <div className="dependentcircle"></div>
                  <div style={{ width: "50%" }} className="depantdetailbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>{ele.dependent_name}</h2>{" "}
                      <FaEdit
                        onClick={() => {
                          setDependentIdforPopUp(ele.id);
                          setDependent_pop_up(!dependent_pop_up);
                        }}
                        style={{
                          color: "var(--secondary-color)",
                          marginLeft: "7px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Date of Birth&nbsp;:&nbsp;
                        </span>
                        {ele.dependent_dob
                          ? ele.dependent_dob.substring(0, 10)
                          : ele.dependent_dob}
                      </p>
                      <p className="relationbtn">{ele.dependent_relation}</p>
                    </div>
                  </div>
                </div>
              );
            })
          : "No Dependent"}
      </div>
      {/* </form> */}
    </>
  );
};
// employee dependant

// identity
const Identity = (props) => {
  // console.log(props.id)
  const [editInput, setEditInput] = useState(true);
  const navigate = useNavigate();

  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const [personal_post_put, setPersonal_post_put] = useState("");
  const [id_for_put, setId_for_put] = useState("");
  const get_identity_byid = useCallback(async () => {
    try {
      let requestData = {};
      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`IdentityDetail`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("updatepersonal=", response.data);
          if (response.data) {
            setPersonal_post_put("put");
            setId_for_put(response.data[0].id);
            setinitialValues({
              uan: response.data[0].uan,
              pan: response.data[0].pan,
              aadhaar: response.data[0].aadhar,
              esic:response.data[0].esic
            });
            // setInitialEmployeePersonalData(response.data);
          } else {
            setPersonal_post_put("post");
          }
        });
    } catch (e) {
      setPersonal_post_put("post");
      // console.log(e);
      // console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_identity_byid();
  }, [get_identity_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    uan: Yup.string().min(12, "Enter valid UAN Number"),
    pan: Yup.string().min(10, "Enter valid Pan"),
    aadhaar: Yup.string().min(12, "Enter valid Aadhaar Number"),
    esic: Yup.string().min(17, "Enter valid Esic Number"),
  });

  const [initialValues, setinitialValues] = useState({
    uan: "",
    pan: "",
    aadhaar: "",
    esic: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      const changedValues = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          changedValues[key] = values[key];
        }
      }

      // Map form fields to API keys
      const apiData = {
        uan: values.uan,
        pan: values.pan,
        aadhar: values.aadhaar,
        esic: values.esic,
      };

      // Filter out undefined values
      const filteredApiData = Object.fromEntries(
        Object.entries(apiData).filter(([_, v]) => v !== undefined)
      );

      if (personal_post_put == "put") {
        add_identity_put(filteredApiData);
      } else if (personal_post_put == "post") {
        add_identity_post(filteredApiData);
      }
    },
  });

  // add identity deatils
  async function add_identity_post(e) {
    console.log("api post");
    if (props.onboard) {
      e.onboard_id = props.id;
      e.type = "Onboard";
    } else {
      e.employee_id = props.id;
      e.type = "Employee";
    }
    try {
      await axiosInstance
        .post(`EmployeeIdentity`, e, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200) {
            setEditInput(false)
            get_identity_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = "All Feilds Are Required";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add identity deatils

  // put identity deatils
  async function add_identity_put(e) {
    console.log("api put");

    if (props.onboard) {
      e.onboard_id = props.id;
      e.indentity_id = id_for_put;
      e.type = "Onboard";
    } else {
      e.employee_id = props.id;
      e.indentity_id = id_for_put;
      e.type = "Employee";
    }
    console.log(e);
    try {
      await axiosInstance
        .put(`EditIdentityDetail`, e, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update=", response);
          setEditInput(false)
          if (response.status == 200) {
            get_identity_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put identity deatils
  return (
    <>
      <div className="headingbox mt-1">
        <div className="headingleftbox">
          <h1>Identity</h1>
          <p>Update employee identity information</p>
        </div>
        <div className="headingrightbox">
          <button
            className="editbutton"
            onClick={() => {
              setEditInput(!editInput);
            }}
          >
            <FaEdit />{" "}
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="empl_edit_page_detailboxes">
          <div className="detailsbox mt-2">
            <label htmlFor="uan" className="input-label">
              UAN
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
                  e.target.value = e.target.value.slice(0, 12);
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="uan"
                id="uan"
                placeholder="00000-00000-0000"
                value={values.uan}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.uan && errors.uan ? (
                <p className="form-error">{errors.uan}</p>
              ) : null}
            </div>
          </div>
          <div className="detailsbox mt-2">
            <label htmlFor="pan" className="input-label">
              PAN
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  // Replace non-alphanumeric characters with an empty string
                  e.target.value = (e.target.value || "").replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  );
                  // Limit the input to a maximum of 10 characters
                  e.target.value = e.target.value.slice(0, 10);
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="pan"
                id="pan"
                placeholder="PAN"
                value={values.pan}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.pan && errors.pan ? (
                <p className="form-error">{errors.pan}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="empl_edit_page_detailboxes">
          <div className="detailsbox mt-2">
            <label htmlFor="esic " className="input-label">
              Esic Number
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
                  e.target.value = e.target.value.slice(0, 17);
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="esic"
                id="esic"
                // placeholder="0000 0000 0000"
                value={values.esic}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.esic && errors.esic ? (
                <p className="form-error">{errors.esic}</p>
              ) : null}
            </div>
          </div>
          <div className="detailsbox mt-2">
            <label htmlFor="aadhaar" className="input-label">
              Aadhaar
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
                  e.target.value = e.target.value.slice(0, 12);
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="aadhaar"
                id="aadhaar"
                placeholder="0000 0000 0000"
                value={values.aadhaar}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.aadhaar && errors.aadhaar ? (
                <p className="form-error">{errors.aadhaar}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <button type="submit" className="cusbtn" disabled={editInput}>
            Update Identity detail
          </button>
        </div>
      </form>
    </>
  );
};
//  identity

// bank
const BankInfo = (props) => {
  // console.log(props.id)
  const [editInput, setEditInput] = useState(true);
  const navigate = useNavigate();

  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const [personal_post_put, setPersonal_post_put] = useState("");
  const [id_for_put, setId_for_put] = useState("");
  const get_bank_byid = useCallback(async () => {
    try {
      let requestData = {};
      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`BankDetails`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.data) {
            setPersonal_post_put("put");
            setId_for_put(response.data[0].id);
            setinitialValues({
              bank_name: response.data[0].bank_name,
              acc_no: response.data[0].acc_no,
              ifcs_code: response.data[0].ifcs_code,
            });
            // setInitialEmployeePersonalData(response.data);
          } else {
            setPersonal_post_put("post");
          }
        });
    } catch (e) {
      setPersonal_post_put("post");
  
    }
  }, []);

  useEffect(() => {

    
    get_bank_byid();
  }, [get_bank_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    // bank_name: Yup.string().required("Enter Bank Name"),
    // acc_no: Yup.string().required("Enter Account Number Number"),
    // ifcs_code: Yup.string().required("Enter IFSC Code"),
  });

  const [initialValues, setinitialValues] = useState({
    bank_name: "",
    acc_no: "",
    ifcs_code: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      const changedValues = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          changedValues[key] = values[key];
        }
      }

      // Map form fields to API keys
      const apiData = {
        bank_name: values.bank_name,
        acc_no: values.acc_no,
        ifcs_code: values.ifcs_code,
      };

      // Filter out undefined values
      const filteredApiData = Object.fromEntries(
        Object.entries(apiData).filter(([_, v]) => v !== undefined)
      );
      if (personal_post_put == "put") {
        add_bank_put(filteredApiData);
      } else if (personal_post_put == "post") {
        add_bank_post(filteredApiData);
      }
    },
  });

  // add identity deatils
  async function add_bank_post(e) {
    // console.log("api post");
    // console.log(props.id);

    if (props.onboard) {
      e.onboard_id = props.id;
      e.type = "Onboard";
    } else {
      e.employee_id = props.id;
      e.type = "Employee";
    }
    try {
      await axiosInstance
        .post(`AddBankDetail`, e, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status == 200) {
            setEditInput(true)
            get_bank_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = "All Deatils Required";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add identity deatils

  // put identity deatils
  async function add_bank_put(e) {
    if (props.onboard) {
      e.onboard_id = props.id;
      e.bankDetail_id = id_for_put;
      e.type = "Onboard";
    } else {
      e.employee_id = props.id;
      e.bankDetail_id = id_for_put;
      e.type = "Employee";
    }
    try {
      await axiosInstance
        .put(`EditBankdetail`, e, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200) {
            get_bank_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put identity deatils
  return (
    <>
      <div className="headingbox mt-1">
        <div className="headingleftbox">
          <h1>Bank Information</h1>
          <p>Update Bank information</p>
        </div>
        <div className="headingrightbox">
          <button
            className="editbutton"
            onClick={() => {
              setEditInput(!editInput);
            }}
          >
            <FaEdit />{" "}
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="empl_edit_page_detailboxes">
          <div className="detailsbox mt-2">
            <label htmlFor="bank_name" className="input-label">
              Bank Name
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  e.target.value = (e.target.value || "").replace(
                    /[^A-Za-z\s]/g,
                    ""
                  );
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="bank_name"
                id="bank_name"
                placeholder="Bank Name"
                value={values.bank_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.bank_name && errors.bank_name ? (
                <p className="form-error">{errors.bank_name}</p>
              ) : null}
            </div>
          </div>
          <div className="detailsbox mt-2">
            <label htmlFor="acc_no" className="input-label">
              Account Number
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
                  // e.target.value = e.target.value.slice(0, 10);
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="acc_no"
                id="acc_no"
                placeholder="Account Number"
                value={values.acc_no}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.acc_no && errors.acc_no ? (
                <p className="form-error">{errors.acc_no}</p>
              ) : null}
            </div>
          </div>
          <div className="detailsbox mt-2">
            <label htmlFor="ifcs_code" className="input-label">
              IFSC Code
            </label>
            <div className="inputbox">
              <input
                type="name"
                onInput={(e) => {
                  // e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
                  e.target.value = e.target.value.slice(0, 12);
                }}
                className={
                  editInput ? "nocustominputprofile" : "custominputprofile"
                }
                readOnly={editInput}
                autoComplete="off"
                name="ifcs_code"
                id="ifcs_code"
                placeholder="IFSC Code"
                value={values.ifcs_code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.ifcs_code && errors.ifcs_code ? (
                <p className="form-error">{errors.ifcs_code}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <button type="submit" className="cusbtn" disabled={editInput}>
            Update Bank detail
          </button>
        </div>
      </form>
    </>
  );
};
// bank

// employee personal
const Employee_personal = (props) => {
  // console.log(props.onboard);
  const [editInput, setEditInput] = useState(true);
  const navigate = useNavigate();
  // get_all_state api
  // const [State, setState] = useState("");
  // async function get_all_State() {
  //   try {
  //     await axios
  //       .get(`https://api.Statestatecity.in/v1/countries/IN/states`, {
  //         headers: {
  //           "X-CSCAPI-KEY":
  //             "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
  //         },
  //       })
  //       .then((response) => {
  //         // console.log("c=", response.data);
  //         setState(response.data);
  //       });
  //   } catch (e) {
  //     console.log(e);
  //     console.log(e.response.data.error);
  //   }
  // }
  // useEffect(() => {
  //   get_all_State();
  // }, []);
  // get_all_State api

  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  // const [initialEmployeePersonalData, setInitialEmployeePersonalData] =
  //   useState("");
  const [personal_post_put, setPersonal_post_put] = useState("");
  const [id_for_put, setId_for_put] = useState("");
  const get_employee_byid = useCallback(async () => {
    try {
      let requestData = {};

      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`PersonalDetail`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          if (response.data) {
            setPersonal_post_put("put");
            const date = new Date(response.data[0].dob);
            setId_for_put(response.data[0].id);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            props.setGenderForLeave(response.data[0].gender);
            setinitialValues({
              personalphone: response.data[0].personal_phone,
              personalemail: response.data[0].personal_email,
              dateofbirth: formattedDate,
              gender: response.data[0].gender,
              maritalstatus: response.data[0].marital_status,
              persentaddress: response.data[0].address1,
              permanentaddress: response.data[0].address2,
              country: response.data[0].country,
              state: response.data[0].state,
              city: response.data[0].city,
              pincode: response.data[0].pincode,
            });    
            // setInitialEmployeePersonalData(response.data);
          } else {
            setPersonal_post_put("post");
          }
        });
    } catch (e) {
      setPersonal_post_put("post");
    }
  }, []);

  useEffect(() => {
    get_employee_byid();
  }, [get_employee_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    // employeeid: Yup.string().required("Enter Employee Id"),
    // fullname: Yup.string().required("Enter Full Name"),
    // profilephoto: Yup.mixed()
    //   .required("Select Profile")
    //   .test("fileSize", "Max Size 2MB", (file) => {
    //     if (file) {
    //       return file.size <= 2000000;
    //     } else {
    //       return true;
    //     }
    //   }),
    personalemail: Yup.string().required("Enter email").email("Not a proper email"),
    personalphone: Yup.string().min(10, "Enter valid Number"),
    // dateofbirth: Yup.date().required("Enter Date of Birth"),
    // gender: Yup.string().required("Please select a gender"),
    // maritalstatus: Yup.string().required("Please select a your Marital Status"),
    // persentaddress: Yup.string().required("Enter Persent Address"),
    // permanentaddress: Yup.string().required("Enter Permanent Address"),
    pincode: Yup.string().min(6, "Enter valid pincode Number"),
    country:Yup.string().required("Select Country"),
    state:Yup.string().required("Enter State"), 
    city:Yup.string().required("Enter City")
  
  });

  const [initialValues, setinitialValues] = useState({
    employeeid: `${props.employee_id}`,
    fullname: `${props.first_name} ${props.last_name}`,
    // profilephoto: "",

    personalemail: "",
    personalphone: "",
    dateofbirth: "",
    gender: "",
    maritalstatus: "",
    persentaddress: "",
    permanentaddress: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      const changedValues = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          changedValues[key] = values[key];
        }
      }

      // Map form fields to API keys
      const apiData = {
        personal_email: changedValues.personalemail,
        personal_phone: changedValues.personalphone,
        dob: changedValues.dateofbirth,
        gender: changedValues.gender,
        marital_status: changedValues.maritalstatus,
        address1: changedValues.persentaddress,
        address2: changedValues.permanentaddress,
        state: changedValues.state,
        city: changedValues.city,
        pincode: changedValues.pincode,
        country: changedValues.country,
      };

      // Filter out undefined values
      const filteredApiData = Object.fromEntries(
        Object.entries(apiData).filter(([_, v]) => v !== undefined)
      );
      // console.log("filteredApiData", filteredApiData);
      if (personal_post_put == "put") {
        add_personal_put(filteredApiData);
        // console.log("put")
      } else if (personal_post_put == "post") {
        // console.log("post")
        add_personal_post(filteredApiData);
      }
    },
  });

  // add personal deatils
  async function add_personal_post(e) {
    if (props.onboard) {
      e.onboard_id = props.id;
      e.type = "Onboard";
    } else {
      e.employee_id = props.id;
      e.type = "Employee";
    }
    try {
      if (props.onboard) {
        e.onboard_id = props.id;
        e.type = "Onboard";
      } else {
        e.employee_id = props.id;
        e.type = "Employee";
      }

      await axiosInstance
        .post(`AddPersonalDetail`, e, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update=", response);
          if (response.status === 200) {
            get_employee_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      const errorMessage = "All Feilds Are Required";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add personal deatils

  // put personal deatils
  async function add_personal_put(e) {
    try {
      if (props.onboard) {
        e.emp_per_id = id_for_put;
        e.onboard_id = props.id;
        e.type = "Onboard";
      } else {
        e.emp_per_id = id_for_put;
        e.employee_id = props.id;
        e.type = "Employee";
      }
      await axiosInstance
        .put(`EditPersonalDetail`, e, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            setEditInput(true)
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_employee_byid();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put personal deatils

  // upload image
  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation();
  //state to store country name
  const [countryName, setCountryName] = useState([]);

  //state to store states name
  const [statesName, setStatesName] = useState([]);
   //state to store cities name
   const [citiesName, setCitiesName] = useState([]);

  const [countryISO,setCountryISO]=useState();
  //api call to get country name
  async function getCountriesname() {
    try {
      await axios
        .get("https://api.countrystatecity.in/v1/countries", {
          headers: {
            "X-CSCAPI-KEY":
              "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
          },
        })
        .then((response) => {
          setCountryName(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  }

  //calling state API after the country is selected
  async function getStateName(countrycode) {
    setCountryISO(countrycode);
    try {
      await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${countrycode}/states`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
            },
          }
        )
        .then((response) => {
          setStatesName(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  }

   //calling city API after the state is selected
   async function getCityName(statecode) {
    try {
      await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${countryISO}/states/${statecode}/cities`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "ZE9zV1hWb1lhR1NDdzF0cXU2WHlqWmpDb2dQZFpOY1hyZ3dlSXVLcg==",
            },
          }
        )
        .then((response) => {
          setCitiesName(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  }

  //calling api to get country name on page load
  useEffect(() => {
   
    getCountriesname();
  }, []);

  return (
    <>
      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Personal Profile</h1>
          <p>Update employee photo or personal details</p>
        </div>
        <div className="headingrightbox">
          <button
            className="editbutton"
            onClick={() => {
              setEditInput(!editInput);
            }}
          >
            <FaEdit />{" "}
          </button>
        </div>
      </div>
      <div className="detailsboxsection">
        <form onSubmit={handleSubmit}>
          {props.onboard ? (
            ""
          ) : (
            <div className="empl_edit_page_detailboxes">
              <div className="detailsbox mt-2">
                <label htmlFor="employeeid" className="input-label">
                  Employee ID
                </label>
                <div className="inputbox">
                  <input
                    type="name"
                    className="nocustominputprofile"
                    readOnly={true}
                    autoComplete="off"
                    name="employeeid"
                    id="employeeid"
                    placeholder="Employee ID"
                    value={values.employeeid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.employeeid && errors.employeeid ? (
                    <p className="form-error">{errors.employeeid}</p>
                  ) : null}
                </div>
              </div>

              <div className="detailsbox mt-2">
                <label htmlFor="fullname" className="input-label">
                  Full Name
                </label>
                <div className="inputbox">
                  <input
                    type="name"
                    className="nocustominputprofile"
                    readOnly={editInput}
                    autoComplete="off"
                    name="fullname"
                    id="fullname"
                    placeholder="Full Name"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.fullname && errors.fullname ? (
                    <p className="form-error">{errors.fullname}</p>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="personalemail" className="input-label">
                Personal Email
              </label>
              <div className="inputbox">
                <input
                  type="email"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="personalemail"
                  id="personalemail"
                  placeholder="Personal Email"
                  value={values.personalemail}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {touched.personalemail && errors.personalemail ? (
                  <p className="form-error">{errors.personalemail}</p>
                ) : null}
              </div>
            </div>
            <div className="detailsbox mt-2">
              <label htmlFor="personalphone" className="input-label">
                Personal Phone
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                    e.target.value = e.target.value.slice(0, 10);
                  }}
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="personalphone"
                  id="personalphone"
                  placeholder="Personal Phone"
                  value={values.personalphone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.personalphone && errors.personalphone ? (
                  <p className="form-error">{errors.personalphone}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="dateofbirth" className="input-label">
                Date Of Birth
              </label>
              <div className="inputbox">
                <input
                  type="date"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="dateofbirth"
                  id="dateofbirth"
                  placeholder="Date Of Birth"
                  value={values.dateofbirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.dateofbirth && errors.dateofbirth ? (
                  <p className="form-error">{errors.dateofbirth}</p>
                ) : null}
              </div>
            </div>
            <div className="detailsbox mt-2">
              <label htmlFor="gender" className="input-label">
                Gender
              </label>
              <div className="inputbox">
                <select
                  type="gender"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="gender"
                  id="gender"
                  disabled={editInput}
                  value={values.gender}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                >
                  <option disabled value="">
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {touched.gender && errors.gender ? (
                  <p className="form-error">{errors.gender}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="maritalstatus" className="input-label">
                Marital Status
              </label>

              <div className="inputbox">
                <select
                  type="maritalstatus"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="maritalstatus"
                  id="maritalstatus"
                  disabled={editInput}
                  value={values.maritalstatus}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    Select Status
                  </option>
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                </select>
                {touched.maritalstatus && errors.maritalstatus ? (
                  <p className="form-error">{errors.maritalstatus}</p>
                ) : null}
              </div>
            </div>
            <div className="detailsbox mt-2">
              <label htmlFor="persentaddress" className="input-label">
                Present Address
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="persentaddress"
                  id="persentaddress"
                  placeholder="Persent Address"
                  value={values.persentaddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.persentaddress && errors.persentaddress ? (
                  <p className="form-error">{errors.persentaddress}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="permanentaddress" className="input-label">
                Permanent Address
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="permanentaddress"
                  id="permanentaddress"
                  placeholder="Permanent Address"
                  value={values.permanentaddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.permanentaddress && errors.permanentaddress ? (
                  <p className="form-error">{errors.permanentaddress}</p>
                ) : null}
              </div>
            </div>

            <div className="detailsbox mt-2">
              <label htmlFor="permanentaddress" className="input-label">
                Pincode
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                    e.target.value = e.target.value.slice(0, 6);
                  }}
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="pincode"
                  id="pincode"
                  placeholder="Pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.pincode && errors.pincode ? (
                  <p className="form-error">{errors.pincode}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="empl_edit_page_detailboxes">
            <div className="detailsbox mt-2">
              <label htmlFor="country" className="input-label">
                Country
              </label>
              <div className="inputbox">
                <select
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="country"
                  id="country"
                  placeholder="Country"
                  value={countryName.find(c => c.name === values.country) 
                    ? `${values.country},${countryName.find(c => c.name === values.country).iso2}` 
                    : values.country}
             
                  onChange={(e) => {
                    const [selectedCountryName, selectedCountryIso2] =
                      e.target.value.split(",");
                    console.log("selectedCountryIso2", selectedCountryIso2);
                    setFieldValue("country", selectedCountryName);
                    const state = selectedCountryIso2
                      ? getStateName(selectedCountryIso2)
                      : null;
                  }}
                  // onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled value="">
                    Select Country
                  </option>
                  {countryName
                    ? countryName.map((country, index) => (
                        <option
                          key={index}
                          value={`${country.name},${country.iso2}`}
                        >
                          {country.name}
                        </option>
                      ))
                    : ""}
                </select>
                {touched.country && errors.country ? (
                  <p className="form-error">{errors.country}</p>
                ) : null}
              </div>
            </div>
            <div className="detailsbox mt-2">
              <label htmlFor="persentaddress" className="input-label">
                State
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="state"
                  id="state"
                  placeholder="Persent Address"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.state && errors.state ? (
                  <p className="form-error">{errors.state}</p>
                ) : null}
              </div>
            </div>
            <div className="detailsbox mt-2">
              <label htmlFor="persentaddress" className="input-label">
                City
              </label>
              <div className="inputbox">
                <input
                  type="name"
                  className={
                    editInput ? "nocustominputprofile" : "custominputprofile"
                  }
                  readOnly={editInput}
                  autoComplete="off"
                  name="city"
                  id="city"
                  placeholder="Persent Address"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.city && errors.city ? (
                  <p className="form-error">{errors.city}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <button type="submit" className="cusbtn" disabled={editInput}>
              Update personal detail
            </button>
          </div>
        </form>
        {/* identity info  */}
        <Identity
          id={props.id}
          onboard={props.onboard}
          employee_id={props.employee_id}
        />
        {/* identity info  */}

        {/* bank info  */}
        <BankInfo id={props.id} onboard={props.onboard} />
        {/* bank info  */}

        {/* depandent info  */}
        <Dependant id={props.id} onboard={props.onboard} />
        {/* depandent info  */}
      </div>
    </>
  );
};

// employee personal details ends

export default Employee_personal;
