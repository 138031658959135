import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../../Navbar";
import { CgCloseR } from "react-icons/cg";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import dp from "../../../components/photos/dp.jpg";
import { GlobalFilter } from "./GlobalFilter";
import { BsThreeDotsVertical } from "react-icons/bs";
import MOCK_DATA from "./Customers.json";
import { IoIosAdd } from "react-icons/io";
import { useFormik } from "formik";
import { MdOutlineDeleteSweep } from "react-icons/md";
import * as Yup from "yup";
import FormData from "form-data";
import axios from "axios";

const Employee_sal = () => {

  const signUpSchema = Yup.object({
    select_staff: Yup.string().required("Select Staff"),
    net_salary: Yup.number().required("Enter Net Salary"),
    basic: Yup.number().required("Enter Basic"),
    TDS: Yup.number().required("Enter TDS"),
    DA: Yup.number().required("Enter DA"),
    ESI: Yup.number().required("Enter ESI"),
    HRA: Yup.number().required("Enter HRA"),
    PF: Yup.number().required("Enter PF"),
    conyenance: Yup.number().required("Enter Conveynance"),
    LOP: Yup.number().required("Enter LOP"),
    allowance: Yup.number().required("Enter Allowance"),
    prof_tax: Yup.number().required("Enter prof_tax"),
    medical_allowance: Yup.number().required("Enter Medical Allowance"),
    labour_welfare: Yup.number().required("Enter Labour Allowance"),
    other_allowance: Yup.number().required("Enter Other Allowance")

  })

  const [initialValues, setInitialValues] = useState({
    select_staff: "",
    net_salary: "",
    basic: "",
    TDS: "",
    DA: "",
    ESI: "",
    HRA: "",
    PF: "",
    conyenance: "",
    allowance: "",
    prof_tax: "",
    medical_allowance: "",
    labour_welfare: "",
    other_allowance: ""
  })
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);

      action.resetForm();
    },
  });

  const navigate = useNavigate();
  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ", flight_data.EVENT_TABLE_DATA);

  const [upcoming, setUpcoming] = useState(false);
  const [options, setOptions] = useState();

  useEffect(() => {
    // event_table_detail();
    // if (flight_data.EVENT_TABLE_DATA) {
    //   setUpcoming(true);
    // }
    // assistance_list();
  }, [upcoming]);

  // const data = useMemo(() => {
  //   if (upcoming) {
  //     return flight_data.EVENT_TABLE_DATA;
  //   } else {
  //     return [];
  //   }
  // }, [upcoming, flight_data.EVENT_TABLE_DATA]);

  const data = useMemo(() => MOCK_DATA);
  // useEffect

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);

  // Create Event

  const COLUMNS = [
    {
      Header: "Employee Deatils",
      accessor: "firstname",
      Cell: ({ row }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              // marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            <img
              src={dp}
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                objectFit: "cover",
                margin: "0px 5px 0px 5px",
              }}
              alt=""
            />
            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>{row.original.firstname}</p>
              <p>zatin56@gmail.com</p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "Join Date",
      accessor: "events_name",

      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
              // color: "black",
            }}
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            20 may 2023
            {/* {row.original.events_name} */}
          </p>
        </>
      ),
    },
    {
      Header: "Desigantion",
      accessor: (row) => {
        return [`${row.events_start_date}`, `${row.events_end_date}`];
      },
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
              // color: "black",
            }}
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            Full Stack Developer
            {/* {row.original.events_start_date} to {row.original.events_end_date} */}
          </p>
        </>
      ),
    },
    {
      Header: "Department",
      accessor: (row) => {
        return [`${row.event_start_time}`, `${row.event_end_time}`];
      },
      Cell: ({ row }) => (
        <>
          <p style={{}}>
            Administration
            {/* {row.original.event_start_time} to {row.original.event_end_time} */}
          </p>
        </>
      ),
    },
    {
      Header: "  Previous CTC",
      accessor: "",
      Cell: ({ row }) => (
        <p>
          $14.00
          {/* <p>{row.original.description}</p> */}
        </p>
      ),
    },
    {
      Header: "Revised CTC",
      accessor: "",
      Cell: ({ row }) => (
        <p>
          $15.00
          {/* <p>{row.original.description}</p> */}
        </p>
      ),
    },
    {
      Header: "Last Revision Date",
      accessor: "",
      Cell: ({ row }) => (
        <p>
          $15.00
          {/* <p>{row.original.description}</p> */}
        </p>
      ),
    },
    {
      Header: "Revision Type",
      accessor: "",
      Cell: ({ row }) => (
        <p>
          Promotion
          {/* <p>{row.original.description}</p> */}
        </p>
      ),
    },
    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <div style={{ cursor: "pointer" }}>
          <BsThreeDotsVertical onClick={() => { navigate("/Payroll/Emolyee_sal/Salary_details") }} />
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div className="main-container">
        <div className="allcontent">
          <Navbar />
          {/* <Breadcrumbs/> */}
          <div className="mainbox">
            {/* pop up */}
            {Create_event_modal && (
              <div className="modal">
                <div
                  onClick={() => {
                    setCreate_event_modal(!Create_event_modal);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Add Staff Salary</h2>

                    <button
                      className="close-modal"
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <form action="" onSubmit={handleSubmit}>
                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="select_staff"
                          className="text_input"
                          id="select_staff"
                          value={values.select_staff}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Select Staff</label>
                        <h1 className="lower_input_sty">Earning</h1>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="net_salary"
                          className="text_input"
                          id="net_salary"
                          value={values.net_salary}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Net Salary</label>
                        <h1 className="lower_input_sty">Deductions</h1>
                      </div>

                    </div>

                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="basic"
                          className="text_input"
                          id="basic"
                          value={values.basic}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Basic</label>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="TDS"
                          className="text_input"
                          id="TDS"
                          value={values.TDS}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">TDS</label>
                      </div>

                    </div>


                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="DA"
                          className="text_input"
                          id="DA"
                          value={values.DA}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">DA(40%)</label>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="ESI"
                          className="text_input"
                          id="ESi"
                          value={values.ESI}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">ESI</label>
                      </div>

                    </div>


                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="HRA"
                          className="text_input"
                          id="HRA"
                          value={values.HRA}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">HRA (15%)</label>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="PF"
                          className="text_input"
                          id="PF"
                          value={values.PF}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">PF</label>
                      </div>

                    </div>

                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="conyenance"
                          className="text_input"
                          id="conyenance"
                          value={values.conyenance}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Conyenance</label>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="LOP"
                          className="text_input"
                          id="LOP"
                          value={values.LOP}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">LOP</label>
                      </div>

                    </div>

                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="allowance"
                          className="text_input"
                          id="allowance"
                          value={values.allowance}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Allowance</label>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="proff_tax"
                          className="text_input"
                          id="proff_tax"
                          value={values.prof_tax}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Proff. tax</label>
                      </div>

                    </div>

                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="labour_welfare"
                          className="text_input"
                          id="labour_welfare"
                          value={values.labour_welfare}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Labour Welfare</label>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="medical_allowance"
                          className="text_input"
                          id="medical_allowance"
                          value={values.medical_allowance}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Medical Allowance</label>
                      </div>

                    </div>

                    <div className="setting_input_boxes mt-2">
                      <div className="input-container">
                        <input type="text"
                          name="other"
                          className="text_input"
                          id="other"
                          value={values.other}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel">Other</label>

                        <h1 className="lower_input_sty"> <IoIosAdd /> Add More</h1>
                      </div>
                      <div className="input-container">
                        <input type="text"
                          name="other"
                          className="text_input"
                          id="other"
                          value={values.other}
                          onChange={handleChange}
                        />
                        <label htmlFor="" className="loginlabel"> Other</label>
                        
                        <h1 className="lower_input_sty"> <IoIosAdd />Add More</h1>
                      </div>

                    </div>
                    <div className="btnstyle">
                      <button type="submit" className="cusbtn">Add Salary</button>
                      </div>

                  </form>
                </div>

              </div>
            )}
            {/* pop up*/}

            {/* breadcrumbs and pop up  */}
            <div className="breadcrumbs_pop">
              <div></div>

              <button
                onClick={() => {
                  setCreate_event_modal(!Create_event_modal);
                }}
                className="cusbtn"
              >
                <BiPlusMedical size="1.2rem" />
              </button>
            </div>
            {/* breadcrumbs and pop up  */}

            <div className="card mt-3">
              <div
                className="department tablebox"
                style={{ backgroundcolor: "white" }}
              >
                {/* pagination */}
                <div className="pages">
                  <div className="tablebottomboxleft">
                    <h1>Show</h1>
                    <span>
                      <select
                        className="selectnoofpagescolor"
                        style={{
                          padding: "4px 10px 4px 3px",
                          borderRadius: "10px",
                        }}
                        value={pageSize}
                        // className="showrows"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {[10, 15, 20].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>

                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div>
                {/* pagination */}

                <table className="mt-2" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroups) => (
                      <tr {...headerGroups.getHeaderGroupProps()}>
                        {headerGroups.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? "🔽"
                                  : "🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {/* {flight_data.EVENT_TABLE_DATA ? (
                      <> */}

                    {page.length === 0 ? (
                      <tr>
                        <td>No data</td>
                      </tr>
                    ) : (
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  style={{
                                    textalign: "center",
                                  }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <div className="pagination">
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <button
                    className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <BiLeftArrow />
                  </button>
                  <button
                    className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <BiRightArrow />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default Employee_sal;
