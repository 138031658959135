import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Checkbox } from "./Checkbox_request";
import { BiLeftArrow, BiRightArrow, BiSearchAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {useSelector } from "react-redux";
import { FaThumbsDown, FaThumbsUp, FaUser } from "react-icons/fa";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import swal from "sweetalert";

const Regularization_request = () => {
  const navigate = useNavigate();
const [loading,setLoading]=useState(false);
  const employee_page = useSelector((state) => state.Reducer);

  // get all attendence
  const [table_data, setTable] = useState();
  const get_all_attendence = useCallback(async () => {
    try {
      await axiosInstance
        .get(`Allregularization`, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
        
          setTable(response.data);
        });
    } catch (e) {
      console.log(e);

    }
  }, []);

  useEffect(() => {
    get_all_attendence();
  }, [get_all_attendence]);
  // get all attendence

  const data = useMemo(() => {
    if (table_data) {
      return table_data;
    } else {
      return [];
    }
  }, [table_data]);
  // approve/reject api
  async function approve_reject(id, status) {
    setLoading(true)
    try {
      await axiosInstance
        .post(`Editregularization`, {
          attendence_id: id,
          regularize_status: status,
        })
        .then((response) => {
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_all_attendence();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
    }
    finally{
      setLoading(false);
    }
  }

  // date format
  function formatDate(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Define month names array
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month name
    const month = monthNames[date.getMonth()];

    // Get the day
    const day = date.getDate();

    // Get the year
    const year = date.getFullYear();

    // Return formatted date string
    return `${day} ${month}, ${year}`;
  }
  // date format
  // approve/reject api
  const COLUMNS = [
    {
      Header: "Employees’ Details",
      accessor: "firstname",
      Cell: ({ row }) => (
  
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              // marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            {row.original.photo ? (
              <img
                src={row.original.photo}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
                alt=""
              />
            ) : (
              <FaUser
                style={{
                  color: "var(--Whiteblack-color)",
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
              />
            )}
            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>
                {row.original.first_name} {row.original.last_name}
              </p>
              <p>{row.original.employee_id}</p>
            </div>
          </div>
       
      ),
    },
    {
      Header: "Check in Timing",
      accessor: "check_In",

      Cell: ({ row }) => (
      
          <p
            style={{
              cursor: "pointer",
            }}
            // onClick={() => {
            //   navigate("/Event/Event_Details", {
            //     state: {
            //       name: row.original,
            //     },
            //   });
            // }}
          >
            {row.original.check_In}
          </p>
     
      ),
    },
    {
      Header: "Check Out Timing",
      accessor: "check_Out",
      Cell: ({ row }) => (
          <p
            style={{
              cursor: "pointer",
              // color: "black",
            }}
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            {row.original.check_Out}
          </p>
      ),
    },
    {
      Header: "Working Hours",
      accessor: "total_hours",
      Cell: ({ row }) => (
        
          <p style={{}}> {row.original.total_hours}</p>
       
      ),
    },
    {
      Header: "Department",
      accessor: "department_name",
      Cell: ({ row }) => (
        <p>
          <p>{row.original.department_name}</p>
        </p>
      ),
    },
    

    {
      Header: "Date",
      accessor: "attendence_date",
      Cell: ({ row }) => (
        <p>
          <p>
            {row.original.attendence_date
              ? formatDate(row.original.attendence_date)
              : ""}
          </p>
        </p>
      ),
    },
    {
      Header: "Approve/Reject",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <div>
          <FaThumbsUp
            onClick={() => {
              approve_reject(row.original.AttendenceId, "Approved");
            }}
            size="1.2em"
            style={{ color: "#095800", cursor: "pointer" }}
          />
          <FaThumbsDown
            onClick={() => {
              approve_reject(row.original.AttendenceId, "Rejected");
            }}
            size="1.2em"
            style={{
              color: "#be202e",
              marginLeft: "5px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);
  // const [selectedRows, setSelectedRows] = useState([]);
  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  );

  const selectedRows = selectedFlatRows.map((row) => ({
    attendence_id: row.original.AttendenceId,
  }));

  const { globalFilter, pageIndex, pageSize } = state;

  // approve/reject api
  const [bulkList, setBulkList] = useState();
  async function approve_reject_bulk(a) {
    if (selectedRows.length === 0) {
      swal({
        title: "Failure!",
        text: "Select some rows to approve or reject",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return; // Exit the function early if no rows are selected
    }

    if (a === "Approved") {
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        regularize_status: "Approved",
      }));
      setBulkList(updatedRows);

    
    } else if (a === "Rejected") {
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        regularize_status: "Rejected",
      }));
      setBulkList(updatedRows);
     
    }
    if (a === "Approved" || a === "Rejected") {
    try {
      await axiosInstance
        .post(`BulkEditregularization`,selectedRows.map((row) => ({
          ...row,
          regularize_status:a,
        }))
      )
        .then((response) => {
      
          if (response.status === 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_all_attendence();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  }
  // approve/reject api
  return (
    <>
    
      <div className="appr_reject" style={{ paddingLeft: "12px" }}>
        <button
          style={{ marginRight: "5px" }}
          className="approvebtn_bulk"
          onClick={() => {
            approve_reject_bulk("Approved");
          }}
        >
          Approve
        </button>
        <button
          className="rejectbtn_bulk"
          onClick={() => {
            approve_reject_bulk("Rejected");
          }}
        >
          Reject
        </button>
      </div>
      {/* <div className="mainbox"> */}
      <div
        className="department tablebox card mt-2"
        style={{ backgroundcolor: "white", padding: "25px" }}
      >
        {/* pagination */}
        <div className="pages">
          <div className="tablebottomboxleft">
            <h1>Show</h1>
            <span>
              <select
                className="selectnoofpagescolor"
                style={{
                  padding: "4px 10px 4px 3px",
                  borderRadius: "10px",
                }}
                value={pageSize}
                // className="showrows"
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 15, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </span>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <div className="globalsearchbar">
              <BiSearchAlt style={{ marginRight: "5px" }} />
              <input
                style={{ border: "none", outline: "none" }}
                placeholder="Search.."
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>

            <div
              style={{
                width: "50%",
                position: "relative",
              }}
            ></div>
          </div>
        </div>
        {/* pagination */}

        <table className="mt-2" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroups) => (
              <tr {...headerGroups.getHeaderGroupProps()}>
                {headerGroups.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "🔽"
                          : "🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {/* {flight_data.EVENT_TABLE_DATA ? (
                      <> */}

            {page.length === 0 ? (
              <tr>
                 <td colSpan={columns.length} style={{ textAlign: "center" }}>
                   No Data
                 </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{
                            textalign: "center",
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className="pagination">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <button
            className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <BiLeftArrow />
          </button>
          <button
            className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <BiRightArrow />
          </button>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Regularization_request;
